import * as React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  IconButton,
  TablePagination,
  Tooltip,
  Fab,
} from "@mui/material";
import {
  Close as CloseIcon,
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import axios from "axios";
import { useLocation } from "react-router-dom";
import IconSetting from "./IconSetting";
import IconAddiction from "./IconAddiction";
import PopoverDelete from "../../../components/PopoverDelete";
import PopoverRegister from "../../../components/PopoverRegister";
import SnackbarCustom from "../../../components/SnackBarCustom";
import AliveSelect from "./AliveSelect";
import DamageSelect from "./DamageSelect";
import SalesSelect from "./SalesSelect";
import SaleStatusSelect from "./SaleStatusSecect";
import { fetchSession } from "../../../auth";

const columns = [
  {
    id: "file_key",
    label: "アイコン",
    minWidth: "56px",
    align: "center",
    icon: true,
    isSmallScreen: true,
  },
  {
    id: "alive",
    label: "POS死活有無",
    minWidth: "80px",
    select: true,
    isSmallScreen: false,
  },
  {
    id: "sales",
    label: "POS営業有無",
    minWidth: "80px",
    select: true,
    isSmallScreen: false,
  },
  {
    id: "sale_status",
    label: "営業状況入力有無",
    minWidth: "80px",
    select: true,
    isSmallScreen: false,
  },
  {
    id: "damage",
    label: "被害状況有無",
    minWidth: "80px",
    select: true,
    isSmallScreen: false,
  },
  {
    id: "buttonSpace",
    label: "",
    minWidth: "80px",
    button: true,
    isSmallScreen: true,
  },
];

export default function IconTable() {
  const searchParams = new URLSearchParams(useLocation().search);
  const tenantId = searchParams.get("tenant_id");
  const tenantName = searchParams.get("name");

  // function CheckboxValidate(props) {
  //   const { input, column, changedValue } = props;
  //   const handleChange = (e) => {
  //     if (typeof changedValue === "function") {
  //       changedValue(e.target.checked);
  //       console.log("値を更新したよ", e.target.checked);
  //     }
  //   };

  //   // const checkboxAction = (event) => {
  //   //   event.stopPropagation();
  //   // };

  //   return (
  //     <Checkbox
  //       size="small"
  //       checked={input[column]}
  //       // onClick={(event) => checkboxAction(event)}
  //       onChange={(e) => handleChange(e)}
  //       sx={{ cursor: "pointer", pointerEvents: "auto" }}
  //     />
  //   );
  // }

  //更新時の選択行（登録ボタンを押下した行）
  const [rowNum, setRowNum] = useState("");
  //画面縮小時の選択行
  const [selectedRowNum, isSelectedRowNum] = useState("");
  const [registerPopoverOpen, setRegisterPopoverOpen] = useState(false);
  const registerButtonClick = (rowNum) => {
    isSelectedRowNum(rowNum);
    setRegisterPopoverOpen(true);
  };
  const registerPopupClose = () => {
    setRegisterPopoverOpen(false);
  };

  // アイコン一覧取得
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [icons, setIcons] = useState([]);
  const [totalData, setTotalData] = useState();

  const getIconsData = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/icons`;
    const params = {
      params: { m_tenant_id: tenantId, page: page + 1, limit: rowsPerPage },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("入力データ", params);
    axios
      .get(apiUrl, params)
      .then((response) => {
        // レスポンスのデータを取得
        const data = response.data.m_icons;
        const total = response.data.total_count;
        const status = response.data;
        // テナント情報をステートにセット
        setIcons(data);
        setTotalData(total);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  const [imageData, setImageData] = useState(null);
  const getImageUrl = async () => {
    const tokenInfo = await fetchSession();
    const imageUrl = `https://ljs-dic-public-resources-dev.s3.ap-northeast-1.amazonaws.com/${"1_0_0_0_0.png"}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(imageUrl, headers)
      .then((response) => {
        // response.dataには画像ファイルのバイナリデータが格納されています
        const imageData1 = response.data;
        // ここで画像データを使って何かしらの処理を行います
        console.log("画像データ:", imageData1);
        setImageData(imageData1);
      })
      .catch((error) => {
        console.error("画像データの取得に失敗しました:", error);
      });
  };

  useEffect(() => {
    getIconsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  // useEffect(() => {
  //   getIconsData();
  //   // getImageUrl();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [rowsPerPage, page]);

  // 更新処理
  const postIconData = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const imageUrl = `https://ljs-dic-public-resources-dev.s3.ap-northeast-1.amazonaws.com/${icons[selectedRowNum]["file_key"]}`;
    const params = new FormData();
    params.append("id", icons[selectedRowNum]["id"]);
    params.append("m_tenant_id", icons[selectedRowNum]["m_tenant_id"]);
    params.append(
      "icon_files",
      icons[selectedRowNum]["icon_files"] || imageUrl
    );
    params.append("alive", icons[selectedRowNum]["alive"]);
    params.append("sales", icons[selectedRowNum]["sales"]);
    params.append("sale_status", icons[selectedRowNum]["sale_status"]);
    params.append("damage", icons[selectedRowNum]["damage"]);
    params.append("name", tenantName);
    console.log("更新入力データid", icons[selectedRowNum]["id"]);
    console.log(
      "更新入力データm_tenant_id",
      icons[selectedRowNum]["m_tenant_id"]
    );
    console.log(
      "更新入力データicon_files",
      icons[selectedRowNum]["icon_files"] || imageUrl
    );
    console.log(
      "更新入力データfile_key",
      `${icons[selectedRowNum]["id"]}_${icons[selectedRowNum]["alive"]}_${icons[selectedRowNum]["sales"]}_${icons[selectedRowNum]["sale_status"]}_${icons[selectedRowNum]["damage"]}.png/svg`
    );
    console.log("更新入力データalive", icons[selectedRowNum]["alive"]);
    console.log("更新入力データsales", icons[selectedRowNum]["sales"]);
    console.log(
      "更新入力データsale_status",
      icons[selectedRowNum]["sale_status"]
    );
    console.log("更新入力データdamage", icons[selectedRowNum]["damage"]);
    console.log("更新入力データname", tenantName);

    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    isSuccess
      ? axios
          // .put(`http://localhost:8080/icon?id=${params.id}`, params)
          .put(
            `${process.env.REACT_APP_BASE_URL}/icon?id=${params.id}`,
            params,
            headers
          )
          .then((response) => {
            // console.log("更新入力データ", icons[selectedRowNum]);
            console.log("更新", response);
            const status = response.data;
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            getIconsData();
          })
          .catch((error) => {
            console.error("リクエストエラー:", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  // 削除処理
  const [deletePopoverOpen, setDeletePopoverOpen] = useState(false);
  const deleteButtonClick = (iconId) => {
    // event.stopPropagation();
    setDeleteIconId(iconId);
    setDeletePopoverOpen(true);
  };
  const deletePopupClose = (event) => {
    // event.stopPropagation();
    setDeletePopoverOpen(false);
  };
  const [deleteIconId, setDeleteIconId] = useState("");
  // const headers = {
  //   headers: {
  //     Authorization: `Bearer ${tokenInfo.accessToken}`,
  //   },
  // };
  const handleDelete = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    isSuccess
      ? axios
          // .delete(`http://localhost:8080/icon/${deleteIconId}`)
          .delete(`${process.env.REACT_APP_BASE_URL}/icon/${deleteIconId}`, {
            headers: {
              Authorization: `Bearer ${tokenInfo.accessToken}`,
            },
          })
          .then((response) => {
            const status = response.data;
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            console.log(`アイコン ${deleteIconId} を削除しました`);
            getIconsData();
          })
          .catch((error) => {
            console.error("エラーが発生しました:", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  const handleChange = (inputValue, tenantId, rowNum, column) => {
    console.log("inputValue", inputValue);
    // 変更内容とテナントIDを返り値として受け取り
    // console.log("column", typeof column);
    const updatedArray = [...icons]; // オリジナルの配列をコピー
    const updatedItem = { ...updatedArray[rowNum] }; // インデックス0の要素をコピー
    updatedItem[column] = inputValue;
    updatedArray[rowNum] = updatedItem; // インデックス0の要素を更新したものに置き換え
    // 更新対象のrowNumセット
    setRowNum(rowNum);
    setIcons(updatedArray);
    // console.log(inputValue, tenantId, rowNum);
    // console.log();
    // console.log("icons", icons);
  };

  const handleLocationCodeClick = (rowNum) => {
    isSelectedRowNum(rowNum);
    setIsPopoverOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [isAddDialogOpen, setIsAddDialogOpen] = React.useState(false);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const handleOpenAddDialog = (event) => {
    event.stopPropagation();
    setIsAddDialogOpen(true);
  };

  const handleCloseAddDialog = (event) => {
    event.stopPropagation();
    setIsAddDialogOpen(false);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const buttonVaridate = (input, icon, rowNum) =>
    input ? (
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          width: "100%",
          justifyContent: "flex-end",
          ".edit-button": {
            display: "none",
          },
          "@media screen and (max-width:900px)": {
            ".submit-button": {
              display: "none",
            },
            ".edit-button": {
              display: "unset",
            },
          },
        }}
      >
        <Button
          variant="contained"
          className="submit-button"
          sx={{ pointerEvents: "auto" }}
          onClick={() => registerButtonClick(rowNum)}
        >
          登録
        </Button>
        <Button
          variant="contained"
          className="edit-button"
          sx={{ pointerEvents: "auto" }}
          onClick={() => handleLocationCodeClick(rowNum)}
        >
          編集
        </Button>
        <IconButton
          color="error"
          sx={{ pointerEvents: "auto" }}
          onClick={() => deleteButtonClick(icon.id)}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </Box>
    ) : null;

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const consoleCheck = () => {
    console.log("imageData", imageData);
  };
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        {process.env.NODE_ENV === "development" && (
          <Box>
            <Button onClick={consoleCheck}>コンソール確認</Button>
          </Box>
        )}
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                    sx={{
                      color: "outline.dark",
                      fontWeight: "bold",
                      borderBottom: 2,
                      padding: "8px",
                      "@media screen and (max-width:900px)": {
                        display:
                          column.isSmallScreen === true ? "table-cell" : "none",
                      },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {icons.map((icon, rowNum) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={rowNum}
                    sx={{
                      marginTop: "8px",
                      pointerEvents: "none",
                    }}
                  >
                    {columns.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{
                            padding: "8px",
                            backgroundColor:
                              column.id === "file_key"
                                ? "outline.main"
                                : "#FFF",
                            "@media screen and (max-width:900px)": {
                              display:
                                column.isSmallScreen === true
                                  ? "table-cell"
                                  : "none",
                            },
                          }}
                        >
                          {column.select === true ? (
                            column.id === "damage" ? (
                              <DamageSelect
                                inputValue={icon.damage}
                                changedValue={(value) =>
                                  handleChange(
                                    value,
                                    icon.id,
                                    rowNum,
                                    column.id
                                  )
                                }
                              />
                            ) : column.id === "sale_status" ? (
                              <SaleStatusSelect
                                inputValue={icon.sale_status}
                                changedValue={(value) =>
                                  handleChange(
                                    value,
                                    icon.id,
                                    rowNum,
                                    column.id
                                  )
                                }
                              />
                            ) : column.id === "sales" ? (
                              <SalesSelect
                                inputValue={icon.sales}
                                changedValue={(value) =>
                                  handleChange(
                                    value,
                                    icon.id,
                                    rowNum,
                                    column.id
                                  )
                                }
                              />
                            ) : column.id === "alive" ? (
                              <AliveSelect
                                inputValue={icon.alive}
                                changedValue={(value) =>
                                  handleChange(
                                    value,
                                    icon.id,
                                    rowNum,
                                    column.id
                                  )
                                }
                              />
                            ) : null
                          ) : column.icon === true ? (
                            <IconSetting
                              input={icon}
                              column={column.id}
                              changedValue={(value) =>
                                handleChange(
                                  value,
                                  icon.id,
                                  rowNum,
                                  "icon_files"
                                )
                              }
                            />
                          ) : null}
                          {buttonVaridate(column.button, icon, rowNum)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <SnackbarCustom
          data={snackBarstatus}
          openStatus={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
        />
        <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
          <Box
            sx={{
              display: "flex",
              height: "56px",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              borderBottom: "2px solid #CCC",
            }}
          >
            <Box
              sx={{
                paddingLeft: "8px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              アイコン詳細
            </Box>
            <IconButton
              aria-label="close"
              onClick={handlePopoverClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
            <TableBody>
              {columns.map((column) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                    <TableCell
                      // align={column.align}
                      sx={{
                        color: "outline.dark",
                        fontWeight: "bold",
                        boxSizing: "border-box",
                        width: "calc((100vw - 64px) / 2)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          WebkitWritingMode: "horizontal-tb",
                          height: "100%",
                          padding: "8px",
                        }}
                      >
                        {column.label}
                      </Box>
                    </TableCell>
                    <TableCell
                      // align={column.align}
                      sx={{
                        writingMode: "horizontal-tb",
                        boxSizing: "border-box",
                        width: "calc((100vw - 64px) / 2)",
                        backgroundColor:
                          column.id === "icon" ? "outline.main" : "#FFF",
                        overflowWrap: "break-word",
                        ".edit-button": {
                          display: "none",
                        },
                        ".submit-button": {
                          display: "unset",
                        },
                      }}
                    >
                      {/* {column.checkBox === true ? (
                        <CheckboxValidate
                          input={icons[selectedRowNum]}
                          column={column.id}
                          changedValue={(value) =>
                            handleChange(
                              value,
                              icons[selectedRowNum].id,
                              selectedRowNum,
                              column.id
                            )
                          }
                        />
                      ) : column.icon === true ? (
                        <>
                          <IconSetting
                            input={icons[selectedRowNum]}
                            column={column.id}
                            changedValue={(value) =>
                              handleChange(
                                value,
                                icons[selectedRowNum].id,
                                selectedRowNum,
                                "icon_files"
                              )
                            }
                          />
                        </>
                      ) : null}
                      {buttonVaridate(
                        column.button,
                        icons[selectedRowNum],
                        selectedRowNum
                      )} */}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Dialog>
        <Box
          sx={{ display: "flex", justifyContent: "center", margin: "16px 0" }}
        >
          <Tooltip title="アイコン追加登録">
            <Fab
              color="primary"
              size="small"
              onClick={handleOpenAddDialog}
              sx={{ zIndex: 399 }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Box>
        <PopoverDelete
          open={deletePopoverOpen}
          onClose={deletePopupClose}
          yesNo={handleDelete}
        />

        <IconAddiction
          open={isAddDialogOpen}
          onClose={handleCloseAddDialog}
          columns={columns}
          tenantId={tenantId}
        />
        <PopoverDelete
          open={deletePopoverOpen}
          onClose={deletePopupClose}
          yesNo={handleDelete}
        />
        <PopoverRegister
          open={registerPopoverOpen}
          onClose={registerPopupClose}
          yesNo={(isSuccess) => postIconData(isSuccess)}
        />

        <TablePagination
          rowsPerPageOptions={[10, 30, 50, 100]}
          component="div"
          count={totalData | 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions": {
              margin: "0 !important",
              padding: "0 !important",
            },
          }}
        />
      </Paper>
    </>
  );
}
