import { Box, TextField } from "@mui/material";
import React from "react";
import Divider from "@mui/material/Divider";
import SalesSelect from "./SaleSelect";
import CloseReasonSelect from "./CloseReasonSelect";
import WaterTestStatusSelect from "./WaterTestStatusSelect";

const SaleStatus = (props) => {
  const { disasterStatus, onSaleStatusChange } = props;

  //営業状況管理e=1~4の時sale_status=1それ以外は0
  const handleChangeSaleStatus = (e) => {
    // 親コンポーネントのステート変更
    onSaleStatusChange({
      ...disasterStatus,
      sale_detail_status: e,
      sale_status: e !== null && 1 <= e && e <= 4 ? 1 : null,
    });
  };

  //休止要因管理
  const handleChangeReasonsClosingStatus = (e) => {
    // 親コンポーネントのステート変更
    onSaleStatusChange({
      ...disasterStatus,
      reasons_closing_status: e,
    });
  };

  //休止要因管理
  const handleChangeWaterTestStatus = (e) => {
    // 親コンポーネントのステート変更
    onSaleStatusChange({
      ...disasterStatus,
      water_test_status: e,
    });
  };

  //被害詳細管理
  const handleChangeReasonsClosing = (e) => {
    const newValue = e.target.value;
    // 親コンポーネントのステート変更
    onSaleStatusChange({
      ...disasterStatus,
      reasons_closing: newValue,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        justifyContent: "center",
        width: "100%",
        borderRadius: 2,
        borderTop: 16,
        borderColor: "primary.main",
        boxShadow: 3,
        color: "outline.dark",
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          padding: "16px 40px 32px 40px",
          gap: "16px",
          "@media screen and (max-width:900px)": {
            padding: "16px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 20,
            fontWeight: "bold",
            color: "primary.main",
            whiteSpace: "nowrap",
          }}
        >
          営業状況
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            "@media screen and (max-width:600px)": {
              width: "100%",
              flexDirection: "column",
            },
          }}
        >
          <Box sx={{ width: "248px" }}>営業状況</Box>
          <SalesSelect
            saleDetailStatus={disasterStatus?.sale_detail_status}
            changedValue={(e) => handleChangeSaleStatus(e)}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            "@media screen and (max-width:600px)": {
              width: "100%",
              flexDirection: "column",
            },
          }}
        >
          <Box sx={{ width: "248px" }}>休止要因</Box>
          <CloseReasonSelect
            reasonsClosingStatus={disasterStatus?.reasons_closing_status}
            changedValue={(e) => handleChangeReasonsClosingStatus(e)}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            "@media screen and (max-width:600px)": {
              width: "100%",
              flexDirection: "column",
            },
          }}
        >
          <Box sx={{ width: "248px" }}>浸水・冠水時検水対応</Box>
          <WaterTestStatusSelect
            waterTestStatus={disasterStatus?.water_test_status}
            changedValue={(e) => handleChangeWaterTestStatus(e)}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            "@media screen and (max-width:600px)": {
              width: "100%",
              flexDirection: "column",
            },
          }}
        >
          <Box sx={{ width: "248px" }}>再開見込</Box>
          <TextField
            id="outlined-basic"
            variant="outlined"
            multiline
            rows={2}
            value={disasterStatus?.reasons_closing || ''}
            onChange={(e) => handleChangeReasonsClosing(e)}
            sx={{
              height: "100%",
              width: "100%",
              ".MuiInputBase-root": {
                height: "100%",
              },
              "#outlined-basic": {
                boxSizing: "border-box",
                height: "100%",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SaleStatus;
