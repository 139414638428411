import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Dialog,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import axios from "axios";
import { formatDate } from "../../utils/deteUtils";
import { fetchSession } from "../../auth";

const columns = [
  {
    id: "start_at",
    label: "災害対応日時（始）",
    minWidth: "120px",
    isSmallScreen: true,
  },
  {
    id: "kind",
    label: "災害種別",
    minWidth: "120px",
    kind: true,
    isSmallScreen: false,
  },
  {
    id: "name",
    label: "災害名",
    minWidth: "120px",
    isSmallScreen: true,
  },
];

export default function Disaster() {
  //クエリパラメータ取得
  const searchParams = new URLSearchParams(useLocation().search);
  const disasterId = searchParams.get("disaster-id");

  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  //災害種別スタイル
  const disasterValidate = (disasterName) => {
    return (
      <Box
        sx={{
          borderRadius: "20px",
          padding: "8px",
          color: "white",
          fontWeight: "bold",
          width: "fit-content",
          backgroundColor:
            disasterName === "quake"
              ? "red"
              : disasterName === "w-r-s"
              ? "orange"
              : disasterName === "manual"
              ? "gray"
              : "black",
        }}
      >
        {disasterName === "quake"
          ? "地震"
          : disasterName === "w-r-s"
          ? "雨風雪"
          : disasterName === "manual"
          ? "手動"
          : disasterName === "alert"
          ? "警報"
          : "その他"}
      </Box>
    );
  };

  // 災害一覧取得
  const [disaster, setDisaster] = useState([]);
  const getDisasterData = async () => {
    const tokenData = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster/${disasterId}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenData.accessToken}`,
      },
    };
    axios
      .get(apiUrl, headers)
      .then((response) => {
        // レスポンスのデータを取得
        const data = response.data.disaster;
        // テナント情報をステートにセット
        setDisaster(data);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  useEffect(() => {
    getDisasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLocationCodeClick = () => {
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{
                      color: "outline.dark",
                      fontWeight: "bold",
                      borderBottom: 2,
                      padding: "8px",
                      "@media screen and (max-width:900px)": {
                        display:
                          column.isSmallScreen === true ? "table-cell" : "none",
                      },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                onClick={() => handleLocationCodeClick()}
                sx={{
                  pointerEvents: "none",
                  "@media screen and (max-width:900px)": {
                    cursor: "pointer",
                    pointerEvents: "auto",
                  },
                }}
              >
                {columns.map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      sx={{
                        padding: "8px",
                        border: "none",
                        "@media screen and (max-width:900px)": {
                          display:
                            column.isSmallScreen === true
                              ? "table-cell"
                              : "none",
                        },
                      }}
                    >
                      {column.kind
                        ? disasterValidate(disaster.kind)
                        : column.id === "start_at"
                        ? formatDate(disaster[column.id])
                        : disaster[column.id]}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
          <Box
            sx={{
              display: "flex",
              height: "56px",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              borderBottom: "2px solid #CCC",
            }}
          >
            <Box
              sx={{
                paddingLeft: "8px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              災害情報詳細
            </Box>
            <IconButton
              aria-label="close"
              onClick={handlePopoverClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
            <TableBody>
              {columns.slice(0, 3).map((column) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                    <TableCell
                      align={column.align}
                      sx={{
                        color: "outline.dark",
                        fontWeight: "bold",
                        boxSizing: "border-box",
                        width: "calc((100vw - 64px) / 2)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          WebkitWritingMode: "horizontal-tb",
                          height: "100%",
                        }}
                      >
                        {column.label}
                      </Box>
                    </TableCell>
                    <TableCell
                      align={column.align}
                      sx={{
                        writingMode: "horizontal-tb",
                        boxSizing: "border-box",
                        width: "calc((100vw - 64px) / 2)",
                        overflowWrap: "break-word",
                      }}
                    >
                      <>
                        {column.kind
                          ? disasterValidate(disaster.kind)
                          : column.id === "start_at"
                          ? formatDate(disaster[column.id])
                          : disaster[column.id]}
                      </>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Dialog>
      </Paper>
    </>
  );
}
