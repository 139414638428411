import React from "react";
import { useSnackbar } from "notistack";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const SnackbarCustom = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { data, openStatus, onClose } = props;

  const message = data?.message;
  const severity = data?.status === "ok" ? "success" : "error";

  React.useEffect(() => {
    if (openStatus && message) {
      const action = (key) => (
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
            onClose(); // 必要であれば、外部のclose関数も呼び出す
          }}
          style={{
            padding: "0",
            margin: "0"
          }}
        >
          <CloseIcon />
        </IconButton>
      );

      enqueueSnackbar(message, {
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
        variant: severity,
        action,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openStatus, data]);

  return null;
};

export default SnackbarCustom;
