import { Box } from "@mui/material";
import {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import Disaster from "../../Disaster";
import Map from "./Map";
import MaterList from "../../../../components/MaterList";
import MapIconTable from "./MapIconTable";
import ManualRegistration from "./ManualRegistration/ManualRegistration";
import ManualDisasterList from "./ManualRegistration/ManualDisasterList";
import DisasterInfomation from "./DisasterInfomation/DisasterInfomation";
import {TokenContext} from "../../../../TokenContext";
import {ManualCreateDisasterRole} from "../../../../role";

export default function DisasterMap() {
  const { user } = useContext(TokenContext);
  const manualCreateDisasterRole = ManualCreateDisasterRole(user.m_organization.m_role_id)
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // 前の画面に戻る
  };

  const [siteId, setSiteId] = useState();
  const [operator, setOperator] = useState("SS店舗データ");
  const [isDisasterListUpdated, setIsDisasterListUpdated] = useState();
  const handleChange = (selectId) => {
    setSiteId(selectId);
  };

  const handleChangeOperator = (operator) => {
    setOperator(operator);
  };

  const handleProcessComplete = (status) => {
    setIsDisasterListUpdated(status);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          onClick={handleGoBack}
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            paddingLeft: 5,
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            color: "primary.main",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
              color: "primary.dark",
            },
            "&:active": {
              color: "primary.light",
            },
            "@media screen and (max-width:900px)": {
              paddingLeft: 2,
            },
          }}
        >
          ダッシュボード
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          /マップ
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          padding: "16px",
          borderRadius: 2,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Disaster />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderTop: 16,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            paddingTop: 2,
            paddingRight: 5,
            paddingLeft: 5,
            paddingBottom: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 20,
              fontWeight: "bold",
              color: "primary.main",
              whiteSpace: "nowrap",
            }}
          >
            マップアイコン
          </Box>
        </Box>
        <MapIconTable />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "32px",
          "@media screen and (max-width:1200px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box
          className="child1"
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            borderRadius: 2,
            borderTop: 16,
            borderColor: "primary.main",
            boxShadow: 3,
            color: "outline.dark",
            backgroundColor: "#fff",
            flexGrow: 3,
          }}
        >
          <Box
            className="child2"
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              justifyContent: "center",
              padding: "16px 40px 32px 40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 20,
                fontWeight: "bold",
                color: "primary.main",
                whiteSpace: "nowrap",
              }}
            >
              災害情報マップ
            </Box>
          </Box>
          <Map
            selectId={(value) => handleChange(value)}
            selectOperator={(value) => handleChangeOperator(value)}
          />{" "}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            borderRadius: 2,
            borderTop: 16,
            borderColor: "primary.main",
            boxShadow: 3,
            color: "outline.dark",
            backgroundColor: "#fff",
            minWidth: "352px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "16px",
              paddingRight: "40px",
              paddingLeft: "40px",
              paddingBottom: "32px",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 20,
                fontWeight: "bold",
                color: "primary.main",
                wordWrap: "break-word",
                maxWidth: "216px",
                textAlign: "center",
              }}
            >
              {operator}
            </Box>
            <MaterList siteId={siteId} />
          </Box>
        </Box>
      </Box>
      {manualCreateDisasterRole.update ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            borderRadius: 2,
            borderTop: 16,
            borderColor: "primary.main",
            boxShadow: 3,
            color: "outline.dark",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              justifyContent: "center",
              paddingTop: "16px",
              paddingRight: "40px",
              paddingLeft: "40px",
              paddingBottom: "32px",
              gap: "32px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 20,
                fontWeight: "bold",
                color: "primary.main",
                whiteSpace: "nowrap",
              }}
            >
              災害エリア追加登録
            </Box>
          </Box>
          <ManualRegistration onProcessComplete={handleProcessComplete} />
          <ManualDisasterList isUpdated={isDisasterListUpdated} />
        </Box>
      ) : null}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderTop: 16,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            paddingTop: 2,
            paddingRight: 5,
            paddingLeft: 5,
            paddingBottom: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 20,
              fontWeight: "bold",
              color: "primary.main",
              whiteSpace: "nowrap",
            }}
          >
            災害情報
          </Box>
        </Box>
        <DisasterInfomation />
      </Box>
    </>
  );
}
