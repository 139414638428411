import { Box } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import SearchResult from "./SearchResult";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TablePagination from "@mui/material/TablePagination";
import { fetchSession } from "../../../auth";

export default function MailLog() {
  // 検索条件
  const [searchContents, setSearchContents] = useState({
    sent_at_stt: null,
    sent_at_end: null,
    is_plan: null,
    title: null,
    m_role_id: null,
    m_organization_id: null,
    order: null,
    sort: null,
    page: null,
    limit: null,
  });

  //権限チェックボックスのチェック管理
  const CheckboxValidate = (props) => {
    const { roleId, column, organizationIds } = props;
    const isChecked = searchContents[column]?.includes(roleId);

    const handleChange = (e) => {
      setSearchContents((prevSearchContents) => {
        const currentMRoleId = prevSearchContents[column] || [];
        let updatedMRoleId = currentMRoleId.slice(); // Make a copy of currentMRoleId

        if (organizationIds) {
          updatedMRoleId = e.target.checked
            ? [...updatedMRoleId, ...organizationIds]
            : updatedMRoleId.filter(
                (checkedId) => !organizationIds.includes(checkedId)
              );
        } else {
          updatedMRoleId = e.target.checked
            ? [...updatedMRoleId, roleId]
            : updatedMRoleId.filter((checkedId) => checkedId !== roleId);
        }

        return {
          ...prevSearchContents,
          [column]: updatedMRoleId,
          [column === "m_role_id" ? "m_organization_id" : "m_role_id"]: null,
        };
      });
    };

    return (
      <Checkbox
        size="small"
        checked={isChecked}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleChange(e)}
        sx={{ cursor: "pointer", pointerEvents: "auto" }}
      />
    );
  };

  //件名情報
  const handleChangeTitle = (e) => {
    const newValue = e.target.value;
    setSearchContents((prevValue) => ({
      ...prevValue,
      title: newValue,
    }));
  };

  //送信日時情報
  //カレンダー操作時
  const handleChangeCalender = (e, column) => {
    if (!e) {
      // eがnullの場合（カレンダーがクリアされた場合）は何もしない
      return;
    }

    const year = e.getFullYear();
    const month = (e.getMonth() + 1).toString().padStart(2, "0");
    const day = e.getDate().toString().padStart(2, "0");
    const hours = e.getHours().toString().padStart(2, "0");
    const minutes = e.getMinutes().toString().padStart(2, "0");
    const seconds = "00"; // SSは常に"00"に設定

    const formattedDateStr = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    console.log("eventlog", formattedDateStr);

    setSearchContents((prevValue) => ({
      ...prevValue,
      [column]: formattedDateStr,
    }));
  };

  //権限情報取得
  const [roles, setRoles] = useState(null);
  const getRoles = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/roles`;
    const params = {
      params: { order: null, sort: null, page: 1 },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("権限情報取得入力データ", params);
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.m_roles;
        console.log("権限情報情報取得", response);
        setRoles(data);
        console.log("data", data);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  //組織情報取得
  const [organizations, setOrganizations] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState();

  //検索結果ページネーション
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getOrganizations = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/organizations`;
    const params = {
      params: {
        parent_m_organization_id: 0,
        m_branch_id: null,
        m_role_id: null,
        order: null,
        sort: null,
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("組織情報取得入力データ", params);
    axios
      .get(apiUrl, params)
      .then((response) => {
        console.log("組織情報取得", response);
        const data = response.data.m_organizations;
        const total = response.data.total_count;
        setOrganizations(data);
        setTotalData(total);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  useEffect(() => {
    getRoles();
    getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  const renderTree = (organization) => {
    // チェックボックスに渡すすべてのidを含む配列を作成する
    const flattenOrganizationIds = (org) => {
      let ids = [];
      if (org.id) {
        ids.push(org.id);
      }
      if (Array.isArray(org.child_m_organizations)) {
        org.child_m_organizations.forEach((childOrg) => {
          ids = ids.concat(flattenOrganizationIds(childOrg));
        });
      }
      return ids;
    };

    const organizationIds = flattenOrganizationIds(organization);

    if (!organization) {
      return null;
    }

    return (
      <Box
        key={organization.id}
        sx={{ display: "flex", alignItems: "flex-start", padding: "0" }}
      >
        <TreeItem
          nodeId={String(organization.id)}
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* 親組織のIDをプロップスで渡す */}
              <CheckboxValidate
                // roleId={organizationIds}
                roleId={organization.id}
                column={"m_organization_id"}
                parentOrganizationid={organization.parent_m_organization_id}
                organizationIds={organizationIds}
              />
              {organization.group_name === null
                ? organization.department_name
                : organization.department_name + "-" + organization.group_name}
            </Box>
          }
          sx={{
            display: "flex",
            flexDirection: "column",
            ".MuiTreeItem-iconContainer": {
              display: "none",
            },
            ".css-1vbkhv8-MuiTreeItem-content": {
              padding: "0",
            },
            ".css-1vbkhv8-MuiTreeItem-content .MuiTreeItem-label": {
              padding: "0",
            },
          }}
        >
          {Array.isArray(organization.child_m_organizations)
            ? organization.child_m_organizations.map((childOrganization) =>
                renderTree(childOrganization)
              )
            : null}
        </TreeItem>
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "48px",
          alignItems: "center",
          paddingLeft: "40px",
          fontSize: 20,
          fontWeight: "bold",
          whiteSpace: "nowrap",
          "@media screen and (max-width:900px)": {
            paddingLeft: 2,
          },
        }}
      >
        メール送信履歴
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              paddingLeft: "40px",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              "@media screen and (max-width:900px)": {
                padding: "0",
              },
            }}
          >
            検索条件
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "48px",
              "@media screen and (max-width:600px)": {
                flexDirection: "column",
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
                maxWidth: "480px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box
                  sx={{
                    minWidth: "160px",
                    padding: "8px 0 0 0",
                    "@media screen and (max-width:900px)": {
                      padding: "0",
                    },
                  }}
                >
                  権限
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <Box>
                    {roles?.map((role) => (
                      <Box
                        key={role.name}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "500",
                        }}
                      >
                        <CheckboxValidate
                          roleId={role.id}
                          column={"m_role_id"}
                        />
                        {role["name"]}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "100%",
                  },
                }}
              >
                <Box sx={{ minWidth: "160px" }}>件名</Box>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  onChange={(e) => handleChangeTitle(e)}
                  sx={{
                    height: "40px",
                    width: "100%",
                    ".MuiInputBase-root": {
                      height: "100%",
                    },
                    "#outlined-basic": {
                      boxSizing: "border-box",
                      height: "100%",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box
                  sx={{
                    minWidth: "160px",
                    padding: "8px 0 0 0",
                    ".MuiFormControl-root": {
                      width: "100% !importnat",
                    },
                  }}
                >
                  送信日時
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "8px",
                    "@media screen and (max-width:900px)": {
                      width: "100%",
                      flexDirection: "column",
                    },
                  }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ja} //ja追加
                    dateFormats={{ monthAndYear: "yyyy/MM" }} //カレンダー左上の日付表示
                    localeText={{
                      previousMonth: "前月を表示", // < のツールチップ
                      nextMonth: "次月を表示", // > のツールチップ
                      cancelButtonLabel: "キャンセル", // スマホ画面のCANCELボタン
                      okButtonLabel: "選択", // スマホ画面のOKボタン
                      datePickerToolbarTitle: "日付選択",
                    }}
                  >
                    <DateTimePicker
                      label="start"
                      inputFormat="yyyy-MM-dd" //選択済みの日付の表示
                      mask="____年__月__日"
                      toolbarFormat="yyyy年MM月dd日"
                      textFieldProps={(params) => (
                        <TextField
                          defaultValue={null}
                          size="small"
                          {...params}
                          inputProps={{
                            inputMode: "numeric",
                            ...params.inputProps,
                            placeholder: "****年**月**日", //プレースホルダー（フォーカスを合わせたときに薄く表示される入力例）
                          }}
                        />
                      )}
                      onChange={(e) => handleChangeCalender(e, "sent_at_stt")}
                      slotProps={{
                        toolbar: {
                          toolbarFormat: "yyyy/MM/dd",
                        },
                        textField: { size: "small" },
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ja} //ja追加
                    dateFormats={{ monthAndYear: "yyyy/MM" }} //カレンダー左上の日付表示
                    localeText={{
                      previousMonth: "前月を表示", // < のツールチップ
                      nextMonth: "次月を表示", // > のツールチップ
                      cancelButtonLabel: "キャンセル", // スマホ画面のCANCELボタン
                      okButtonLabel: "選択", // スマホ画面のOKボタン
                      datePickerToolbarTitle: "日付選択",
                    }}
                  >
                    <DateTimePicker
                      label="end"
                      inputFormat="yyyy-MM-dd" //選択済みの日付の表示
                      mask="____年__月__日"
                      // sx={{
                      //   ".MuiInputBase-root": { height: "40px" },
                      //   width: "100%",
                      // }}
                      toolbarFormat="yyyy年MM月dd日"
                      textFieldProps={(params) => (
                        <TextField
                          defaultValue={null}
                          size="small"
                          {...params}
                          inputProps={{
                            inputMode: "numeric",
                            ...params.inputProps,
                            placeholder: "****年**月**日", //プレースホルダー（フォーカスを合わせたときに薄く表示される入力例）
                          }}
                        />
                      )}
                      onChange={(e) => handleChangeCalender(e, "sent_at_end")}
                      slotProps={{
                        toolbar: {
                          toolbarFormat: "yyyy/MM/dd",
                        },
                        textField: { size: "small" },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
                maxWidth: "480px",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    minWidth: "min-content",
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#000",
                    whiteSpace: "nowrap",
                    "@media screen and (max-width:900px)": {
                      flexDirection: "column",
                      width: "100%",
                    },
                  }}
                >
                  <Box
                    sx={{
                      minWidth: "160px",
                      padding: "8px 0 0 0",
                      "@media screen and (max-width:900px)": {
                        padding: "0",
                      },
                    }}
                  >
                    会社・部署・Gr
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        fontWeight: "500",
                      }}
                    >
                      <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        multiSelect
                      >
                        {Array.isArray(organizations)
                          ? organizations?.map((organization) =>
                              renderTree(organization)
                            )
                          : null}
                      </TreeView>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    minWidth: "min-content",
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#000",
                    whiteSpace: "nowrap",
                    "@media screen and (max-width:900px)": {
                      flexDirection: "column",
                      width: "100%",
                    },
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[10, 30, 50, 100]}
                    component="div"
                    count={totalData | 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions":
                        {
                          margin: "0 !important",
                          padding: "0 !important",
                        },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <SearchResult searchContents={searchContents} />
      </Box>
    </>
  );
}
