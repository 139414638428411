import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";

export default function DatepickerValidate(props) {
  const { input, column, changedValue } = props;
  const inputDate = new Date(input[column]);

  const handleChange = (e) => {
    if (e) {
      const selectedDate = e.toLocaleDateString(); // 日付オブジェクトを文字列に変換
      console.log("selectedDate", selectedDate);
      changedValue(selectedDate);
    } else {
      console.log("No date selected");
      changedValue(null); // or handle this case as needed
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ja} // ja追加
      dateFormats={{ monthAndYear: "yyyy/MM/dd" }} // カレンダー左上の日付表示
      localeText={{
        previousMonth: "前月を表示", // < のツールチップ
        nextMonth: "次月を表示", // > のツールチップ
        cancelButtonLabel: "キャンセル", // スマホ画面のCANCELボタン
        okButtonLabel: "選択", // スマホ画面のOKボタン
        datePickerToolbarTitle: "日付選択",
      }}
    >
      <DatePicker
        defaultValue={inputDate}
        onChange={handleChange}
        inputFormat="yyyy-MM-dd" // 選択済みの日付の表示
        mask="____年__月__日"
        sx={{
          ".MuiInputBase-root": { height: "40px" },
          pointerEvents: "auto",
          width: "100%",
        }}
        // toolbarFormat="yyyy年MM月dd日"
        format="yyyy/MM/dd"
        textFieldProps={(params) => (
          <TextField
            {...params}
            inputProps={{
              inputMode: "numeric",
              ...params.inputProps,
              placeholder: "****年**月**日", // プレースホルダー（フォーカスを合わせたときに薄く表示される入力例）
            }}
          />
        )}
        slotProps={{
          toolbar: {
            toolbarFormat: "yyyy/MM/dd",
          },
        }}
      />
    </LocalizationProvider>
  );
}
