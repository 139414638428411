import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Divider,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Box,
  TextField,
  Dialog,
  IconButton,
} from "@mui/material";
import {
  Close as CloseIcon,
  Save as SaveIcon,
  PanoramaFishEye as PanoramaFishEyeIcon,
} from "@mui/icons-material";
import SnackbarCustom from "../../../../components/SnackBarCustom";
import PopoverCustom from "../../../../components/PopoverCustom";
import SalesSelect from "./SaleSelect";
import ReasonsClosinCategorySelect from "./ReasonClosingCategorySelect";
import FacilityDamageCategorySelect from "./FacilityDamageCategorySelect";
import { fetchSession } from "../../../../auth";
import { formatDate } from "../../../../utils/deteUtils";


//
const columns = [
  {
    id: "code",
    label: "Sコード",
    minWidth: "80px",
    isSmallScreen: true,
    changeColor: true,
    site: true,
  },
  {
    id: "name",
    label: "SS名称",
    minWidth: "112px",
    isSmallScreen: true,
    changeColor: true,
    site: true,
  },
  {
    id: "name",
    label: "支店",
    minWidth: "144px",
    isSmallScreen: false,
    m_branch: true,
  },
  {
    id: "operator",
    label: "運営店名",
    minWidth: "144px",
    isSmallScreen: false,
    site: true,
  },
  {
    id: "name",
    label: "特約店",
    minWidth: "144px",
    isSmallScreen: false,
    m_customer: true,
  },
  {
    id: "sales_staff",
    label: "担当者名",
    minWidth: "144px",
    isSmallScreen: false,
  },
  {
    id: "pref_name",
    label: "都道府県名",
    minWidth: "144px",
    isSmallScreen: false,
    m_division: true,
  },
  {
    id: "city_name",
    label: "市区町村名",
    minWidth: "144px",
    isSmallScreen: false,
    m_division: true,
  },
  {
    id: "owned_kind",
    label: "所有区分",
    minWidth: "144px",
    isSmallScreen: false,
    site: true,
    defaultSelecter: true,
  },
  // {
  //   id: "compe_kind",
  //   label: "運営区分",
  //   minWidth: "144px",
  //   isSmallScreen: false,
  //   site: true,
  //   defaultSelecter: true,
  // },
  // {
  //   id: "disaster_kind",
  //   label: "災害時給油可",
  //   minWidth: "144px",
  //   isSmallScreen: false,
  //   site: true,
  //   defaultSelecter: true,
  // },
  // {
  //   id: "sale_status",
  //   label: "営業状況",
  //   minWidth: "144px",
  //   isSmallScreen: false,
  //   defaultSelecter: true,
  // },
  {
    id: "business_time",
    label: "営業時間",
    minWidth: "144px",
    isSmallScreen: false,
    site: true,
  },
  {
    id: "holiday",
    label: "定休日",
    minWidth: "144px",
    isSmallScreen: false,
    site: true,
  },
  // {
  //   id: "latest_alive",
  //   label: "応答",
  //   minWidth: "56px",
  //   existence: true,
  //   isSmallScreen: false,
  // },
  {
    id: "latest_sales",
    label: "売上",
    minWidth: "56px",
    existence: true,
    isSmallScreen: false,
  },
  // {
  //   id: "latest_authori",
  //   label: "オーソリ",
  //   minWidth: "80px",
  //   existence: true,
  //   isSmallScreen: false,
  // },
  {
    id: "sale_status",
    label: "営業状況",
    minWidth: "80px",
    existence: true,
    isSmallScreen: false,
  },
  // {
  //   id: "latest_alive_at",
  //   label: "最終応答日時",
  //   minWidth: "144px",
  //   isSmallScreen: false,
  // },
  {
    id: "latest_sales_at",
    label: "最終売上日時",
    minWidth: "144px",
    isSmallScreen: false,
  },
  // {
  //   id: "latest_authori_at",
  //   label: "カード最終利用日時",
  //   minWidth: "144px",
  //   isSmallScreen: false,
  // },
  {
    id: "today_sales",
    label: "本日売上状況",
    minWidth: "112px",
    existence: true,
    isSmallScreen: false,
  },
  {
    id: "ago1_sales",
    label: "1日前売上状況",
    minWidth: "112px",
    existence: true,
    isSmallScreen: false,
  },
  {
    id: "ago2_sales",
    label: "2日前売上状況",
    minWidth: "112px",
    existence: true,
    isSmallScreen: false,
  },
  {
    id: "ago3_sales",
    label: "3日前売上状況",
    minWidth: "112px",
    existence: true,
    isSmallScreen: false,
  },
  {
    id: "damage_at",
    label: "被害日時",
    minWidth: "112px",
    isSmallScreen: false,
  },

  //以下11_disaster-status.mdでの処理範囲
  {
    id: "personal_damage",
    label: "人的被害",
    minWidth: "144px",
    checkBox: true,
    isSmallScreen: false,
  },
  {
    id: "property_damage",
    label: "第三者への物損",
    minWidth: "144px",
    checkBox: true,
    isSmallScreen: false,
  },
  {
    id: "facility_damage",
    label: "SS設備被害",
    minWidth: "144px",
    checkBox: true,
    isSmallScreen: false,
  },

  {
    id: "sale_detail_status",
    label: "営業状況",
    minWidth: "176px",
    pullDown: true,
    isSmallScreen: false,
  },
  // {
  //   id: "facility_damage_category",
  //   label: "SS施設被害分類",
  //   minWidth: "176px",
  //   pullDown: true,
  //   isSmallScreen: false,
  // },
  // {
  //   id: "reasons_closing_category",
  //   label: "主たる休業理由",
  //   minWidth: "176px",
  //   pullDown: true,
  //   isSmallScreen: false,
  // },
  {
    id: "damage_details",
    label: "被害詳細",
    minWidth: "144px",
    textField: true,
    isSmallScreen: false,
  },
  {
    id: "reasons_closing",
    label: "再開見込",
    minWidth: "144px",
    textField: true,
    isSmallScreen: false,
  },
  {
    id: "update_user",
    label: "最終記入者",
    minWidth: "144px",
    isSmallScreen: false,
  },
  {
    id: "update_at",
    label: "最終記入日時",
    minWidth: "144px",
    isSmallScreen: false,
  },
  {
    id: "reportButton",
    label: "",
    minWidth: "112px",
    button: true,
    align: "right",
    isSmallScreen: true,
    position: "sticky",
    right: 0,
    backgroundColor: "#fff",
    zIndex: 1,
  },
];

const ownKind = [
  {
    key: 1,
    value: "自己",
  },
  {
    key: 9,
    value: "社有",
  },
];

const compeKind = [
  {
    key: 1,
    value: "自己",
  },
  {
    key: 9,
    value: "社有",
  },
];

const disaterKind = [
  {
    key: 0,
    value: "区分1",
  },
  {
    key: 1,
    value: "区分2",
  },
  {
    key: 2,
    value: "区分3",
  },
];
const saleStatus = [
  {
    key: 0,
    value: "休止",
  },
  {
    key: 1,
    value: "営業",
  },
];

//ファイル内連想配列使用
const DefaultSelector = (props) => {
  const { column, value } = props;
  const useSelector =
    column === "owned_kind"
      ? ownKind
      : column === "compe_kind"
      ? compeKind
      : column === "disaster_kind"
      ? disaterKind
      : column === "sale_status"
      ? saleStatus
      : null;

  const useKey =
    column === "sale_status" ? value?.[column] : value?.site?.[column];

  function findValue(key) {
    const findValue = useSelector.find((item) => item.key === key);
    return findValue ? findValue.value : null;
  }

  const outputValue = findValue(useKey);
  return <>{outputValue}</>;
};

//セレクター設定
const SelecterValidate = (props) => {
  const { disasterData, changedValue, column } = props;
  return (
    <Box onClick={(e) => e.stopPropagation()} sx={{ pointerEvents: "auto" }}>
      {column === "sale_detail_status" ? (
        <SalesSelect
          saleDetailStatus={disasterData?.sale_detail_status}
          changedValue={(e) => changedValue(e)}
        />
      ) : column === "facility_damage_category" ? (
        <FacilityDamageCategorySelect
          facilityDamageCategory={disasterData?.facility_damage_category}
          changedValue={(e) => changedValue(e)}
        />
      ) : column === "reasons_closing_category" ? (
        <ReasonsClosinCategorySelect
          reasonsClosinCategory={disasterData?.reasons_closing_category}
          changedValue={(e) => changedValue(e)}
        />
      ) : null}
    </Box>
  );
};

//テキストフィールド設定
const StatusesTextfield = (props) => {
  const { disasterData, changedValue, column } = props;
  const handleChange = (e) => {
    if (typeof changedValue === "function") {
      changedValue(e.target.value);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "8px" }}>
      <TextField
        size="small"
        variant="outlined"
        value={disasterData?.[column] || ''}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleChange(e)}
        sx={{ pointerEvents: "auto" }}
      />
    </Box>
  );
};

//チェックボックス設定
const CheckboxValidate = (props) => {
  const { disasterData, changedValue, column } = props;
  const handleChange = (e) => {
    changedValue(e.target.checked);
  };

  return (
    <Checkbox
      size="small"
      checked={disasterData[column]}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => handleChange(e)}
      sx={{ cursor: "pointer", pointerEvents: "auto" }}
    />
  );
};

//マルバツ設定
const existenceValidate = (input) =>
  input ? (
    <Box>
      <PanoramaFishEyeIcon />
    </Box>
  ) : (
    <Box>
      <CloseIcon />
    </Box>
  );

const SearchResult = (props) => {
  //クエリパラメータ取得
  const searchParams = new URLSearchParams(useLocation().search);
  const disasterId = searchParams.get("disaster-id");

  const { searchContent } = props;

  const navigate = useNavigate();

  const handleClick = (event, path) => {
    event.stopPropagation();
    navigate(path, { state: { previousPage: "situationcheck" } });
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [disasterStatuses, setDisasterStatuses] = useState([]);
  const [totalData, setTotalData] = useState();

  const getDisasterSsData = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster-statuses/${searchContent["display_id"]}`;
    const params = {
      params: {
        display_code: searchContent["display_code"],
        name: null,
        pref: searchContent["pref"],
        m_branch_id: searchContent["m_branch_id"], // 例: 都道府県コードを指定 (複数指定可能)
        m_customer_id: searchContent["m_customer_id"], // 例: 得意先IDを指定 (複数指定可能)
        latest_alive: searchContent["latest_alive"],
        latest_sales: searchContent["latest_sales"],
        latest_authori: searchContent["latest_authori"],
        is_input: searchContent["is_input"],
        is_damage: searchContent["is_damage"],
        order: null, // デフォルト値: 'code'
        sort: null,
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.disaster_statuses;
        const total = response.data.total_count;
        const status = response.data;
        // テナント情報をステートにセット
        setDisasterStatuses(data);
        setTotalData(total);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  const ButtonVaridate = (props) => {
    const { button, id, rowNum, isDialog } = props;
    return button ? (
      <Box sx={{ display: "flex", gap: "8px", width: "max-content" }}>
        <IconButton
          color="primary"
          sx={{
            pointerEvents: "auto",
            "@media screen and (max-width:900px)": {
              display:
                isDialog === true
                ? "table-cell"
                : "none",
            },
          }}
          onClick={(e) => registerButtonClick(e, rowNum)}
        >
          <SaveIcon />
        </IconButton>
        <Button
          variant="contained"
          onClick={(event) =>
            handleClick(
              event,
              `/dashboard/situationcheck/report?disaster-id=${disasterId}&status-id=${id}`
            )
          }
          sx={{ pointerEvents: "auto" }}
        >
          被害状況報告
        </Button>
      </Box>
    ) : null;
  };

  //初回起動時の読み込み回避
  const isFirstRender = useRef(false);

  useEffect(() => {
    // このeffectは初回レンダー時のみ呼ばれるeffect
    isFirstRender.current = true;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // 初回レンダー判定
      isFirstRender.current = false; // もう初回レンダーじゃないよ代入
    }
    getDisasterSsData();
  }, [rowsPerPage, page]);

  //登録ボタン選択行番号管理
  const [selectedRowNum, setSelectedRowNum] = useState("");

  //入力可能カラム変更管理
  const handleChangeDisasterStatus = (inputValue, statusId, column) => {
    console.log("inputValue, rowNum", inputValue, statusId);
    const updatedArray = [...disasterStatuses]; // オリジナルの配列をコピー
    //sites配列内からidが当てはまる配列を探す
    const updatedItem = updatedArray.find((item) => item.id === statusId);
    if (updatedItem) {
      updatedItem[column] = inputValue;
      console.log("updatedArray, column", updatedArray, column);
      setDisasterStatuses(updatedArray);
    } else {
      console.error(`sites with id ${statusId} not found`);
    }
  };

  //復旧報告登録
  const putrecoberyReport = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster-status`;
    const params = {
      id: disasterStatuses[selectedRowNum].id,
      personal_damage: disasterStatuses[selectedRowNum].personal_damage,
      property_damage: disasterStatuses[selectedRowNum].property_damage,
      facility_damage: disasterStatuses[selectedRowNum].facility_damage,
      damage_details:
        !disasterStatuses[selectedRowNum].damage_details ||
        !disasterStatuses[selectedRowNum].damage_details.match(/\S/g)
          ? null
          : disasterStatuses[selectedRowNum].damage_details,
      sale_detail_status: disasterStatuses[selectedRowNum].sale_detail_status,
      reasons_closing:
        !disasterStatuses[selectedRowNum].reasons_closing ||
        !disasterStatuses[selectedRowNum].reasons_closing.match(/\S/g)
          ? null
          : disasterStatuses[selectedRowNum].reasons_closing,
      facility_damage_category:
        disasterStatuses[selectedRowNum].facility_damage_category,
      reasons_closing_category:
        disasterStatuses[selectedRowNum].reasons_closing_category,
    };

    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    console.log("復旧報告入力データ", params);
    isSuccess
      ? axios

          .put(apiUrl, params, headers)
          .then((response) => {
            const status = response.data;
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            console.log("復旧報告レスポンス", response.data);
          })
          .catch((error) => {
            console.error("エラーが発生しました:", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  //登録ボタンクリック
  const [popoverRegisterOpen, setPopoverRegisterOpen] = useState(false);
  const registerButtonClick = (e, rowNum) => {
    e.stopPropagation();
    setSelectedRowNum(rowNum);
    setPopoverRegisterOpen(true);
  };
  const registerPopupClose = (event) => {
    setPopoverRegisterOpen(false);
  };

  const [selectedRow, setSelectedRow] = useState(null);
  //詳細ポップアップ設定
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleLocationCodeClick = (row, rowNum) => {
    setSelectedRow(row);
    setSelectedRowNum(rowNum);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  //検索結果ページネーション管理
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      {/*{process.env.NODE_ENV === "development" && (*/}
      {/*  <Button onClick={consoleCheck}>コンソール確認</Button>*/}
      {/*)}*/}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
          padding: "16px 0 32px",
          "@media screen and (max-width:600px)": {
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <Button
          variant="contained"
          onClick={getDisasterSsData}
          sx={{ width: "208px" }}
        >
          検索
        </Button>
      </Box>
      <Divider />
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            gap: "16px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              paddingLeft: "40px",
              "@media screen and (max-width:900px)": {
                padding: "0",
              },
            }}
          >
            検索結果
          </Box>
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ width: "100%" }}
            >
              <TableHead>
                <TableRow sx={{ overflowX: "auto" }}>
                  {columns.map((column) => (
                    <TableCell
                      key={`${column.label}_${column.id}`}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        position: column.position,
                        right: column.right,
                      }}
                      sx={{
                        padding: "8px",
                        "@media screen and (max-width:900px)": {
                          display:
                            column.isSmallScreen === true
                              ? "table-cell"
                              : "none",
                        },
                        backgroundColor: column.changeColor
                          ? "primary.light"
                          : "#FFF",
                        color: column.changeColor ? "#FFF" : "outline.dark",
                        fontWeight: "bold",
                        borderBottom: "2px solid #606060",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {disasterStatuses?.map((disasterstatus, rowNum) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`${disasterstatus.id}_${disasterstatus.label}`}
                      onClick={() =>
                        handleLocationCodeClick(disasterstatus, rowNum)
                      }
                      sx={{
                        pointerEvents: "none",
                        "@media screen and (max-width:900px)": {
                          cursor: "pointer",
                          pointerEvents: "auto",
                        },
                      }}
                    >
                      {columns.map((column) => {
                        // const value = disasterstatus[column.id];
                        return (
                          <TableCell
                            key={`${column.label}_${column.id}`}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              position: column.position,
                              right: column.right,
                              backgroundColor: column.backgroundColor,
                            }}
                            sx={{
                              padding: "8px",
                              "@media screen and (max-width:900px)": {
                                display:
                                  column.isSmallScreen === true
                                    ? "table-cell"
                                    : "none",
                              },
                            }}
                          >
                            {column.checkBox ? (
                              <CheckboxValidate
                                disasterData={disasterstatus}
                                column={column.id}
                                changedValue={(value) =>
                                  handleChangeDisasterStatus(
                                    value,
                                    disasterstatus.id,
                                    column.id
                                  )
                                }
                              />
                            ) : column.textField ? (
                              <StatusesTextfield
                                disasterData={disasterstatus}
                                column={column.id}
                                changedValue={(value) =>
                                  handleChangeDisasterStatus(
                                    value,
                                    disasterstatus.id,
                                    column.id
                                  )
                                }
                              />
                            ) : column.pullDown ? (
                              <SelecterValidate
                                disasterData={disasterstatus}
                                column={column.id}
                                changedValue={(value) =>
                                  handleChangeDisasterStatus(
                                    value,
                                    disasterstatus.id,
                                    column.id
                                  )
                                }
                              />
                            ) : column.defaultSelecter ? (
                              <DefaultSelector
                                column={column.id}
                                value={disasterstatus}
                              />
                            ) : column.site ? (
                              <>{disasterstatus?.["site"][column.id]}</>
                            ) : column.m_branch ? (
                              <>
                                {
                                  disasterstatus?.["site"]["m_branch"][
                                    column.id
                                  ]
                                }
                              </>
                            ) : column.m_customer ? (
                              <>
                                {
                                  disasterstatus?.["site"]["m_customer"][
                                    column.id
                                  ]
                                }
                              </>
                            ) : column.m_division ? (
                              <>
                                {
                                  disasterstatus?.["site"]["m_division"][
                                    column.id
                                  ]
                                }
                              </>
                            ) : column.existence ? (
                              <>
                                {existenceValidate(disasterstatus?.[column.id])}
                              </>
                            ) : column.id === "latest_alive_at" ||
                              column.id === "latest_sales_at" ||
                              column.id === "damage_at" ||
                              column.id === "update_at" ? (
                              formatDate(disasterstatus?.[column.id])
                            ) : column.id === "update_user" ? (
                              <>
                                {disasterstatus?.update_user?.name}
                              </>
                            ) : (
                              <>{disasterstatus?.[column.id]}</>
                            )}
                            <ButtonVaridate
                              button={column.button}
                              id={disasterstatus["id"]}
                              detailId={disasterstatus["disaster_detail_id"]}
                              siteId={disasterstatus["site_id"]}
                              kmlId={disasterstatus["disaster_detail_kml_id"]}
                              rowNum={rowNum}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
            <Box
              sx={{
                display: "flex",
                height: "56px",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px",
                borderBottom: "2px solid #CCC",
              }}
            >
              <Box
                sx={{
                  paddingLeft: "8px",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                被害状況詳細
              </Box>
              <IconButton
                aria-label="close"
                onClick={handlePopoverClose}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
              <TableBody>
                {selectedRow &&
                  columns.slice(0, 36).map((column) => {
                    return (
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={`${column.id}_${column.label}`}
                      >
                        <TableCell
                          align={column.align}
                          sx={{
                            color: "outline.dark",
                            fontWeight: "bold",
                            boxSizing: "border-box",
                            width: "calc((100vw - 64px) / 2)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              WebkitWritingMode: "horizontal-tb",
                              height: "100%",
                            }}
                          >
                            {column.label}
                          </Box>
                        </TableCell>
                        <TableCell
                          align={column.align}
                          sx={{
                            writingMode: "horizontal-tb",
                            boxSizing: "border-box",
                            width: "calc((100% - 64px) / 2)",
                            overflowWrap: "break-word",
                          }}
                        >
                          {column.checkBox ? (
                            <CheckboxValidate
                              disasterData={selectedRow}
                              column={column.id}
                              changedValue={(value) =>
                                handleChangeDisasterStatus(
                                  value,
                                  selectedRow.id,
                                  column.id
                                )
                              }
                            />
                          ) : column.textField ? (
                            <StatusesTextfield
                              disasterData={selectedRow}
                              column={column.id}
                              changedValue={(value) =>
                                handleChangeDisasterStatus(
                                  value,
                                  selectedRow.id,
                                  column.id
                                )
                              }
                            />
                          ) : column.pullDown ? (
                            <SelecterValidate
                              disasterData={selectedRow}
                              column={column.id}
                              changedValue={(value) =>
                                handleChangeDisasterStatus(
                                  value,
                                  selectedRow.id,
                                  column.id
                                )
                              }
                            />
                          ) : column.defaultSelecter ? (
                            <DefaultSelector
                              column={column.id}
                              value={selectedRow}
                            />
                          ) : column.site ? (
                            <>{selectedRow?.["site"][column.id]}</>
                          ) : column.m_branch ? (
                            <>{selectedRow?.["site"]["m_branch"][column.id]}</>
                          ) : column.m_customer ? (
                            <>
                              {selectedRow?.["site"]["m_customer"][column.id]}
                            </>
                          ) : column.m_division ? (
                            <>
                              {selectedRow?.["site"]["m_division"][column.id]}
                            </>
                          ) : column.existence ? (
                            <>{existenceValidate(selectedRow?.[column.id])}</>
                          )  : column.id === "latest_alive_at" ||
                                column.id === "latest_sales_at" ||
                                column.id === "damage_at" ||
                                column.id === "update_at" ? (
                            formatDate(selectedRow?.[column.id])
                          ) : column.id === "update_user" ? (
                            <>
                              {selectedRow?.update_user?.name}
                            </>
                          ) : (
                            <>{selectedRow?.[column.id]}</>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <Box
              sx={{
                margin: "16px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ButtonVaridate
                button={true}
                id={selectedRow?.["id"]}
                detailId={selectedRow?.["disaster_detail_id"]}
                siteId={selectedRow?.["site_id"]}
                kmlId={selectedRow?.["disaster_detail_kml_id"]}
                rowNum={selectedRowNum}
                isDialog={true}
              />
            </Box>
          </Dialog>
          <SnackbarCustom
            data={snackBarstatus}
            openStatus={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
          />
          <TablePagination
            rowsPerPageOptions={[10, 30, 50, 100]}
            component="div"
            // count={rows.length}
            count={totalData | 0}
            // name={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions":
                {
                  margin: "0 !important",
                  padding: "0 !important",
                },
            }}
          />
          <PopoverCustom
            open={popoverRegisterOpen}
            onClose={registerPopupClose}
            yesNo={(e) => putrecoberyReport(e)}
            title={"登録確認"}
            contents={"登録してもよろしいですか？"}
          />
        </Paper>
      </Box>
    </>
  );
};
export default SearchResult;
