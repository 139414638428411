import React, {useState, useEffect, createContext, useContext} from "react";
import {
  AppBar,
  Typography,
  Box,
  Button,
  Drawer,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {useLocation, useNavigate} from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import SideBar from "./Sidebar";
import awsExports from "../aws-exports";
import {AccountCircle} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

Amplify.configure(awsExports);
Auth.configure({
  authenticationFlowType: process.env.REACT_APP_AUTHTYPE,
});

function Header({ user, setUser, setAuthState }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSidebarOpen = () => {
    setIsSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsSidebarOpen(false);
  }, [location]);

  const handleEditUser = async () => {
    navigate("/account/list/edit?user-id=" + user.id);
    handleClose();
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setUser(null);
      setAuthState({ user: null, state: "signIn" });
      navigate("/");
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <div>
      {user ? (
        <React.Fragment>
          <AppBar
            position="static"
            sx={{
              width: "100vw",
              display: "flex",
              flexDirection: "row",
              position: "fixed",
              alignItems: "center",
              zIndex: 500,
              boxShadow: 3,
            }}
          >
            <Button
              onClick={handleSidebarOpen}
              size="small"
              sx={{
                display: "none",
                alignItems: "center",
                color: "white",
                borderColor: "white",
                border: "1px solid",
                minWidth: "48px",
                marginLeft: "16px",
                "@media screen and (max-width:1200px)": {
                  display: "flex",
                  position: "absolute",
                },
              }}
            >
              <MenuIcon />
            </Button>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: 72,
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                災害時情報収集システム
              </Typography>
            </Box>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}><BusinessIcon />{user.m_organization.department_name}</MenuItem>
                <MenuItem onClick={handleEditUser}><PersonIcon />{user.name}</MenuItem>
                <MenuItem onClick={handleSignOut}><ExitToAppIcon />ログアウト</MenuItem>
              </Menu>
            </div>
          </AppBar>
          <Drawer
            anchor="left"
            open={isSidebarOpen}
            onClose={handleSidebarClose}
          >
            <SideBar/>
          </Drawer>
        </React.Fragment>
        ) : (<></>)}
    </div>
  );
}

export default Header;
