import { TextField, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { fetchSession } from "../../../../../auth";

export default function SsSelect(props) {
  const { prefCode, divisionCode, changedValue } = props;
  console.log("pref, divisionCode", prefCode, divisionCode);
  const [sites, setsites] = useState([]);
  const getDisasters = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/sites`;

    const params = {
      params: {
        name: null, // 災害名称があいまい検索
        pref: [prefCode],
        division_code: [divisionCode],
        order: null, // デフォルト: complete_at
        sort: null, // デフォルト: desc, asc|desc
        page: 1,
        limit: null,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };

    console.log("SS情報取得入力データ", params);
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.sites;
        console.log("SS情報取得", response);
        data ? setsites(data) : console.error("ヒットするSSなし");
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };
  useEffect(() => {
    getDisasters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefCode, divisionCode]);

  const handleChange = (event, newValues) => {
    changedValue(newValues?.id);
  };

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={sites}
        getOptionLabel={(disaster) => disaster["name"]}
        isOptionEqualToValue={(option, value) => option["id"] === value["id"]}
        renderInput={(params) => <TextField {...params} label="SS選択" />}
        onChange={handleChange}
      />
    </>
  );
}
