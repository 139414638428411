import React, { useState, useEffect } from "react";
import { Box, Fab } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import DamageStatus from "./Cards/DamageStatus";
import SaleStatus from "./Cards/SaleStatus/SaleStatus";
import SnackbarCustom from "../../../../../components/SnackBarCustom";
import PopoverCustom from "../../../../../components/PopoverCustom";
import { fetchSession } from "../../../../../auth";
import DisasterStatus from "../../../DisasterStatus";

export default function RecoveryReport() {
  const location = useLocation();

  // location.stateからpreviousPageの情報を取得
  const previousPage =
    location.state?.previousPage === "sslist" ? "対象SS一覧" : "SS被害状況確認";
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // 前の画面に戻る
  };
  //クエリパラメータ取得
  const searchParams = new URLSearchParams(useLocation().search);
  const disasterId = searchParams.get("disaster-id");
  const statusId = searchParams.get("status-id");

  const [disasterStatuses, setDisasterStatuses] = useState({});

  const getDisasterStatus = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster-status/${statusId}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, headers)
      .then((response) => {
        const data = response.data.disaster_status;
        const status = response.data;
        setDisasterStatuses(data);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  useEffect(() => {
    getDisasterStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //各入力欄の入力内容を一括管理
  const handleDisasterStatusChange = (newDamageStatus) => {
    setDisasterStatuses((prevValue) => ({
      ...prevValue,
      //スプレッド構文で展開し、値を更新
      ...newDamageStatus,
    }));
    // ここで他の処理を行う
  };

  //日付の入力出力フォーマット調整
  const dateFormatter = (e) => {
    const date = new Date(e);
    if (!date) {
      // eがnullの場合（カレンダーがクリアされた場合）は何もしない
      return null;
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = "00"; // SSは常に"00"に設定
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  };

  //日付の入力出力フォーマット調整（年月日）
  const dateFormatterDay = (e) => {
    const date = new Date(e);
    if (!date) {
      // eがnullの場合（カレンダーがクリアされた場合）は何もしない
      return null;
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  //damage_atのフォーマット調整
  const formattedDamageAt = dateFormatter(disasterStatuses?.damage_at);
  const formattedRestartDate = dateFormatterDay(disasterStatuses?.restart_date);
  const formattedRecoveryScheduleDate = dateFormatterDay(
    disasterStatuses?.recovery_schedule_date
  );
  const formattedRecoveryDate = dateFormatterDay(
    disasterStatuses?.recovery_date
  );
  const formattedAirtightDate = dateFormatterDay(
    disasterStatuses?.airtight_date
  );

  //復旧報告登録
  const putrecoberyReport = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster-status`;
    const params = {
      id: statusId | 0,
      disaster_id: disasterId | 0,
      //DamageStatus内の変更
      damage_at: formattedDamageAt,
      not_damage: disasterStatuses?.not_damage,
      personal_damage: disasterStatuses?.personal_damage,
      property_damage: disasterStatuses?.property_damage,
      facility_damage: disasterStatuses?.facility_damage,
      damage_details:
        !disasterStatuses?.damage_details ||
        !disasterStatuses?.damage_details.match(/\S/g)
          ? null
          : disasterStatuses?.damage_details,

      //SaleStatus内の変更
      sale_detail_status: disasterStatuses?.sale_detail_status,
      sale_status: disasterStatuses?.sale_status,
      reasons_closing_status: disasterStatuses?.reasons_closing_status,
      water_test_status: disasterStatuses?.water_test_status,
      reasons_closing:
        !disasterStatuses?.reasons_closing ||
        !disasterStatuses?.reasons_closing.match(/\S/g)
          ? null
          : disasterStatuses?.reasons_closing,

      //Other1内の変更
      facility_damage_category: disasterStatuses?.facility_damage_category,
      reasons_closing_category: disasterStatuses?.reasons_closing_category,

      //RestartDate内の変更
      restart_date: formattedRestartDate,
      restart_method: disasterStatuses?.restart_method,

      //RecoveryWork内の変更
      recovery_content:
        !disasterStatuses?.recovery_content ||
        !disasterStatuses?.recovery_content.match(/\S/g)
          ? null
          : disasterStatuses?.recovery_content,
      recovery_status: disasterStatuses?.recovery_status,
      recovery_schedule_date: formattedRecoveryScheduleDate,
      recovery_date: formattedRecoveryDate,

      //Examinate内の変更
      airtight_date: formattedAirtightDate,
      airtight_result: disasterStatuses?.airtight_result,

      //Tanker内の変更
      is_receipt: disasterStatuses?.is_receipt,
      receipt_details:
        !disasterStatuses?.receipt_details ||
        !disasterStatuses?.receipt_details.match(/\S/g)
          ? null
          : disasterStatuses?.receipt_details,

      //Other2内の変更
      is_electric: disasterStatuses?.is_electric,
      remark:
        !disasterStatuses?.remark || !disasterStatuses?.remark.match(/\S/g)
          ? null
          : disasterStatuses?.remark,
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    if (isSuccess) {
      axios
        .put(apiUrl, params, headers)
        .then((response) => {
          const status = response.data;
          //スナックバーステート
          setSnackBarstatus(status);
          setSnackbarOpen(true);
        })
        .catch((error) => {
          console.error("エラーが発生しました:", error);
        })
    }
  };

  //登録ボタンクリック
  const [popoverRegisterOpen, setPopoverRegisterOpen] = useState(false);
  const registerButtonClick = () => {
    setPopoverRegisterOpen(true);
  };
  const registerPopupClose = (event) => {
    setPopoverRegisterOpen(false);
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          "@media screen and (max-width:900px)": {
            flexDirection: "column",
            gap: "-16px",
          },
        }}
      >
        <Box
          onClick={handleGoBack}
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            paddingLeft: "40px",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            color: "primary.main",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
              color: "primary.dark",
            },
            "&:active": {
              color: "primary.light",
            },
            "@media screen and (max-width:900px)": {
              paddingLeft: 2,
            },
          }}
        >
          ダッシュボード/{previousPage}
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            "@media screen and (max-width:900px)": {
              paddingLeft: 2,
            },
          }}
        >
          /被害状況報告
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          padding: "16px",
          borderRadius: 2,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <DisasterStatus disasterStatus={disasterStatuses} />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "32px",
          "@media screen and (max-width:600px)": {
            flexDirection: "column",
          },
        }}
      >
        <DamageStatus
          disasterStatus={disasterStatuses}
          onDamageStatusChange={handleDisasterStatusChange}
        />
        <SaleStatus
          disasterStatus={disasterStatuses}
          onSaleStatusChange={handleDisasterStatusChange}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "32px",
          "@media screen and (max-width:600px)": {
            flexDirection: "column",
          },
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          gap: "32px",
          "@media screen and (max-width:600px)": {
            flexDirection: "column",
          },
        }}
      >
        {/* <TankStatus siteId={disasterStatuses.site_id} /> */}
      </Box>
      <PopoverCustom
        open={popoverRegisterOpen}
        onClose={registerPopupClose}
        yesNo={putrecoberyReport}
        title={"登録確認"}
        contents={"登録してもよろしいですか？"}
      />
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          position: "sticky",
          bottom: "16px",
        }}
      >
        <Fab
          variant="extended"
          color="primary"
          onClick={registerButtonClick}
          sx={{
            width: "144px",
          }}
        >
          登録
        </Fab>
      </Box>
    </>
  );
}
