import { Box } from "@mui/material";
import React from "react";
import OfficeTable from "./OfficeTable";
import PrefectureTable from "./PrefectureTable";
import InformationTable from "./InformationTable";
import { useNavigate } from "react-router-dom";
import Disaster from "../../Disaster";

export default function Totalization() {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // 前の画面に戻る
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          onClick={handleGoBack}
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            paddingLeft: 5,
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            color: "primary.main",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
              color: "primary.dark",
            },
            "&:active": {
              color: "primary.light",
            },
            "@media screen and (max-width:900px)": {
              paddingLeft: 2,
            },
          }}
        >
          ダッシュボード
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          /集計表
        </Box>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          height: "48px",
          alignItems: "center",
          paddingLeft: 5,
          fontSize: 20,
          fontWeight: "bold",
          whiteSpace: "nowrap",
        }}
      >
        災害時情報収集ダッシュボード　集計表
      </Box> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          padding: "16px",
          borderRadius: 2,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        {/* <DisasterTable /> */}
        <Disaster />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          paddingBottom: "32px",
          borderRadius: 2,
          borderTop: 16,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            paddingTop: 2,
            paddingRight: 5,
            paddingLeft: 5,
            paddingBottom: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 20,
              fontWeight: "bold",
              color: "primary.main",
              whiteSpace: "nowrap",
            }}
          >
            支店別
          </Box>
        </Box>
        <OfficeTable />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          paddingBottom: "32px",
          borderRadius: 2,
          borderTop: 16,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            paddingTop: 2,
            paddingRight: 5,
            paddingLeft: 5,
            paddingBottom: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 20,
              fontWeight: "bold",
              color: "primary.main",
              whiteSpace: "nowrap",
            }}
          >
            都道府県別
          </Box>
        </Box>
        <PrefectureTable />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          paddingBottom: "32px",
          borderRadius: 2,
          borderTop: 16,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            paddingTop: 2,
            paddingRight: 5,
            paddingLeft: 5,
            paddingBottom: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 20,
              fontWeight: "bold",
              color: "primary.main",
              whiteSpace: "nowrap",
            }}
          >
            災害情報別
          </Box>
        </Box>
        <InformationTable />
      </Box>
    </>
  );
}
