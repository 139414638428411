import { TextField, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { fetchSession } from "../../../../auth";
import { formatDate } from "../../../../utils/deteUtils";

const DisasterSelect = (props) => {
  const { changedValue, value } = props;

  const [disasters, setDisasters] = useState([]);
  const [selectedDisaster, setSelectedDisaster] = useState(null);

  const getDisasters = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disasters`;

    const params = {
      params: {
        name: null,
        is_completed: true,
        exclude_id: null,
        start_at_stt: null,
        start_at_end: null,
        order: null,
        sort: null,
        page: 1,
        limit: null,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };

    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.disasters;
        if (data) {
          setDisasters(data);

          // 初期のvalueに対応する災害を設定
          const initialDisaster = data.find(
            (disaster) => disaster.id === value
          );
          setSelectedDisaster(initialDisaster || null);
        }
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  // データ取得時に実行
  useEffect(() => {
    getDisasters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // 初回マウント時のみ実行

  // value が変更された時に選択済み災害を更新
  useEffect(() => {
    if (value && disasters.length > 0) {
      const initialDisaster = disasters.find(
        (disaster) => disaster.id === value
      );
      setSelectedDisaster(initialDisaster || null);
    }
  }, [value, disasters]); // value または disasters が変更されたら実行

  const handleChange = (event, newValues) => {
    setSelectedDisaster(newValues);
    changedValue({ id: newValues?.id, name: newValues?.name });
  };

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={disasters}
        value={selectedDisaster}
        getOptionLabel={(disaster) =>
          formatDate(disaster["create_at"]) +
          (disaster["kind"] === "quake"
            ? " 地震"
            : disaster["kind"] === "w-r-s"
            ? " 雨風雪"
            : disaster["kind"] === "manual"
            ? " 手動"
            : " その他") +
          disaster["name"]
        }
        isOptionEqualToValue={(option, value) => option["id"] === value["id"]}
        renderInput={(params) => <TextField {...params} label="災害選択" />}
        onChange={handleChange}
      />
    </>
  );
};

export default DisasterSelect;
