import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import IconTable from "./IconTable";
import SsNameTable from "./SsNameTable";

export default function TenantSetting() {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // 前の画面に戻る
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          "@media screen and (max-width:900px)": {
            flexDirection: "column",
            gap: "-16px",
          },
        }}
      >
        <Box
          onClick={handleGoBack}
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            paddingLeft: "40px",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            color: "primary.main",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
              color: "primary.dark",
            },
            "&:active": {
              color: "primary.light",
            },
            "@media screen and (max-width:900px)": {
              paddingLeft: 2,
            },
          }}
        >
          テナント管理
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            "@media screen and (max-width:900px)": {
              paddingLeft: 2,
            },
          }}
        >
          /アイコン編集
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderTop: 16,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            paddingTop: "16px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 20,
              fontWeight: "bold",
              color: "primary.main",
              whiteSpace: "nowrap",
            }}
          >
            アイコン設定
          </Box>
        </Box>
        <IconTable />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderTop: 16,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            paddingTop: "16px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            gap: "32px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 20,
              fontWeight: "bold",
              color: "primary.main",
              whiteSpace: "nowrap",
            }}
          >
            名称設定
          </Box>
        </Box>
        <SsNameTable />
      </Box>
    </>
  );
}
