import { TextField, Autocomplete } from "@mui/material";

const facilityDamages = [
  {
    key: 1,
    value: "SS施設被害分類1",
  },
  {
    key: 2,
    value: "SS施設被害分類2",
  },
  {
    key: 3,
    value: "SS施設被害分類3",
  },
];

export default function FacilityDamageCategorySelect(props) {
  const { facilityDamageCategory, changedValue } = props;

  //第一引数にイベント情報、第二引数に選択された新しい値を受け取ることができる。
  const handleChange = (event, newValues) => {
    changedValue(newValues ? newValues.key : null);
  };
  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={facilityDamages}
        getOptionLabel={(sale) => sale["value"]}
        isOptionEqualToValue={(option, value) => option["key"] === value["key"]}
        value={
          facilityDamages.find(
            (status) => status.key === facilityDamageCategory
          ) || null
        }
        renderInput={(params) => (
          <TextField {...params} placeholder="SS施設被害分類選択"/>
        )}
        onChange={handleChange}
      />
    </>
  );
}
