import React, {useState, useEffect, useContext} from "react";
import { Box, TextField, Checkbox, Button } from "@mui/material";
import axios from "axios";
import SnackbarCustom from "../../components/SnackBarCustom";
import PopoverCustom from "../../components/PopoverCustom";
import { fetchSession } from "../../auth";
import {TokenContext} from "../../TokenContext";
import {DisasterConditionRole} from "../../role";

export default function Environment() {
  const { user } = useContext(TokenContext);
  const disasterConditionRole = DisasterConditionRole(user.m_organization.m_role_id);
  //一覧取得処理
  const [settings, setSettings] = useState({
    id: 1,
    maintenance: false,
    training: false,
    from_name: "",
  });

  const getConfig = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/config`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    // axiosを使用してGETリクエストを送信
    axios
      .get(apiUrl, headers)
      .then((response) => {
        const data = response.data.config;
        const status = response.data;
        console.log("APIレスポンス", response);
        setSettings(data);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  useEffect(() => {
    getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTraining = (e) => {
    const newValue = e.target.checked;
    setSettings((prevCheck) => ({
      ...prevCheck,
      training: newValue,
    }));
  };

  const handleChangeMaintenance = (e) => {
    const newValue = e.target.checked;
    setSettings((prevCheck) => ({
      ...prevCheck,
      maintenance: newValue,
    }));
  };

  const handleChangeName = (e) => {
    const newValue = e.target.value;
    setSettings((prevValue) => ({
      ...prevValue,
      from_name: newValue,
    }));
  };

  //登録確認ポップオーバー確認
  const [confirmPopoverOpen, setPopoverSplitOpen] = useState(false);
  const registerButtonClick = () => {
    setPopoverSplitOpen(true);
  };
  const confirmPopupClose = (event) => {
    setPopoverSplitOpen(false);
  };

  //登録処理
  const putConfigData = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/config?id=1`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("入力データ", settings);
    isSuccess
      ? axios
          .put(apiUrl, settings, headers)
          .then((response) => {
            console.log("レスポンスデータ:", response.data);
            const status = response.data;
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.error("エラーが発生しました:", error);
          })
      : console.log("何も行いません");
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "100%",
          maxWidth: "480px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            // alignItems: "center",
            alignItems: "flex-start",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            "@media screen and (max-width:900px)": {
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
            },
          }}
        >
          <Box
            sx={{
              minWidth: "160px",
              padding: "9px 0",
              "@media screen and (max-width:900px)": {
                padding: "0",
              },
            }}
          >
            モード
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  size="small"
                  checked={settings?.training}
                  onChange={handleChangeTraining}
                  disabled={!disasterConditionRole.update}
                />
                訓練モード
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  size="small"
                  checked={settings?.maintenance}
                  onChange={handleChangeMaintenance}
                  disabled={!disasterConditionRole.update}
                />
                メンテナンスモード
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            "@media screen and (max-width:900px)": {
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
            },
          }}
        >
          <Box sx={{ minWidth: "160px" }}>メール送信元名称</Box>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={settings?.from_name}
            onChange={(e) => handleChangeName(e)}
            sx={{
              height: "40px",
              width: "100%",
              ".MuiInputBase-root": {
                height: "100%",
              },
              "#outlined-basic": {
                boxSizing: "border-box",
                height: "100%",
              },
            }}
            disabled={!disasterConditionRole.update}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "16px",
            "@media screen and (max-width:600px)": {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <Button
            variant="contained"
            sx={{ width: "208px" }}
            onClick={() => registerButtonClick()}
            disabled={!disasterConditionRole.update}
          >
            登録
          </Button>
        </Box>
      </Box>
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
      <PopoverCustom
        open={confirmPopoverOpen}
        onClose={confirmPopupClose}
        yesNo={putConfigData}
        title={"登録確認"}
        contents={"環境を登録してもよろしいですか？"}
      />
    </>
  );
}
