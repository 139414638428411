import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Dialog,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SnackbarCustom from "../../../components/SnackBarCustom";
import { fetchSession } from "../../../auth";

const columns = [
  { id: "name", label: "権限名", minWidth: "120px", isSmallScreen: true },
  {
    id: "MBranchCount",
    label: "関連付け組織数",
    minWidth: "120px",
    isSmallScreen: false,
  },
  {
    id: "UserCount",
    label: "ユーザー数",
    minWidth: "120px",
    isSmallScreen: false,
  },
  {
    id: "buttonSpace",
    label: "",
    align: "right",
    minWidth: "120px",
    button: true,
    isSmallScreen: true,
  },
];

export default function AuthorityTable() {
  const navigate = useNavigate();

  const handleClick = (event, path) => {
    event.stopPropagation();
    navigate(path);
  };

  const [selectedRow, setSelectedRow] = useState(null);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  //メールテンプレート一覧取得※エラー修正待ち
  const [roles, setRoles] = useState([]);
  const getRoles = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/roles`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, headers)
      .then((response) => {
        console.log("権限設定一覧取得", response);
        const data = response.data.m_roles;
        const status = response.data;
        setRoles(data);
        // スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
        console.log("スナックバー表示されたよ");
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLocationCodeClick = (row) => {
    setSelectedRow(row);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const buttonVaridate = (roleId) => {
    return (
      <Box sx={{ width: "100%", textAlign: "right" }}>
        <Button
          variant="contained"
          onClick={(event) =>
            handleClick(
              event,
              `/account/authority/connecting?role-id=${roleId}`
            )
          }
          sx={{ pointerEvents: "auto" }}
        >
          関連付け
        </Button>
      </Box>
    );
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    // align={"column.align"}
                    style={{ minWidth: column.minWidth }}
                    sx={{
                      color: "outline.dark",
                      fontWeight: "bold",
                      borderBottom: 2,
                      padding: "8px",
                      "@media screen and (max-width:900px)": {
                        display:
                          column.isSmallScreen === true ? "table-cell" : "none",
                      },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {roles?.map((role) => {
                return (
                  <TableRow
                    hover={false}
                    role="checkbox"
                    tabIndex={-1}
                    key={role.id}
                    onClick={() => handleLocationCodeClick(role)}
                    sx={{
                      pointerEvents: "none",
                      "@media screen and (max-width:900px)": {
                        cursor: "pointer",
                        pointerEvents: "auto",
                      },
                    }}
                  >
                    {columns.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          sx={{
                            padding: "8px",
                            "@media screen and (max-width:900px)": {
                              display:
                                column.isSmallScreen === true
                                  ? "table-cell"
                                  : "none",
                            },
                          }}
                        >
                          {column.button
                            ? buttonVaridate(role.id)
                            : role[column.id]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
          <Box
            sx={{
              display: "flex",
              height: "56px",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              borderBottom: "2px solid #CCC",
            }}
          >
            <Box
              sx={{
                paddingLeft: "8px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              災害情報詳細
            </Box>
            <IconButton
              aria-label="close"
              onClick={handlePopoverClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
            <TableBody>
              {selectedRow &&
                columns.map((column) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                      <TableCell
                        // align={column.align}
                        sx={{
                          color: "outline.dark",
                          fontWeight: "bold",
                          boxSizing: "border-box",
                          width: "calc((100vw - 64px) / 2)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            WebkitWritingMode: "horizontal-tb",
                            height: "100%",
                            padding: "8px",
                          }}
                        >
                          {column.label}
                        </Box>
                      </TableCell>
                      <TableCell
                        // align={column.align}
                        sx={{
                          writingMode: "horizontal-tb",
                          boxSizing: "border-box",
                          width: "calc((100vw - 64px) / 2)",
                          overflowWrap: "break-word",
                        }}
                      >
                        {column.button
                          ? buttonVaridate(selectedRow.id)
                          : selectedRow[column.id]}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Dialog>
        <SnackbarCustom
          data={snackBarstatus}
          openStatus={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
        />
      </Paper>
    </>
  );
}
