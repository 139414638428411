import { useState } from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import axios from "axios";
import marker_icon from "../../assets/images/default_marker_gray.svg";
import { fetchSession } from "../../auth";
const libraries = ["geometry", "drawing"];

export default function Map(props) {
  const { selectId, selectOperator } = props;

  const container = {
    width: "100%",
    height: "824px",
  };

  const [mapBounds, setMapBounds] = useState(null); // 追加: 地図の表示領域の座標\
  const [damageOnly, setDamageOnly] = useState(false); //対象SS設定\
  const [sites, setSites] = useState();
  //マップ初期表示中心座標
  const [position, setPosition] = useState({
    lat: 35.6813985,
    lng: 139.673278,
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // googleMapsApiKey: "AIzaSyBVhuJswpuJDZkhGLd92pa12m-FIDuzM2I",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries, // コンポーネント外で定義した libraries を使用
  });

  const [previousSites, setPreviousSites] = useState([]);

  const arraysEqual = (arr1, arr2) =>
    JSON.stringify(arr1) === JSON.stringify(arr2);

  const handleBoundsChanged = async () => {
    if (mapBounds) {
      const tokenInfo = await fetchSession();
      const apiUrl = `${process.env.REACT_APP_BASE_URL}/status-map`;
      const bounds = mapBounds.getBounds();
      const center = mapBounds.getCenter();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const params = {
        params: {
          disaster_id: null,
          ne_lat: ne.lat(),
          sw_lat: sw.lat(),
          ne_lng: ne.lng(),
          sw_lng: sw.lng(),
          damage_only: damageOnly,
          limit: null,
        },
        headers: {
          Authorization: `Bearer ${tokenInfo.accessToken}`,
        },
      };
      // axiosでGETリクエストを行う
      axios
        .get(apiUrl, params)
        .then((response) => {
          // 取得したデータを処理する
          if (!arraysEqual(response.data.sites, previousSites)) {
            setPosition(center);
            setSites(response.data.sites);
            setPreviousSites(response.data.sites);
          }
        })
        .catch((error) => {
          console.error("GETリクエストエラー:", error);
        });
    }
  };

  useEffect(() => {
    handleBoundsChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [damageOnly]);

  const markerClick = (site) => {
    // クエリパラメータに "site-id" を追加する処理
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("site-id", site.id);

    // 新しい URL を作成してブラウザの履歴を変更
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
    selectId(site.id);
    selectOperator(site.operator + "\n" + site.name);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "0 40px 32px 40px",
          "@media screen and (max-width:900px)": {
            padding: "0 8px 8px 8px",
            height: "400px",
          },
        }}
      >
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={container}
            center={position}
            zoom={5.7}
            // onLoad={handleMapLoad}
            onLoad={(map) => setMapBounds(map)}
            onIdle={handleBoundsChanged}
          >
            {sites?.map((site) => (
              <MarkerF
                key={site.site.id}
                position={{ lat: site.site.lati, lng: site.site.longi }}
                options={{
                  clickable: true,
                  icon: {
                    scale: 0.002,
                    url:
                      site.icon_file_key === ""
                        ? marker_icon
                        : `https://ljs-dic-public-resources-dev.s3.ap-northeast-1.amazonaws.com/${site.icon_file_key}`,
                    size: { width: 40, height: 40 },
                    scaledSize: new window.google.maps.Size(40, 40),
                  },
                }}
                onClick={() => markerClick(site.site)}
              />
            ))}
          </GoogleMap>
        )}
      </Box>
    </>
  );
}
