import React from "react";
import TextField from "@mui/material/TextField";

export default function TextFieldValidate(props) {
  const { input, column, changedValue } = props;
  const handleChange = (e) => {
    if (typeof changedValue === "function") {
      changedValue(e.target.value);
    }
  };

  return (
    <>
      <TextField
        size="small"
        defaultValue={input[column]}
        sx={{ pointerEvents: "auto" }}
        onChange={handleChange}
      />
    </>
  );
}
