import { Box } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import Unauthorized from "./components/Unauthorized";
import NotFound from "./components/NotFound";
import Footer from "./components/Footer";
import Dashboard from "./pages/Dashboard/Dashboard";
import SsLog from "./pages/SsLog/SsLog";
import SsState from "./pages/SsState/SsState";
import SsInfomation from "./pages/SsInfomation/SsInfomattion";
import SsImport from "./pages/SsInfomation/SsImport/SsImport";
import MailLog from "./pages/MailSetting/MailLog/MailLog";
import Mailing from "./pages/MailSetting/MailLog/Mailing/Mailing";
import MailTemplate from "./pages/MailSetting/MailTemplate/MailTemplate";
import TemplateRegistration from "./pages/MailSetting/MailTemplate/TemplateRegistration/TemplateRegistration";
import Totalization from "./pages/Dashboard/DashboardPages/Totalization/Totalization";
import SituationCheck from "./pages/Dashboard/DashboardPages/SituationCheck/SituationCheck";
import RecoveryReport from "./pages/Dashboard/DashboardPages/SituationCheck/RecoveryReport/RecoveryReport";
import SsList from "./pages/Dashboard/DashboardPages/SsList/SsList";
import DisasterMap from "./pages/Dashboard/DashboardPages/DisasterMap/DisasterMap";
import AccountList from "./pages/Account/AccountList/AccountList";
import AccountImport from "./pages/Account/AccountList/AccountImport/AccountImport";
import MailAddressImport from "./pages/Account/AccountList/MailAddressImport/MailAddressImport";
import AccountEdit from "./pages/Account/AccountList/AccountEdit/AccountEdit";
import AccountAuthority from "./pages/Account/AccountAuthority/AccountAuthority";
import Connecting from "./pages/Account/AccountAuthority/Connecting/Connecting";
import Setting from "./pages/Setting/Setting";
import Tenant from "./pages/Tenant/Tenant";
import TenantSetting from "./pages/Tenant/TenantSetting/TenantSetting";
import Error from "./components/Error";
import ProtectedRoute from "./components/ProtectedRoute";
import "./styles/styles.scss";
import Forbidden from "./components/Forbidden";

I18n.putVocabularies(translations);
I18n.setLanguage("ja");

Amplify.configure(awsExports);

function AppInner({user, authState}) {
  return (
    <>
      <Box
        sx={{
          marginTop: "72px",
          marginLeft: "272px",
          width: "calc(100% - 272px)",
          height: "100%",
          minHeight: "calc(100% - 72px)",
          "@media screen and (max-width:1200px)": {
            width: "100%",
            marginLeft: "0px",
          },
        }}
      >
        <Box
          clasname="mainframe"
          sx={{
            height: "fit-content",
            minHeight: "calc(100% - 72px)",
            display: "flex",
            flexDirection: "column",
            padding: "40px",
            paddingBottom: "80px",
            gap: "40px",
            backgroundColor: "#F8F8F8",
            "@media screen and (max-width:900px)": {
              padding: "16px",
              gap: "16px",
            },
          }}
        >
          <Routes>
            <Route path="/" element={
              <Navigate to={authState === "signedIn" ? "/dashboard" : "/"} />
            } />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route exact path="/dashboard/disastermap" element={<DisasterMap />} />
            <Route exact path="/dashboard/sslist" element={<SsList />} />
            <Route exact path="/dashboard/situationcheck" element={<SituationCheck />} />
            <Route exact path="/dashboard/situationcheck/report" element={<RecoveryReport />} />
            <Route exact path="/dashboard/totalization" element={
              <ProtectedRoute user={user} requiredRole="TotalizationRole">
                <Totalization />
              </ProtectedRoute>
            } />
            <Route exact path="/sslog" element={<SsLog />} />
            <Route exact path="/ssstate" element={<SsState />} />
            <Route exact path="/ssinfo" element={<SsInfomation />} />
            <Route exact path="/ssinfo/import" element={
              <ProtectedRoute user={user} requiredRole="SSMasterImportRole">
                <SsImport />
              </ProtectedRoute>
            } />
            <Route exact path="/mail/log" element={
              <ProtectedRoute user={user} requiredRole="MailSendRole">
                <MailLog />
              </ProtectedRoute>
            } />
            <Route exact path="/mail/log/mailing" element={
              <ProtectedRoute user={user} requiredRole="MailSendRole">
                <Mailing />
              </ProtectedRoute>
            } />
            <Route exact path="/mail/template" element={
              <ProtectedRoute user={user} requiredRole="MailTemplateRole">
                <MailTemplate />
              </ProtectedRoute>
            } />
            <Route exact path="/mail/template/registation" element={
              <ProtectedRoute user={user} requiredRole="MailTemplateRole">
                <TemplateRegistration />
              </ProtectedRoute>
            } />
            <Route exact path="/account/list" element={
              <ProtectedRoute user={user} requiredRole="UserRole">
                <AccountList />
              </ProtectedRoute>
            } />
            <Route exact path="/account/list/import" element={
              <ProtectedRoute user={user} requiredRole="UserImportRole">
                <AccountImport />
              </ProtectedRoute>
            } />
            <Route exact path="/account/list/importaddress" element={
              <ProtectedRoute user={user} requiredRole="UserImportRole">
                <MailAddressImport />
              </ProtectedRoute>
            } />
            <Route exact path="/account/list/edit" element={
              <ProtectedRoute user={user} requiredRole="UserRole">
                <AccountEdit />
              </ProtectedRoute>
            } />
            <Route exact path="/account/authority" element={
              <ProtectedRoute user={user} requiredRole="UserRole">
                <AccountAuthority />
              </ProtectedRoute>
            } />
            <Route exact path="/account/authority/connecting" element={
              <ProtectedRoute user={user} requiredRole="UserRole">
                <Connecting />
              </ProtectedRoute>
            } />
            <Route exact path="/setting" element={
              <ProtectedRoute user={user} requiredRole="DisasterConditionRole">
                <Setting />
              </ProtectedRoute>
            } />
            <Route exact path="/tenant" element={
              <ProtectedRoute user={user} requiredRole="TenantRole">
                <Tenant />
              </ProtectedRoute>
            } />
            <Route exact path="/tenant/setting" element={
              <ProtectedRoute user={user} requiredRole="TenantRole">
                <TenantSetting />
              </ProtectedRoute>
            } />
            <Route path="*" element={<NotFound />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/forbidden" element={<Forbidden />} />
            <Route path="/error" element={<Error title={"エラーが発生しました"} message={""} />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default AppInner;
