// // src/TokenContext.js

import React, { createContext, useState, useEffect } from 'react';
import axios from "axios";
import {Amplify, Auth} from "aws-amplify";
import awsExports from "./aws-exports";

export const TokenContext = createContext();
export const TokenProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authState, setAuthState] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const fetchUserData = async () => {
    // const tokenInfo = await fetchSession();
    Amplify.configure(awsExports);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const accessToken = user.getSignInUserSession().getAccessToken().getJwtToken()
        const apiUrl = `${process.env.REACT_APP_BASE_URL}/user/0`;
        const headers = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        axios
          .get(apiUrl, headers)
          .then((response) => {
            const data = response.data.current_user;
            setUser(data);
            setAuthState({ user, state: "signedIn" });
          })
          .catch((error) => {
            console.error("TokenProvider.fetchUserData Error: ", error);
          }).finally(() => {
          setIsLoading(false); // Set loading to false once auth state is determined
        });
      })
      .catch(() => {
        setAuthState({ user: null, state: "signIn" });
        setIsLoading(false); // Set loading to false once auth state is determined
      });
  }

  // APIを呼び出してユーザー情報を取得する関数
  useEffect(() => {
    fetchUserData();
  }, []);
  return (
    <TokenContext.Provider value={{ user, setUser, authState, setAuthState, isLoading, fetchUserData }}>
        {children}
    </TokenContext.Provider>
  );
};
