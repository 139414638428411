import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import SnackbarCustom from "../../../../components/SnackBarCustom";
import { fetchSession } from "../../../../auth";

const columns = [
  {
    id: "department_name",
    label: "部名",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: false,
  },
];

const DisconnectedTable = (props) => {
  const { CheckedItems, disconnectedOrganizations,update } = props;

  //ページネーション管理
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //メールテンプレート一覧取得※エラー修正待ち
  const [organizations, setOrganizations] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const getOrganizations = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/organizations`;
    const params = {
      params: {
        parent_m_organization_id: 0,
        m_branch_id: null,
        m_role_id: 0,// 未設定
        order: null,
        sort: null,
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.m_organizations;
        const branchName = data.map((dataSingle) => dataSingle.m_branch.name);
        //会社名をm_branchから抜き出しm_organizationにm_branch_nameとして追加
        const dataCombined = data.map((item, index) => {
          // オブジェクトのコピーを作成
          const newItem = { ...item };
          newItem.m_branch_name = branchName[index];
          return newItem;
        });
        const totalCount = response.data.total_count;
        const status = response.data;
        // setOrganizations(stableSort(data, getComparator(order, orderBy)));
        setOrganizations(dataCombined);
        setTotalCount(totalCount);
        // setSortedOrganizations(stableSort(data, getComparator(order, orderBy)));
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };
  useEffect(() => {
    getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update,rowsPerPage, page]);

  const [selectedRow, setSelectedRow] = useState(null);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const CheckboxVaridate = (props) => {
    const { organization, organizationIds } = props;
    const { id: roleId, parent_m_organization_id: parentId } = organization;
  
    // 全ての組織をフラットな配列にする関数
    const flattenOrganizations = (orgs) => {
      let flat = [];
      orgs.forEach((org) => {
        flat.push(org);
        if (org.child_m_organizations && org.child_m_organizations.length > 0) {
          flat = flat.concat(flattenOrganizations(org.child_m_organizations));
        }
      });
      return flat;
    };
  
    // 再帰的に親階層を辿ってチェックする関数
    const checkRecursiveParents = (
      parentId,
      organizations,
      updatedIds,
      recursionCount = 1
    ) => {
      if (!parentId) return updatedIds; // 親がいない場合は終了
  
      // 全組織をフラット化して親子関係を見つけやすくする
      const flatOrganizations = flattenOrganizations(organizations);
  
      // 親IDに属する子要素が一つでもチェックされているか確認
      const someChildrenChecked = flatOrganizations
        .filter((org) => org.parent_m_organization_id === parentId)
        .some((child) => updatedIds.includes(child.id));
  
      // 一つでも子がチェックされているなら親もチェック
      if (someChildrenChecked) {
        updatedIds = [...new Set([...updatedIds, parentId])];
      } else {
        // 子がチェックされていないなら親のチェックを外す
        updatedIds = updatedIds.filter((id) => id !== parentId);
      }
  
      // 親の親が存在する場合、更にその親も再帰的にチェック
      const parentOrg = flatOrganizations.find((org) => org.id === parentId);
      if (parentOrg && parentOrg.parent_m_organization_id !== null) {
        updatedIds = checkRecursiveParents(
          parentOrg.parent_m_organization_id,
          organizations,
          updatedIds,
          recursionCount + 1 // 再帰回数をインクリメント
        );
      }
  
      return updatedIds;
    };
  
    const isChecked =
      disconnectedOrganizations["m_organization_ids"]?.includes(roleId) ||
      organizationIds.some((id) =>
        disconnectedOrganizations["m_organization_ids"]?.includes(id)
      );
  
    const handleParentChildCheck = (checked, ids, parentId) => {
      CheckedItems((prevState) => {
        const currentIds = prevState["m_organization_ids"] || [];
        let updatedIds = [...currentIds];
  
        // チェックされた場合、全ての子要素のIDを追加
        if (checked) {
          updatedIds = [...new Set([...updatedIds, ...ids])];
        } else {
          // チェックが外された場合、全ての子要素のIDを削除
          updatedIds = updatedIds.filter((id) => !ids.includes(id));
        }
  
        // 親のチェック状態を再帰的に確認・更新
        if (parentId) {
          updatedIds = checkRecursiveParents(
            parentId,
            organizations,
            updatedIds
          );
        }
  
        return {
          ...prevState,
          m_organization_ids: updatedIds,
        };
      });
    };
  
    const handleChange = (e) => {
      handleParentChildCheck(e.target.checked, organizationIds, parentId);
    };
  
    return (
      <Checkbox
        checked={isChecked}
        icon={<ArrowCircleLeftOutlinedIcon />}
        checkedIcon={<ArrowCircleLeftIcon />}
        sx={{ pointerEvents: "auto" }}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleChange(e)}
      />
    );
  };

  const renderTree = (organization) => {
    const flattenOrganizationIds = (org) => {
      let ids = [];
      if (org.id) {
        ids.push(org.id);
      }
      if (Array.isArray(org.child_m_organizations)) {
        org.child_m_organizations.forEach((childOrg) => {
          ids = ids.concat(flattenOrganizationIds(childOrg));
        });
      }
      return ids;
    };
    const organizationIds = flattenOrganizationIds(organization);
    if (!organization) {
      return null;
    }

    return (
      <TreeItem
        key={organization.id}
        nodeId={String(organization.id)}
        label={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CheckboxVaridate
              organization={organization} // organizationオブジェクトをそのまま渡す
              organizationIds={organizationIds} // 子要素のIDリストを渡す
            />
            {organization.group_name === null
              ? organization.department_name
              : organization.department_name + "-" + organization.group_name}
          </Box>
        }
        sx={{
          display: "flex",
          flexDirection: "column",
          ".MuiTreeItem-iconContainer": {
            display: "none",
          },
          ".css-1vbkhv8-MuiTreeItem-content": {
            padding: "0",
          },
          ".css-1vbkhv8-MuiTreeItem-content .MuiTreeItem-label": {
            padding: "0",
          },
        }}
      >
        {Array.isArray(organization.child_m_organizations)
          ? organization.child_m_organizations.map((childOrganization) =>
              renderTree(childOrganization)
            )
          : null}
      </TreeItem>
    );
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        multiSelect
      >
        {Array.isArray(organizations)
          ? organizations
            .filter(
              (organization) =>
                organization.parent_m_organization_id === null
            )
            .map((organization) => renderTree(organization))
          : null}
      </TreeView>
      <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
        <Box
          sx={{
            display: "flex",
            height: "56px",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
            borderBottom: "2px solid #CCC",
          }}
        >
          <Box
            sx={{
              paddingLeft: "8px",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            未関連組織詳細
          </Box>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
          <TableBody>
            {selectedRow &&
              columns.map((column) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                    <TableCell
                      align={column.align}
                      sx={{
                        color: "outline.dark",
                        fontWeight: "bold",
                        boxSizing: "border-box",
                        width: "calc((100vw - 64px) / 2)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          WebkitWritingMode: "horizontal-tb",
                          height: "100%",
                          padding: "8px",
                        }}
                      >
                        {column.label}
                      </Box>
                    </TableCell>
                    <TableCell
                      align={column.align}
                      sx={{
                        writingMode: "horizontal-tb",
                        boxSizing: "border-box",
                        width: "calc((100vw - 64px) / 2)",
                        overflowWrap: "break-word",
                      }}
                    >
                      {column.button ? (
                        <CheckboxVaridate id={selectedRow.id} />
                      ) : (
                        <>{selectedRow[column.id]}</>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Dialog>
      <TablePagination
        rowsPerPageOptions={[10, 30, 50, 100]}
        component="div"
        count={totalCount | 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions": {
            margin: "0 !important",
            padding: "0 !important",
          },
        }}
      />
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </Paper>
  );
};
export default DisconnectedTable;
