import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

const Error = ({ title, message }) => {

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // 前の画面に戻る
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Typography variant="h3" color="error" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        {message}
      </Typography>
      <Button variant="outlined" color="primary" onClick={handleGoBack}>
        戻る
      </Button>
    </Box>
  );
};

export default Error;
