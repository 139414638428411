import React from 'react';
import {Typography, Box, Button} from '@mui/material';
import {useNavigate} from "react-router-dom";

const Forbidden = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // 前の画面に戻る
  };
  return (
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100vh - 264px)",
    }}
  >
    <Typography variant="h2" sx={{ marginBottom: "16px" }}>
      権限不正
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: "16px" }}>
      このページにアクセスする権限がありません。
    </Typography>
    <Button variant="outlined" color="primary" onClick={handleGoBack}>
      戻る
    </Button>
  </Box>
  );
};

export default Forbidden;
