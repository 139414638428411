import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Button,
  Divider,
  Dialog,
  IconButton,
} from "@mui/material";
import {
  Close as CloseIcon,
  ModeEditOutline as ModeEditOutlineIcon,
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
} from "@mui/icons-material";
import axios from "axios";
import SnackbarCustom from "../../../components/SnackBarCustom";
import PopoverDelete from "../../../components/PopoverDelete";
import { fetchSession } from "../../../auth";
import { formatDate } from "../../../utils/deteUtils";

const columns = [
  {
    id: "title",
    label: "件名",
    minWidth: "80px",
    isSmallScreen: true,
  },
  {
    id: "content",
    label: "本文",
    minWidth: "fit-content",
    align: "left",
    wordBreak: "break-all",
    isSmallScreen: true,
  },
  {
    id: "name",
    label: "作成者",
    minWidth: "80px",
    align: "left",
    isSmallScreen: false,
    isUser: true,
  },
  {
    id: "update_at",
    label: "更新日時",
    minWidth: "152px",
    align: "left",
    isSmallScreen: false,
  },
  {
    id: "operation",
    label: "",
    align: "left",
    button: true,
    isSmallScreen: true,
  },
];

const SearchResult = (props) => {
  const { searchTerms } = props;
  // export default function SearchResult() {
  const navigate = useNavigate();

  const handleNavigate = (e, path) => {
    e.stopPropagation();
    navigate(path);
  };

  //ページネーション管理
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  //メールテンプレート一覧取得※
  const [mailTemplates, setMailTemplates] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const getMailTemplates = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/template_mails`;
    const params = {
      params: {
        title: searchTerms["title"],
        content: searchTerms["content"],
        order: null,
        sort: null,
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("メールテンプレート一覧入力データ", params);
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.mail_templates;
        const totalCount = response.data.total_count;
        const status = response.data;
        console.log("メールテンプレート一覧取得", response);
        setMailTemplates(data);
        setTotalCount(totalCount);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  //初回起動時の読み込み回避
  const isFirstRender = useRef(false);

  useEffect(() => {
    // このeffectは初回レンダー時のみ呼ばれるeffect
    isFirstRender.current = true;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // 初回レンダー判定
      isFirstRender.current = false; // もう初回レンダーじゃないよ代入
    } else {
      getMailTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  // 削除処理
  const [deleteTemplateId, setDeleteTemplateId] = useState("");
  const [deletePopoverOpen, setDeletePopoverOpen] = useState(false);
  const deleteButtonClick = (id) => (e) => {
    e.stopPropagation();
    setDeleteTemplateId(id);
    setDeletePopoverOpen(true);
  };
  const deletePopupClose = (event) => {
    // event.stopPropagation();
    setDeletePopoverOpen(false);
  };

  //削除処理
  const deleteTemplateMail = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/template_mail/${deleteTemplateId}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    isSuccess
      ? axios
          .delete(apiUrl, headers)
          .then((response) => {
            const status = response.data;
            console.log("メールテンプレート削除", response);
            getMailTemplates();
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.error("API呼び出しエラー", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  const [selectedRow, setSelectedRow] = useState(null);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  // const [popoverData, setPopoverData] = useState(null);

  const handleLocationCodeClick = (row) => {
    setSelectedRow(row);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const buttonVaridate = (id) => {
    return id ? (
      <>
        <Box sx={{ display: "flex", gap: "8px", height: "36px" }}>
          <IconButton
            color="primary"
            onClick={(e) =>
              handleNavigate(e, `/mail/template/registation?id=${id}`)
            }
            sx={{ pointerEvents: "auto" }}
          >
            <ModeEditOutlineIcon />
          </IconButton>
          <IconButton
            variant="contained"
            color="error"
            // onClick={(e) => deleteButtonClick(e, id)}
            onClick={deleteButtonClick(id)}
            sx={{ pointerEvents: "auto" }}
          >
            <DeleteForeverOutlinedIcon />
          </IconButton>
        </Box>
      </>
    ) : null;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
          paddingTop: "16px",
          paddingBottom: "32px",
          "@media screen and (max-width:600px)": {
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <Button
          variant="contained"
          sx={{ width: "208px" }}
          onClick={getMailTemplates}
        >
          検索
        </Button>
        <Button
          variant="outlined"
          sx={{ display: "flex", width: "208px", gap: "8px" }}
          onClick={(e) => handleNavigate(e, "/mail/template/registation")}
        >
          追加
        </Button>
      </Box>
      <Divider />
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              paddingLeft: "40px",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              "@media screen and (max-width:900px)": {
                padding: "0",
              },
            }}
          >
            検索結果
          </Box>
        </Box>
        {/* <SearchResult /> */}
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      sx={{
                        padding: "8px",
                        fontWeight: "bold",
                        borderBottom: 2,
                        borderBottomColor: "outline.dark",
                        "@media screen and (max-width:900px)": {
                          display:
                            column.isSmallScreen === true
                              ? "table-cell"
                              : "none",
                        },
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {mailTemplates?.map((mailTemplate) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={mailTemplate.id}
                    onClick={() => handleLocationCodeClick(mailTemplate)}
                    sx={{
                      pointerEvents: "none",
                      "@media screen and (max-width:900px)": {
                        cursor: "pointer",
                        pointerEvents: "auto",
                      },
                    }}
                  >
                    {columns.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{
                            padding: "8px",
                            wordBreak: column.wordBreak,
                            "@media screen and (max-width:900px)": {
                              display:
                                column.isSmallScreen === true
                                  ? "table-cell"
                                  : "none",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "32px",
                              maxHeight: "100px",
                              overflow: "hidden",
                            }}
                          >
                            {
                              column.id === "update_at"
                                ? formatDate(mailTemplate[column.id]):
                                column.isUser
                                ? mailTemplate['create_user'][column.id] : mailTemplate[column.id]
                            }
                            {column.button
                              ? buttonVaridate(mailTemplate["id"])
                              : null}
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
            <Box
              sx={{
                display: "flex",
                height: "56px",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px",
                borderBottom: "2px solid #CCC",
              }}
            >
              <Box
                sx={{
                  paddingLeft: "8px",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                メールテンプレート詳細
              </Box>
              <IconButton
                aria-label="close"
                onClick={handlePopoverClose}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
              <TableBody>
                {selectedRow &&
                  columns.slice(0, 4).map((column) => {
                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                        <TableCell
                          align={column.align}
                          sx={{
                            color: "outline.dark",
                            fontWeight: "bold",
                            boxSizing: "border-box",
                            width: "calc((100vw - 64px) / 2)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              WebkitWritingMode: "horizontal-tb",
                              height: "100%",
                              padding: "8px",
                            }}
                          >
                            {column.label}
                          </Box>
                        </TableCell>
                        <TableCell
                          align={column.align}
                          sx={{
                            wordBreak: column.wordBreak,
                            writingMode: "horizontal-tb",
                            boxSizing: "border-box",
                            width: "calc((100vw - 64px) / 2)",
                            overflowWrap: "break-word",
                          }}
                        >
                          {column.id === "update_at"
                            ? formatDate(selectedRow[column.id])
                            : selectedRow[column.id]}
                          {column.button
                            ? buttonVaridate(selectedRow["id"])
                            : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <Box
              sx={{
                margin: "16px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {buttonVaridate(selectedRow?.["id"])}
            </Box>
          </Dialog>
          <PopoverDelete
            open={deletePopoverOpen}
            onClose={deletePopupClose}
            yesNo={deleteTemplateMail}
          />
          <SnackbarCustom
            data={snackBarstatus}
            openStatus={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
          />
          <TablePagination
            rowsPerPageOptions={[10, 30, 50, 100]}
            component="div"
            //totalDataがnull、undefined時に対応
            count={totalCount | 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions":
                {
                  margin: "0 !important",
                  padding: "0 !important",
                },
            }}
          />
        </Paper>
      </Box>
    </>
  );
};
export default SearchResult;
