import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import CardMedia from "@mui/material/CardMedia";
import Checkbox from "@mui/material/Checkbox";
import PopoverRegister from "../../../components/PopoverRegister";
import ImageIcon from "../../../assets/images/l_e_others_501.png";
import axios from "axios";
import { fetchSession } from "../../../auth";

export default function IconAddiction({ open, onClose, columns }) {
  const [icons, setIcons] = useState({
    alive: false,
    sales: false,
    sale_status: false,
    damage: false,
  });

  // クエリパラメーター取得
  const searchParams = new URLSearchParams(useLocation().search);
  const tenantId = searchParams.get("tenant_id");
  const tenantName = searchParams.get("name");

  const checkboxAction = (event) => {
    event.stopPropagation();
  };

  function CheckboxValidate(input, columnId) {
    const consoleCheck = () => {
      console.log("inputValue", input);
      console.log("icons", icons);
      console.log("selectedFile", selectedFile);
    };

    const handleChange = (e) => {
      const newValue = e.target.checked;
      setIcons((prevIcons) => ({
        ...prevIcons,
        [columnId]: newValue,
      }));
    };

    return (
      <>
        <Checkbox
          size="small"
          checked={input}
          onChange={handleChange}
          onClick={(event) => checkboxAction(event)}
          sx={{ cursor: "pointer", pointerEvents: "auto" }}
        />
        <Button onClick={consoleCheck}>コンソールチェック</Button>
      </>
    );
  }

  //登録処理
  const [registerPopoverOpen, setRegisterPopoverOpen] = useState(false);
  const registerButtonClick = () => {
    // event.stopPropagation();
    setRegisterPopoverOpen(true);
  };
  const registerPopupClose = (event) => {
    // event.stopPropagation();
    setRegisterPopoverOpen(false);
  };

  const postIconData = async(value) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/icon`;
    const params = new FormData();
      params.append('icon_files', selectedFile || null);
      params.append('name', tenantName)
      params.append('alive', (icons.alive));
      params.append('sales', (icons.sales));
      params.append('sale_status', (icons.sale_status));
      params.append('damage', (icons.damage));
      params.append('m_tenant_id', (parseInt(tenantId)));
      const headers = {
        headers: {
          Authorization: `Bearer ${tokenInfo.accessToken}`,
        },
      };
// axios.post(apiUrl, params)

    const fileName = `${params.m_tenant_id}_${params.alive}_${params.sales}_${
      params.sale_status
    }_${params.damage}.${selectedFile.name.split(".").pop()}`;
    value
      ? axios
          .post(apiUrl, params,headers)
          .then((response) => {
            console.log("アイコンを登録しました");
            console.log("レスポンスデータ:", response.data);
            console.log("入力データ", params);
          })
          .catch((error) => {
            if (
              error.response.status === 400 &&
              error.response.data.status === "ng"
            ) {
              console.log("ステータスの組み合わせが重複しています");
              console.log("エラーメッセージ:", error.response.data.message);
            } else {
              console.error("エラーが発生しました:", error);
            }
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event, file) => {
    event.stopPropagation();
    setSelectedFile(file);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose} fullWidth>
        <Box
          sx={{
            display: "flex",
            height: "56px",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
            borderBottom: "2px solid #CCC",
          }}
        >
          <Box
            sx={{
              paddingLeft: "8px",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            アイコン追加登録
          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
          <TableBody>
            {columns.slice(0, 5).map((column) => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                  <TableCell
                    sx={{
                      color: "outline.dark",
                      fontWeight: "bold",
                      boxSizing: "border-box",
                      width: "calc((100vw - 64px) / 2)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        WebkitWritingMode: "horizontal-tb",
                        height: "100%",
                        padding: "8px",
                      }}
                    >
                      {column.label}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      writingMode: "horizontal-tb",
                      boxSizing: "border-box",
                      width: "calc((100vw - 64px) / 2)",
                      overflowWrap: "break-word",
                      backgroundColor:
                        column.id === "icon" ? "outline.main" : "#FFF",
                    }}
                  >
                    {column.icon === true ? (
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                          }}
                        >
                          <CardMedia
                            component="img"
                            alt="Image"
                            image={
                              (selectedFile &&
                                URL.createObjectURL(selectedFile)) ||
                              ImageIcon
                            }
                            sx={{
                              height: "60px",
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                          <Button
                            size="small"
                            variant="contained"
                            component="label"
                          >
                            ファイル選択
                            <input
                              accept=".png, .svg,"
                              type="file"
                              style={{ display: "none" }}
                              onChange={(event) =>
                                handleFileChange(event, event.target.files[0])
                              }
                            />
                          </Button>
                        </Box>
                      </Box>
                    ) : null}
                    {column.checkBox === true
                      ? CheckboxValidate(icons[column.id], column.id)
                      : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", padding: "16px" }}
        >
          <Button variant="contained" onClick={() => registerButtonClick()}>
            登録
          </Button>
        </Box>
      </Dialog>
      <PopoverRegister
        open={registerPopoverOpen}
        onClose={registerPopupClose}
        yesNo={(value) => postIconData(value)}
      />
    </React.Fragment>
  );
}
