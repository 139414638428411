import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import { fetchSession } from "../auth";
import { formatDate } from "../utils/deteUtils";

const StyledCircularBackground = styled(CircularProgress)`
  color: #bfbfbf;
`;

const StyledCircularBar = styled(CircularProgress)`
  position: absolute;
`;

const typeVaridate = (type) =>
  type === 1
    ? "orange"
    : type === 2
    ? "red"
    : type === 3
    ? "green"
    : type === 4
    ? "blue"
    : "black";

export default function MaterList(props) {
  const { siteId } = props;
  // タンク情報取得
  const [latestStocks, setLatestStocks] = useState([]);
  const [latestStockState, setLatestStockState] = useState(null);
  const getStocksData = async () => {
    if (siteId !== undefined) {
      const tokenInfo = await fetchSession();
      const apiUrl = `${process.env.REACT_APP_BASE_URL}/latest-stocks`;
      const params = {
        params: {
          site_id: [siteId], //災害情報マップの方でサイト情報を取得後、取得したsite_idと置き換え
          order: null, // デフォルト値: 'code'
          sort: null, // デフォルト値: 'asc'
          page: 1,
          limit: null,
        },
        headers: {
          Authorization: `Bearer ${tokenInfo.accessToken}`,
        },
      };
      axios
        .get(apiUrl, params)
        .then((response) => {
          // レスポンスのデータを取得
          const data = response.data.latest_stocks;
          // テナント情報をステートにセット
          setLatestStocks(data);
          setLatestStockState(null);
        })
        .catch((error) => {
          console.error("API呼び出しエラー", error);
          setLatestStockState("表示権限がありません");
        });
    } else {
      setLatestStockState("SSが選択されていません");
    }
  };

  useEffect(() => {
    getStocksData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        {latestStockState == null ? latestStocks ? null : (
          <Box sx={{ fontWeight: 700 }}>{"POSデータが連携されていません"}</Box>
        ) : (
          <Box sx={{ fontWeight: 700 }}>{latestStockState}</Box>
        )}
        {}

        {latestStocks?.map((latestStock, index) => {
          return (
            <Box key={latestStock.id}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "32px",
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Box sx={{ fontSize: "32px", fontWeight: "bold" }}>
                    {latestStock.stock}
                  </Box>
                  <Box>
                    {latestStock.tank.oil_type === 1
                      ? "ハイオク"
                      : latestStock.tank.oil_type === 2
                      ? "レギュラー"
                      : latestStock.tank.oil_type === 3
                      ? "軽油"
                      : latestStock.tank.oil_type === 4
                      ? "灯油"
                      : "その他"}
                  </Box>
                </Box>
                <Box position="relative" display="inline-flex">
                  {/* 背景用のCircularProgress */}
                  <StyledCircularBackground
                    variant="determinate"
                    size={100}
                    value={100}
                    sx={{
                      color: "outline.light",
                      ".MuiCircularProgress-circle": {
                        strokeWidth: "4",
                        r: 20,
                      },
                    }}
                  />
                  {/* バロメーター用のCircularProgress */}
                  <StyledCircularBar
                    variant="determinate"
                    size={100}
                    value={(latestStock.stock / latestStock.tank.volume) * 100}
                    sx={{
                      color: typeVaridate(latestStock.tank.oil_type),
                      ".MuiCircularProgress-circle": {
                        strokeWidth: "4",
                        r: 20,
                      },
                    }}
                  />
                </Box>
              </Box>
              <Table sx={{ writingMode: "horizontal-tb" }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{
                        writingMode: "horizontal-tb",
                        color: "outline.dark",
                        fontWeight: "bold",
                        borderBottom: "2px solid outline.light",
                        padding: "8px",
                        width: "50%",
                      }}
                    >
                      タンクNo
                    </TableCell>
                    <TableCell
                      sx={{
                        writingMode: "horizontal-tb",
                        color: "outline.dark",
                        fontWeight: "regular",
                        borderBottom: "2px solid outline.light",
                        padding: "8px",
                        width: "50%",
                      }}
                    >
                      {latestStock.tank.no}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        writingMode: "horizontal-tb",
                        color: "outline.dark",
                        fontWeight: "bold",
                        borderBottom: "2px solid outline.light",
                        padding: "8px",
                        width: "50%",
                      }}
                    >
                      容量
                    </TableCell>
                    <TableCell
                      sx={{
                        writingMode: "horizontal-tb",
                        color: "outline.dark",
                        fontWeight: "regular",
                        borderBottom: "2px solid outline.light",
                        padding: "8px",
                        width: "50%",
                      }}
                    >
                      {latestStock.tank.volume}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        writingMode: "horizontal-tb",
                        color: "outline.dark",
                        fontWeight: "bold",
                        borderBottom: "2px solid outline.light",
                        padding: "8px",
                        width: "50%",
                      }}
                    >
                      出荷
                    </TableCell>
                    <TableCell
                      sx={{
                        writingMode: "horizontal-tb",
                        color: "outline.dark",
                        fontWeight: "regular",
                        borderBottom: "2px solid outline.light",
                        padding: "8px",
                        width: "50%",
                      }}
                    >
                      {latestStock.sale}L
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        writingMode: "horizontal-tb",
                        color: "outline.dark",
                        fontWeight: "bold",
                        borderBottom: "2px solid outline.light",
                        padding: "8px",
                        width: "50%",
                      }}
                    >
                      在庫日時
                    </TableCell>
                    <TableCell
                      sx={{
                        writingMode: "horizontal-tb",
                        color: "outline.dark",
                        fontWeight: "regular",
                        borderBottom: "2px solid outline.light",
                        padding: "8px",
                        width: "50%",
                      }}
                    >
                      {formatDate(latestStock.update_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
