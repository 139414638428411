import { TextField, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { fetchSession } from "../auth";

export default function PrefectureSelect(props) {
  const { branchId, customerId, changedValue } = props;
  const searchParams = new URLSearchParams(useLocation().search);
  const disasterId = searchParams.get("disaster-id");
  const [prefs, setPrefs] = useState([]);
  const getPref = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/division-code-prefs`;

    const params = {
      params: {
        disaster_id: disasterId,
        m_branch_id: branchId,
        m_customer_id: customerId,
        order: null,
        sort: null,
        page: 1,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.prefs;
        setPrefs(data)
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };
  useEffect(() => {
    getPref();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disasterId, branchId, customerId]);

  //第一引数にイベント情報、第二引数に選択された新しい値を受け取ることができる。
  const handleChange = (event, newValues) => {
    console.log("Selected value:", newValues);
    const prefValuesAray = newValues.map((newValue) => newValue.pref_code);
    changedValue(prefValuesAray);
  };

  return (
    <>
      <Autocomplete
        multiple
        limitTags={2}
        fullWidth
        size="small"
        options={prefs}
        getOptionLabel={(pref) => pref["pref_name"]}
        isOptionEqualToValue={(option, value) =>
          option["pref_code"] === value["pref_code"]
        }
        renderInput={(params) => (
          <TextField {...params} placeholder="複数選択可" />
        )}
        onChange={handleChange}
      />
    </>
  );
}
