import { TextField, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { fetchSession } from "../../../../../auth";

export default function DivisionSelect(props) {
  const { prefCode, changedValue } = props;
  console.log("prefCode, changedValue", prefCode, changedValue);
  const [divisions, setDivisions] = useState([]);
  const getPref = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/division-codes`;

    const params = {
      params: { pref_code: prefCode, order: null, sort: null, page: 1 },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("地区コード取得入力データ", params);
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.m_division_codes;
        console.log("地区コード情報取得", response);
        data
          ? setDivisions(data)
          : console.error("ヒットする地区コード情報なし");
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };
  useEffect(() => {
    getPref();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefCode]);

  //第一引数にイベント情報、第二引数に選択された新しい値を受け取ることができる。
  const handleChange = (event, newValues) => {
    changedValue(newValues?.code);
  };

  return (
    <>
      <Autocomplete
        limitTags={2}
        fullWidth
        size="small"
        options={divisions}
        getOptionLabel={(division) => division["full_name"]}
        isOptionEqualToValue={(option, value) =>
          option["code"] === value["code"]
        }
        renderInput={(params) => <TextField {...params} label="地域選択" />}
        onChange={handleChange}
      />
    </>
  );
}
