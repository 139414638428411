import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Dialog,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import SnackbarCustom from "../../../../components/SnackBarCustom";
import { fetchSession } from "../../../../auth";

const columns = [
  {
    id: "icon",
    label: "アイコン",
    minWidth: "40px",
    icon: true,
  },
  {
    id: "sales",
    label: "売上有無",
    minWidth: "40px",
  },
  {
    id: "sale_status",
    label: "営業状況",
    minWidth: "40px",
  },
  {
    id: "damage",
    label: "被害有無",
    minWidth: "40px",
  },
];

export default function MapIconTable() {
  //セッションストレージからテナントID呼び出し
  const sessionStorageTenantId = sessionStorage.getItem("tenant_id");
  const [selectedRow, setSelectedRow] = useState(null);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleLocationCodeClick = (row) => {
    setSelectedRow(row);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  // アイコン一覧取得
  const [icons, setIcons] = useState([]);

  const getIcons = async () => {
    const tokenInfo = await fetchSession();

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/icons`;
    const params = {
      params: { m_tenant_code: sessionStorageTenantId, page: null, limit: 30 },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.m_icons;
        const status = response.data;
        // テナント情報をステートにセット
        setIcons(data);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  useEffect(() => {
    getIcons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            writingMode: "vertical-lr",
            "@media screen and (max-width:900px)": {
              writingMode: "horizontal-tb",
            },
          }}
        >
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{
                    padding: "8px",
                    backgroundColor:
                      column.id === "icon" ? "outline.main" : "#FFF",
                    color: "outline.dark",
                    fontWeight: "bold",
                    borderBottom: 2,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {icons?.map((icon) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={icon.id}
                  onClick={() => handleLocationCodeClick(icon)}
                  sx={{
                    pointerEvents: "none",
                    "@media screen and (max-width:900px)": {
                      cursor: "pointer",
                      pointerEvents: "auto",
                    },
                  }}
                >
                  {columns.map((column) => {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{
                          padding: "8px",
                          textAlign: "center",
                          writingMode: "horizontal-tb",
                          backgroundColor:
                            column.id === "icon" ? "outline.main" : "#FFF",
                        }}
                      >
                        {column.icon ? (
                          <img
                            src={`https://ljs-dic-public-resources-dev.s3.ap-northeast-1.amazonaws.com/${icon.file_key}`}
                            alt="Example"
                            style={{ width: "56px", height: "auto" }}
                          />
                        ) : (
                          <>
                            {column.id === "sales" && icon[column.id] === 0
                              ? "なし※1" // 売上有無[0:無, 1:有, 9:設定しない]
                              : column.id === "sales" && icon[column.id] === 1
                              ? "あり※1"
                              : column.id === "sales" && icon[column.id] === 9
                              ? "不問※1"
                              : column.id === "sale_status" &&
                                icon[column.id] === 0
                              ? "休業※2" // 営業状況[0:休業, 1:営業, 2:未設定, 9:設定しない]
                              : column.id === "sale_status" &&
                                icon[column.id] === 1
                              ? "営業※2"
                              : column.id === "sale_status" &&
                                icon[column.id] === 2
                              ? "未設定※2"
                              : column.id === "sale_status" &&
                                icon[column.id] === 9
                              ? "不問※2"
                              : column.id === "damage" && icon[column.id] === 0
                              ? "なし※2" // 被害有無[0:無, 1:有, 2:未設定, 9:設定しない]
                              : column.id === "damage" && icon[column.id] === 1
                              ? "あり※2"
                              : column.id === "damage" && icon[column.id] === 2
                              ? "未設定※2"
                              : column.id === "damage" && icon[column.id] === 9
                              ? "不問※2"
                              : "-"}
                          </>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
        <Box
          sx={{
            display: "flex",
            height: "56px",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
            borderBottom: "2px solid #CCC",
          }}
        >
          <Box
            sx={{
              paddingLeft: "8px",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            マップアイコン詳細
          </Box>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
          <TableBody>
            {selectedRow &&
              columns.slice(0, 4).map((column) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                    <TableCell
                      align={column.align}
                      sx={{
                        color: "outline.dark",
                        fontWeight: "bold",
                        boxSizing: "border-box",
                        width: "calc((100vw - 64px) / 2)",
                        overflowWrap: "break-word",
                        backgroundColor:
                          column.id === "icon" ? "outline.main" : "#FFF",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          WebkitWritingMode: "horizontal-tb",
                          height: "100%",
                        }}
                      >
                        {column.label}
                      </Box>
                    </TableCell>
                    <TableCell
                      align={column.align}
                      sx={{
                        writingMode: "horizontal-tb",
                        boxSizing: "border-box",
                        width: "calc((100vw - 64px) / 2)",
                        overflowWrap: "break-word",
                        backgroundColor:
                          column.id === "icon" ? "outline.main" : "#FFF",
                      }}
                    >
                      {column.icon ? (
                        <img
                          src={`https://ljs-dic-public-resources-dev.s3.ap-northeast-1.amazonaws.com/${selectedRow.file_key}`}
                          alt="Example"
                          style={{ width: "56px", height: "auto" }}
                        />
                      ) : (
                        <>{selectedRow[column.id] === true ? "あり" : "なし"}</>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "8px",
        }}
      >
        <Box sx={{ color: "error.main", fontWeight: "bold" }}>
          ※1: システム自動判定 &nbsp;&nbsp;&nbsp;&nbsp; ※2: 害報告入力判定
        </Box>
      </Box>
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </Paper>
  );
}
