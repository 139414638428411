import { TextField, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { fetchSession } from "../../../auth";

const RoleSelect = (props) => {
  const { changedValue } = props;

  const [roles, setRoles] = useState([]);
  const getDisasters = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/roles`;

    const params = {
      params: {
        order: null, // デフォルト: complete_at
        sort: null, // デフォルト: desc, asc|desc
        page: 1,
        limit: null,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };

    console.log("権限取得入力データ", params);
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.m_roles;
        console.log("権限取得", response);
        data ? setRoles(data) : console.error("ヒットするSSなし");
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };
  useEffect(() => {
    getDisasters();
  }, []);

  const handleChange = (event, newValues) => {
    changedValue({ id: newValues?.id, name: newValues?.name });
  };

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={roles}
        getOptionLabel={(role) => role["name"]}
        isOptionEqualToValue={(option, value) => option["id"] === value["id"]}
        renderInput={(params) => <TextField {...params} label="権限選択" />}
        onChange={handleChange}
      />
    </>
  );
};
export default RoleSelect;
