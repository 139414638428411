import React from "react";
import { useState } from "react";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchResult from "./SearchResult";

export default function MailTemplate() {
  const [mailTemplates, setMailTemplates] = useState([]);

  //タイトル情報
  const handleChangeTitle = (e) => {
    const newValue = e.target.value;
    setMailTemplates((prevValue) => ({
      ...prevValue,
      title: newValue,
    }));
  };

  //内容情報
  const handleChangeContent = (e) => {
    const newValue = e.target.value;
    setMailTemplates((prevValue) => ({
      ...prevValue,
      content: newValue,
    }));
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          height: "48px",
          alignItems: "center",
          paddingLeft: 5,
          fontSize: 20,
          fontWeight: "bold",
          whiteSpace: "nowrap",
          "@media screen and (max-width:900px)": {
            paddingLeft: 2,
          },
        }}
      >
        メールテンプレート一覧
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            // paddingBottom: "32px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              paddingLeft: "40px",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              "@media screen and (max-width:900px)": {
                padding: "0",
              },
            }}
          >
            検索条件
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "16px",
              "@media screen and (max-width:600px)": {
                flexDirection: "column",
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
                maxWidth: "480px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box sx={{ minWidth: "160px" }}>件名</Box>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  onChange={(e) => handleChangeTitle(e)}
                  sx={{
                    width: "100%",
                    ".MuiInputBase-root": {
                      height: "100%",
                    },
                    "#outlined-basic": {
                      boxSizing: "border-box",
                      height: "100%",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box sx={{ minWidth: "160px" }}>本文 </Box>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  multiline
                  rows={3}
                  onChange={(e) => handleChangeContent(e)}
                  sx={{
                    height: "100%",
                    width: "100%",
                    ".MuiInputBase-root": {
                      height: "100%",
                    },
                    "#outlined-basic": {
                      boxSizing: "border-box",
                      height: "100%",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <SearchResult searchTerms={mailTemplates} />
      </Box>
    </React.Fragment>
  );
}
