import React, {useContext, useState} from "react";
import { List, ListItemButton, Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import MonitorIcon from "@mui/icons-material/Monitor";
import TelegramIcon from "@mui/icons-material/Telegram";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import ArticleIcon from "@mui/icons-material/Article";
import { styled } from "@mui/system";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import BusinessIcon from "@mui/icons-material/Business";
import {TokenContext} from "../TokenContext";
import {DashboardRole, MenuRole} from "../role";

const StyledListItemButton = styled(ListItemButton)({
  backgroundColor: "white", // 通常時の設定
  color: "#606060",
  fontSize: "20px",
  fontWeight: "600",
  height: "56px",
  display: "flex",
  alignItems: "center",
  gap: "16px",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#E6ECF1",
  },

  "& .icon": {
    color: "#0752a3",
  },

  "&.active": {
    backgroundColor: "#0752a3", // アクティブ時の背景色
    color: "white", // アクティブ時のテキスト色
    fontWeight: "600",
    "& .icon": {
      color: "white",
    },
    "&:hover": {
      backgroundColor: "#3874b5",
    },
    "&:active": {
      backgroundColor: "#0752a3",
    },
  },
});

function SideBar() {
  const { user } = useContext(TokenContext);
  const menuRole = MenuRole(user.m_organization.m_role_id)

  const [expanded1, setExpanded1] = useState(false);

  const closePulldown1 = () => {
    setExpanded1(false);
  };

  const toggleHeight1 = (event) => {
    // .preventDefaultでクリック時のページ遷移イベントをキャンセル
    event.preventDefault();
    setExpanded1(!expanded1);
  };

  const [expanded2, setExpanded2] = useState(false);

  const closePulldown2 = () => {
    setExpanded2(false);
  };

  const toggleHeight2 = (event) => {
    event.preventDefault();
    setExpanded2(!expanded2);
  };

  const closePulldown = () => {
    closePulldown1();
    closePulldown2();
    // closePulldown3();
  };

  const closePulldown1And2 = () => {
    closePulldown1();
    closePulldown2();
  };

  const closePulldown1And3 = () => {
    closePulldown1();
    // closePulldown3();
  };

  const closePulldown2And3 = () => {
    closePulldown2();
    // closePulldown3();
  };

  return (
    <List
      sx={{ width: "272px", p: 0, zIndex: 400, boxShadow: 3, height: "100%" }}
    >
      <StyledListItemButton
        component={NavLink}
        exact="true"
        to="/dashboard"
        onClick={() => {
          closePulldown();
        }}
        disabled={!menuRole.dashboard}
      >
        <HomeIcon />
        ダッシュボード
      </StyledListItemButton>

      <StyledListItemButton
        component={NavLink}
        exact="true"
        to="/sslog"
        onClick={closePulldown}
        disabled={!menuRole.sslog}
      >
        <HistoryEduIcon />
        SS被害履歴
      </StyledListItemButton>

      <StyledListItemButton
        component={NavLink}
        exact="true"
        to="/ssstate"
        onClick={closePulldown}
        disabled={!menuRole.ssstate}
      >
        <MonitorIcon />
        SS稼働状況
      </StyledListItemButton>

      <StyledListItemButton
        component={NavLink}
        exact="true"
        to="/ssinfo"
        onClick={closePulldown}
        disabled={!menuRole.ssinfo}
      >
        <ArticleIcon />
        SS基本情報一覧
      </StyledListItemButton>

      {/* <StyledListItemButton
        component={NavLink}
        exact="true"
        to="/sirstatus"
        onClick={closePulldown}
      >
        <AnalyticsIcon />
        SIR導入状況集計
      </StyledListItemButton>

      <StyledListItemButton
        component={NavLink}
        exact="true"
        to="/sircalender"
        onClick={closePulldown}
      >
        <CalendarMonthIcon />
        SIR用カレンダー
      </StyledListItemButton> */}
      <Box
        className="parentPulldown"
        sx={{
          overflow: "hidden",
          height: expanded1 ? "168px" : "56px",
          transition: "height 0.3s ease",
        }}
      >
        <StyledListItemButton
          component={NavLink}
          exact="true"
          to="/mail"
          onClick={toggleHeight1}
          disabled={!menuRole.mail}
          sx={{
            padding: "8px 16px",
            backgroundColor: expanded1 ? "primary.main" : "#FFF",
            color: expanded1 ? "#FFF" : "#606060",
            fontSize: "20px",
            fontWeight: "600",
            height: "56px",
            display: "flex",
            alignItems: "center",
            gap: "16px",
            whiteSpace: "nowrap",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: expanded1 ? "#3874b5" : "#E6ECF1",
            },
            ".dropUpIcon": {
              display: expanded1 ? "unset" : "none",
            },
            ".dropDownIcon": {
              display: expanded1 ? "none" : "unset",
            },
          }}
        >
          <TelegramIcon />
          メール設定
          <Box
            className="dropDownIcon"
            sx={{ right: -32, position: "relative" }}
          >
            <ArrowDropDownIcon />
          </Box>
          <Box className="dropUpIcon" sx={{ right: -32, position: "relative" }}>
            <ArrowDropUpIcon />
          </Box>
        </StyledListItemButton>
        <StyledListItemButton
          className="pulldown1"
          component={NavLink}
          exact="true"
          to="/mail/log"
          onClick={closePulldown2And3}
          disabled={!menuRole.mail_send}
        >
          メール送信
        </StyledListItemButton>
        <StyledListItemButton
          className="pulldown1"
          component={NavLink}
          exact="true"
          to="/mail/template"
          onClick={closePulldown2And3}
          disabled={!menuRole.mail_template}
        >
          テンプレート
        </StyledListItemButton>
      </Box>

      <Box
        className="parentPulldown"
        sx={{
          overflow: "hidden",
          height: expanded2 ? "168px" : "56px",
          transition: "height 0.3s ease",
        }}
      >
        <StyledListItemButton
          component={NavLink}
          exact="true"
          to="/account"
          onClick={toggleHeight2}
          disabled={!menuRole.account}
          sx={{
            padding: "8px 16px",
            backgroundColor: expanded2 ? "primary.main" : "#FFF",
            color: expanded2 ? "#FFF" : "#606060",
            fontSize: "20px",
            fontWeight: "600",
            height: "56px",
            display: "flex",
            alignItems: "center",
            gap: "16px",
            whiteSpace: "nowrap",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: expanded2 ? "#3874b5" : "#E6ECF1",
            },
            ".dropUpIcon": {
              display: expanded2 ? "unset" : "none",
            },
            ".dropDownIcon": {
              display: expanded2 ? "none" : "unset",
            },
          }}
        >
          <PersonIcon />
          アカウント
          <Box
            className="dropDownIcon"
            sx={{ right: -32, position: "relative" }}
          >
            <ArrowDropDownIcon />
          </Box>
          <Box className="dropUpIcon" sx={{ right: -32, position: "relative" }}>
            <ArrowDropUpIcon />
          </Box>
        </StyledListItemButton>
        <StyledListItemButton
          className="pulldown1"
          component={NavLink}
          exact="true"
          to="/account/list"
          onClick={closePulldown1And3}
          disabled={!menuRole.account}
        >
          アカウント一覧
        </StyledListItemButton>
        <StyledListItemButton
          className="pulldown1"
          component={NavLink}
          exact="true"
          to="/account/authority"
          onClick={closePulldown1And3}
          disabled={!menuRole.authority}
        >
          権限設定
        </StyledListItemButton>
      </Box>

      <StyledListItemButton
        component={NavLink}
        exact="true"
        to="/setting"
        onClick={closePulldown}
        disabled={!menuRole.setting}
      >
        <SettingsIcon />
        設定
      </StyledListItemButton>

      <StyledListItemButton
        className="pulldown1"
        component={NavLink}
        exact="true"
        to="/tenant"
        onClick={closePulldown1And2}
        disabled={!menuRole.tenant}
      >
        <BusinessIcon />
        テナント管理
      </StyledListItemButton>

      {/* <Box
        className="parentPulldown"
        sx={{
          overflow: "hidden",
          height: expanded3 ? "168px" : "56px",
          transition: "height 0.3s ease",
        }}
      >
        <StyledListItemButton
          component={NavLink}
          exact="true"
          to="/tenant"
          onClick={toggleHeight3}
          sx={{
            padding: "8px 16px",
            backgroundColor: expanded3 ? "primary.main" : "#FFF",
            color: expanded3 ? "#FFF" : "#606060",
            fontSize: "20px",
            fontWeight: "600",
            height: "56px",
            display: "flex",
            alignItems: "center",
            gap: "16px",
            whiteSpace: "nowrap",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: expanded3 ? "#3874b5" : "#E6ECF1",
            },
            ".dropUpIcon": {
              display: expanded3 ? "unset" : "none",
            },
            ".dropDownIcon": {
              display: expanded3 ? "none" : "unset",
            },
          }}
        >
          <BusinessIcon />
          テナント
          <Box
            className="dropDownIcon"
            sx={{ right: -32, position: "relative" }}
          >
            <ArrowDropDownIcon />
          </Box>
          <Box className="dropUpIcon" sx={{ right: -32, position: "relative" }}>
            <ArrowDropUpIcon />
          </Box>
        </StyledListItemButton>

        <StyledListItemButton
          className="pulldown1"
          component={NavLink}
          exact="true"
          to="/tenant/setting"
          onClick={closePulldown1And2}
        >
          テナント設定
        </StyledListItemButton>
      </Box> */}
    </List>
  );
}

export default SideBar;
