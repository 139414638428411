import { Box } from "@mui/material";
import { useState } from "react";
import React from "react";
// import Map from "./Map";
import Map from "./Map";
import MaterList from "../../components/MaterList";

export default function SsState() {
  const [siteId, setSiteId] = useState();
  const [operator, setOperator] = useState("SS店舗データ");

  const handleChange = (selectId) => {
    setSiteId(selectId);
    console.log("selectId", selectId);
  };

  const handleChangeOperator = (operator) => {
    setOperator(operator);
    console.log("operator", operator);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "48px",
          alignItems: "center",
          paddingLeft: "40px",
          fontSize: 20,
          fontWeight: "bold",
          whiteSpace: "nowrap",
          "@media screen and (max-width:900px)": {
            paddingLeft: 2,
          },
        }}
      >
        SS稼働状況
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "32px",
          "@media screen and (max-width:1200px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box
          className="child1"
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            borderRadius: 2,
            borderTop: 16,
            borderColor: "primary.main",
            boxShadow: 3,
            color: "outline.dark",
            backgroundColor: "#fff",
            flexGrow: 3,
          }}
        >
          <Box
            className="child2"
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              justifyContent: "center",
              padding: "16px 40px 32px 40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 20,
                fontWeight: "bold",
                color: "primary.main",
                whiteSpace: "nowrap",
              }}
            >
              マップ
            </Box>
          </Box>
          <Map
            selectId={(value) => handleChange(value)}
            selectOperator={(value) => handleChangeOperator(value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            borderRadius: 2,
            borderTop: 16,
            borderColor: "primary.main",
            boxShadow: 3,
            color: "outline.dark",
            backgroundColor: "#fff",
            // flexGrow: 1,
            minWidth: "352px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "16px",
              paddingRight: "40px",
              paddingLeft: "40px",
              paddingBottom: "32px",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // minWidth: "144px",
                fontSize: 20,
                fontWeight: "bold",
                color: "primary.main",
                wordWrap: "break-word",
                maxWidth: "216px",
                textAlign: "center",
                // padding: "16px 40px 32px",
                // width: "100%"
              }}
            >
              {operator}
            </Box>
            <MaterList siteId={siteId} flexType={"column"}/>
          </Box>
        </Box>
      </Box>
    </>
  );
}
