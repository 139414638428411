export const getCurrentDate = () => {
  const date = new Date();

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = "00"; // SSは常に"00"に設定

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};
