import React from 'react';
import { Typography, Box } from '@mui/material';

function Footer() {
  return (
  <Box sx={{ 
    width: '100%', 
    display: 'flex', 
    height:72, 
    alignItems:"center",
    background:"white",
    boxShadow: 3,
    }}>
    <Typography
    variant="h6" 
    sx={{
      color:"#0752A3",
      fontWeight:"bold",
      ml:9,
    }}>
      TAMADA inc @2021 
    </Typography>
  </Box>
  );
}

export default Footer;