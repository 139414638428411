import { Box } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ImportLog from "./ImportLog";

export default function MailAddressImport() {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // 前の画面に戻る
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          "@media screen and (max-width:900px)": {
            flexDirection: "column",
            gap: "-16px",
          },
        }}
      >
        <Box
          onClick={handleGoBack}
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            paddingLeft: "40px",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            color: "primary.main",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
              color: "primary.dark",
            },
            "&:active": {
              color: "primary.light",
            },
          }}
        >
          アカウント一覧
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            "@media screen and (max-width:900px)": {
              paddingLeft: "40px",
            },
          }}
        >
          /メールアドレスインポート
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              paddingLeft: "40px",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              "@media screen and (max-width:900px)": {
                padding: "0",
              },
            }}
          >
            検索条件
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  maxWidth: "480px",
                }}
              >
                <Button variant="contained" component="label">
                  ファイル選択
                  <input
                    accept=".xls, .xlsx,"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Button>
                <Button variant="outlined">
                  <DownloadIcon />
                  インポート
                </Button>
              </Box>
              {selectedFile ? (
                <Tooltip title={selectedFile.name}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      height: "36px",
                      width: "100%",
                      maxWidth: "480px",
                      border: "1px #D9D9D9 solid",
                      borderRadius: "4px ",
                    }}
                  >
                    <AttachFileIcon />
                    <Box
                      variant="body1"
                      sx={{
                        height: "32px",
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {selectedFile.name}
                    </Box>
                  </Box>
                </Tooltip>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxSizing: "border-box",
                    height: "36px",
                    width: "100%",
                    maxWidth: "480px",
                    border: "1px #D9D9D9 solid",
                    borderRadius: "4px ",
                  }}
                >
                  <AttachFileIcon />
                  {"ファイルを選択してください "}
                </Box>
              )}
            </Box>
            <Button variant="outlined">アカウント一覧</Button>
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              justifyContent: "center",
              gap: "16px",
              paddingTop: "32px",
              paddingRight: "40px",
              paddingLeft: "40px",
              paddingBottom: "32px",
              "@media screen and (max-width:900px)": {
                padding: "16px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "min-content",
                paddingLeft: "40px",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
                "@media screen and (max-width:900px)": {
                  padding: "0",
                },
              }}
            >
              インポート履歴
            </Box>
          </Box>
          <ImportLog />
        </Box>
      </Box>
    </>
  );
}
