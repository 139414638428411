import React, {useContext} from "react";
import { Box, CircularProgress } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "@aws-amplify/ui-react/styles.css";
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import Header from "./components/Header";
import SideBar from "./components/Sidebar";
import "./styles/styles.scss";
import { SnackbarProvider, MaterialDesignContent } from "notistack";
import { styled } from "@mui/system";
import {
  TaskAlt as TaskAltIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";
import CustomSignIn from "./CustomSignIn";
import AppInner from "./AppInner";
import {TokenContext} from "./TokenContext";

I18n.putVocabularies(translations);
I18n.setLanguage("ja");

const theme = createTheme({
  palette: {
    primary: {
      light: "#3874b5",
      main: "#0752A3",
      dark: "#043972",
      contrastText: "#fff",
      border: "#d9d9d9",
      text: "#606060",
    },
    secondary: {
      light: "#33bc90",
      main: "#00ac75",
      dark: "#007851",
      contrastText: "#fff",
    },
    outline: {
      light: "#F0F0F0",
      main: "#D9D9D9",
      dark: "#606060",
      contrastText: "#fff",
    },
  },
});

Amplify.configure(awsExports);

function App() {
  const { isLoading, authState, user, setUser, setAuthState, fetchUserData } = useContext(TokenContext);

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: "#edf7ed",
      color: "#1e4620",
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: "#fdeded",
      color: "#5f2120",
    },
  }));

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {authState && authState.user ? (
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={3}
              Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
              }}
              iconVariant={{
                success: (
                  <TaskAltIcon sx={{ paddingRight: "4px", color: "#2e7d32" }} />
                ),
                error: (
                  <ErrorOutlineIcon
                    sx={{ paddingRight: "4px", color: "#d32f2f" }}
                  />
                ),
              }}
            >
              <Header user={user} setUser={setUser} setAuthState={setAuthState} />
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "calc(100% - 72px)",
                }}
              >
                <Box
                  sx={{
                    position: "fixed",
                    marginTop: "72px",
                    left: 0,
                    width: "272px",
                    height: "calc(100% - 72px)",
                    backgroundColor: "#fff",
                    "@media screen and (max-width:1200px)": {
                      display: "none",
                    },
                  }}
                >
                  <SideBar />
                </Box>
                <AppInner
                  user={user}
                  authState={authState?.state}
                />
              </Box>
            </SnackbarProvider>
          </ThemeProvider>
        </BrowserRouter>
      ) : (
        <CustomSignIn
          onSignIn={(user) => fetchUserData()}
        />
      )}
    </>
  );
}

export default App;
