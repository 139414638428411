import { TextField, Autocomplete } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { fetchSession } from "../../../../../auth";

export default function JoinDisasterSelect(props) {
  //クエリパラメータ取得
  const searchParams = new URLSearchParams(useLocation().search);
  const disasterId = searchParams.get("disaster-id");

  const { changedValue } = props;

  const [disasters, setDisasters] = useState([]);
  const getDisasters = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disasters`;

    const params = {
      params: {
        name: null, // 災害名称があいまい検索
        is_completed: false, // true: 完了した災害情報, false: 未完了の災害
        exclude_id: disasterId, // disasters.idの配列
        start_at_stt: null, // 開始日が指定日以降
        start_at_end: null, // 開始日が指定日以前
        order: null, // デフォルト: complete_at
        sort: null, // デフォルト: desc, asc|desc
        page: 1,
        limit: null,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.disasters;
        data ? setDisasters(data) : console.error("ヒットするSSなし");
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };
  useEffect(() => {
    getDisasters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValues) => {
    changedValue({ id: newValues?.id, name: newValues?.name });
  };

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={disasters}
        getOptionLabel={(disaster) => disaster["name"]}
        isOptionEqualToValue={(option, value) => option["id"] === value["id"]}
        renderInput={(params) => (
          <TextField {...params} label="結合先災害選択" />
        )}
        onChange={handleChange}
      />
    </>
  );
}
