import { TextField, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";

const saleStatus = [
  {
    sale_status: 0,
    name: "休業中",
  },
  {
    sale_status: 1,
    name: "営業中",
  },
  {
    sale_status: 2,
    name: "未設定",
  },
  {
    sale_status: 9,
    name: "設定しない",
  },
];

export default function SaleStatusSelect(props) {
  const { changedValue, inputValue } = props;

  const initialValue = saleStatus.find(
    (element) => element.sale_status === parseInt(inputValue)
  );
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (inputValue) {
      const newValue = saleStatus.find(
        (sale_status) => sale_status.sale_status === inputValue
      );
      setValue(newValue);
    }
  }, [inputValue]);

  const handleChange = (event, newValues) => {
    setValue(newValues);
    changedValue(newValues?.sale_status);
  };

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={saleStatus}
        getOptionLabel={(option) => (option.name ? option.name : "")}
        isOptionEqualToValue={(option, value) =>
          option.sale_status === value.sale_status
        }
        renderInput={(params) => <TextField {...params} />}
        onChange={handleChange}
        value={value} // 初期値を設定
        sx={{ cursor: "pointer", pointerEvents: "auto" }}
      />
    </>
  );
}
