import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

export default function PopoverRegister(props) {
  const { open, onClose, yesNo } = props;

  const handleClickYes = () => {
    yesNo(true);
    onClose();
  };

  const handleClickNo = () => {
    yesNo(false);
    onClose();
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            height: "56px",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
            borderBottom: "2px solid #CCC",
          }}
        >
          <Box
            sx={{
              paddingLeft: "8px",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            登録確認
          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ padding: "16px", fontWeight: "bold" }}>
          登録してもよろしいですか？
        </Box>
        <Box
          sx={{
            margin: "16px",
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            sx={{ fontWeight: "bold", width: "80px" }}
            onClick={handleClickNo}
          >
            いいえ
          </Button>
          <Button
            variant="contained"
            sx={{ fontWeight: "bold", width: "80px" }}
            onClick={handleClickYes}
          >
            はい
          </Button>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
