import * as React from "react";
import { useState } from "react";
import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileUploadIcon from "@mui/icons-material/FileUpload";

export default function IconSetting(props) {
  const { input, column, changedValue } = props;
  // console.log("input, column, changedValue",input, column, changedValue)
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  //アップロードファイル管理
  const [selectedFile, setSelectedFile] = useState(null);
  //表示画像管理
  const [selectedImage, setSelectedImage] = useState(null);

  const handleLocationCodeClick = (event, row) => {
    event.stopPropagation();
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = (event) => {
    event.stopPropagation();
    setIsPopoverOpen(false);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleFileChange = (event, file) => {
    event.stopPropagation();
    setSelectedFile(file);
  };

  const handleClickUpload = (e) => {
    console.log("selectedFile", selectedFile);
    if (typeof changedValue === "function") {
      setSelectedImage(selectedFile);
      changedValue(selectedFile);
      //   const valueToPass = selectedFile || imageUrl;
      // setSelectedImage(selectedFile);
      // changedValue(valueToPass);
      // setSelectedData(valueToPass)
      console.log("登録したよ", selectedFile);
    }
    handlePopoverClose(e);
  };
  // console.log("column", column);
  // console.log("filekey", input[column]);
  const imageUrl = `https://ljs-dic-public-resources-dev.s3.ap-northeast-1.amazonaws.com/${input[column]}`;
  // console.log("imageUrl",imageUrl)
  const consoleCheck = () => {
    console.log("changedValue", changedValue);
  };
  return (
    <Box onClick={(event) => stopPropagation(event)}>
      {/* {console.log("input", input)}
      {console.log("column", column)} */}

      <CardMedia
        component="img"
        alt="No Image"
        //アップロードされたイメージを表示
        image={
          (selectedImage && URL.createObjectURL(selectedImage)) || imageUrl
        }
        onClick={(event) => handleLocationCodeClick(event)}
        sx={{
          height: "60px",
          width: "100%",
          objectFit: "contain",
          cursor: "pointer",
          pointerEvents: "auto",
        }}
      />
      <Dialog
        open={isPopoverOpen}
        onClose={(event) => handlePopoverClose(event)}
      >
        <Box
          sx={{
            display: "flex",
            height: "56px",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
            borderBottom: "2px solid #CCC",
          }}
        >
          <Box
            sx={{
              paddingLeft: "8px",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            アイコンアップロード
          </Box>
          <IconButton
            aria-label="close"
            onClick={handlePopoverClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {process.env.NODE_ENV === "development" && (
          <>
            <Box>file_key {input.file_key}</Box>
            <Box>input {column}</Box>
            <Box>
              <Button onClick={consoleCheck}>コンソール確認</Button>
            </Box>
          </>
        )}
        <Box sx={{ height: "fit-content", padding: "16px" }}>
          <Box sx={{ height: "fit-content" }}>
            <CardMedia
              component="img"
              alt="Image"
              //選択されたイメージを表示
              image={
                (selectedFile && URL.createObjectURL(selectedFile)) || imageUrl
              }
              onClick={(event) => handleLocationCodeClick(event)}
              sx={{
                height: "72px",
                width: "100%",
                padding: "8px",
                objectFit: "contain",
                backgroundColor: "outline.main",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: "16px",
              gap: "4px",
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                maxWidth: "480px",
              }}
            >
              <Button variant="contained" component="label">
                ファイル選択
                <input
                  accept=".png, .svg,"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(event) =>
                    handleFileChange(event, event.target.files[0])
                  }
                />
              </Button>
              <Button variant="outlined" onClick={(e) => handleClickUpload(e)}>
                <FileUploadIcon />
                アップロード
              </Button>
            </Box>
            {selectedFile ? (
              <Tooltip title={selectedFile.name}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxSizing: "border-box",
                    height: "36px",
                    width: "100%",
                    maxWidth: "480px",
                    border: "1px #D9D9D9 solid",
                    borderRadius: "4px ",
                  }}
                >
                  <AttachFileIcon />
                  <Box
                    variant="body1"
                    sx={{
                      height: "32px",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {selectedFile.name}
                  </Box>
                </Box>
              </Tooltip>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  boxSizing: "border-box",
                  height: "36px",
                  width: "100%",
                  maxWidth: "480px",
                  border: "1px #D9D9D9 solid",
                  borderRadius: "4px ",
                }}
              >
                <AttachFileIcon />
                {"ファイルを選択してください "}
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}
