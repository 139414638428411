import React, {useContext} from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import { Checkbox } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CloseIcon from "@mui/icons-material/Close";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import JoinDisasterSelect from "./JoinDisasterSelect";
import SnackbarCustom from "../../../../components/SnackBarCustom";
import PopoverCustom from "../../../../components/PopoverCustom";
import { fetchSession } from "../../../../auth";
import {TokenContext} from "../../../../TokenContext";
import {ManualCreateDisasterRole} from "../../../../role";
import OtherDisasterPopup from "../../OtherDisasterPopup";
const columns = [
  {
    id: "CheckBox",
    label: "",
    minWidth: "64px",
    CheckBox: true,
    isSmallScreen: true,
    checkBox: true,
  },
  {
    id: "display_code",
    label: "Sコード",
    minWidth: "80px",
    isSmallScreen: true,
    changeColor: true,
    site: true,
  },
  {
    id: "name",
    label: "SS名称",
    minWidth: "112px",
    isSmallScreen: true,
    changeColor: true,
    site: true,
  },
  {
    id: "name",
    label: "得意先",
    minWidth: "96px",
    isSmallScreen: false,
    m_customer: true,
  },
  {
    id: "operator",
    label: "運営店",
    minWidth: "112px",
    isSmallScreen: false,
    site: true,
  },
  {
    id: "address",
    label: "住所",
    minWidth: "144px",
    isSmallScreen: false,
    site: true,
  },
  {
    id: "holiday",
    label: "休業日",
    minWidth: "96px",
    isSmallScreen: false,
    site: true,
  },
  // {
  //   id: "latest_alive",
  //   label: "POS死活",
  //   minWidth: "96px",
  //   isSmallScreen: false,
  //   site: true,
  // },
  {
    id: "latest_sales",
    label: "売上有無",
    minWidth: "80px",
    existence: true,
    isSmallScreen: false,
  },
  {
    id: "damage_at",
    label: "被害報告入力有無",
    minWidth: "96px",
    existence: true,
    isSmallScreen: false,
  },
  {
    id: "is_damage",
    label: "被害有無",
    minWidth: "96px",
    existence: true,
    isSmallScreen: false,
  },
  {
    id: "description",
    label: "検知理由",
    minWidth: "80px",
    disaster_detail: true,
    isSmallScreen: false,
  },
  {
    id: "detect_at",
    label: "検知日時",
    minWidth: "80px",
    isSmallScreen: false,
  },
  {
    id: "reportButton",
    label: "",
    minWidth: "112px",
    button: true,
    align: "right",
    isSmallScreen: true,
    position: "sticky",
    right: 0,
    backgroundColor: "#fff",
    zIndex: 1,
  },
];

const textFieldValidate = (input) =>
  input ? <TextField size="small" /> : null;

const existenceValidate = (disasterStatus, column) => {
  let status = false;
  if (column == 'is_damage') {
    status = disasterStatus['personal_damage'] | disasterStatus['property_damage'] | disasterStatus['facility_damage'];
  } else {
    status = (disasterStatus[column] === null) ? false : true;
  }
  return status ? (
    <Box>
      <PanoramaFishEyeIcon />
    </Box>
  ) : (
    <Box>
      <CloseIcon />
    </Box>
  );
}


const SearchResult = (props) => {
  const { user } = useContext(TokenContext);
  const manualCreateDisasterRole = ManualCreateDisasterRole(user.m_organization.m_role_id)
  //クエリパラメータ取得
  const searchParams = new URLSearchParams(useLocation().search);
  const disasterId = searchParams.get("disaster-id");

  const { searchContent } = props;

  //検索結果ページネーション
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // 災害対象SS一覧取得
  const [disasterStatuses, setDisasterStatuses] = useState([]);
  const [totalData, setTotalData] = useState();

  const navigate = useNavigate();
//復旧報告画面へ遷移
  const handleClick = (event, path) => {
    event.stopPropagation();
    navigate(path,{state: {previousPage: "sslist"}});
  };

  const getDisasterSsData = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster-statuses/${searchContent["display_id"]}`;
    const params = {
      params: {
        display_code: searchContent["display_code"],
        name: null,
        // pref: searchContent["pref_code"],
        pref: searchContent["pref"],
        m_branch_id: searchContent["m_branch_id"], // 例: 都道府県コードを指定 (複数指定可能)
        m_customer_id: searchContent["m_customer_id"], // 例: 得意先IDを指定 (複数指定可能)
        latest_alive: searchContent["latest_alive"],
        latest_sales: searchContent["latest_sales"],
        latest_authori: searchContent["latest_authori"],
        is_input: searchContent["is_input"],
        is_damage: searchContent["is_damage"],
        order: null, // デフォルト値: 'code'
        sort: null,
        page: page + 1,
        limit: rowsPerPage
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.disaster_statuses;
        const total = response.data.total_count;
        const status = response.data;
        // テナント情報をステートにセット
        setDisasterStatuses(data);
        setTotalData(total);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);　
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const ButtonVaridate = (props) => {
    const { button, statusId, otherDisasterStatuses } = props;
    return button ? (
      <Box sx={{}}>
        <Button
          variant="contained"
          onClick={(event) =>
            handleClick(
              event,
              `/dashboard/situationcheck/report?disaster-id=${disasterId}&status-id=${statusId}`
            )
          }
          sx={{ pointerEvents: "auto" }}
        >
          被害状況報告
        </Button>
        <OtherDisasterPopup otherDisasterStatuses={otherDisasterStatuses} />
      </Box>
    ) : null;
  };

  //初回起動時の読み込み回避
  const isFirstRender = useRef(false);

  useEffect(() => {
    // このeffectは初回レンダー時のみ呼ばれるeffect
    isFirstRender.current = true;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // 初回レンダー判定
      isFirstRender.current = false; // もう初回レンダーじゃないよ代入
    }
    getDisasterSsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  const [selectedRow, setSelectedRow] = useState(null);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  //チェックボックスのチェック管理
  const [checked, setChecked] = useState([]);
  const [checkedName, setCheckedName] = useState([]);

  const CheckboxValidate = (props) => {
    const { siteId, siteName } = props;
    //checkedにsiteIdが存在する場合、isCheckedがtrue
    const isChecked = checked.includes(siteId);
    const handleChange = (e) => {
      if (e.target.checked === true) {
        setChecked((prevChecked) => {
          if (!prevChecked.includes(siteId)) {
            return [...prevChecked, siteId];
          }
          return prevChecked;
        });
        setCheckedName((prevChecked) => {
          if (!prevChecked.includes(siteName)) {
            return [...prevChecked, siteName];
          }
          return prevChecked;
        });
      } else {
        // チェックが外された場合、siteId を checked 配列から削除
        setChecked((prevChecked) =>
          prevChecked.filter((checkedId) => checkedId !== siteId)
        );
        setCheckedName((prevChecked) =>
          prevChecked.filter((checkedId) => checkedId !== siteName)
        );
      }
    };

    return (
      <Checkbox
        size="small"
        checked={isChecked}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleChange(e)}
        sx={{ cursor: "pointer", pointerEvents: "auto" }}
      />
    );
  };

  const handleLocationCodeClick = (row) => {
    setSelectedRow(row);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  //検索結果ページネーション
  const handleChangePage = (event, newPage) => {
    console.log("newPage", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //選択した災害名称管理
  const [DisasterName, setDisasterName] = useState();
  const handleChangeDisaster = (e) => {
    setSelectedContents((prev) => ({
      ...prev,
      dst_id: e.id,
    }));
    setDisasterName(e.name);
  };

  //結合確認コメント
  var joinMessage = (
    <div>
      {checkedName.map((name, index) => (
        <span key={index}>
          ・{name}
          <br />
        </span>
      ))}
      <span>
        を "{DisasterName}"
        <br />
        に結合しますか？
      </span>
    </div>
  );
  //分割確認コメント
  var splitMessage = (
    <div>
      {checkedName.map((name, index) => (
        <span key={index}>
          ・{name}
          <br />
        </span>
      ))}
      を分割しますか？
    </div>
  );
  //削除確認コメント
  var deleteMessage = (
    <div>
      {checkedName.map((name, index) => (
        <span key={index}>
          ・{name}
          <br />
        </span>
      ))}
      を削除しますか？
    </div>
  );

  //災害情報結合
  const [popoverJoinOpen, setPopoverJoinOpen] = useState(false);
  const joinButtonClick = () => {
    setPopoverJoinOpen(true);
  };
  const joinPopupClose = (event) => {
    setPopoverJoinOpen(false);
  };
  const [selectedContents, setSelectedContents] = useState({
    dst_id: null,
  });
  const joinDisasters = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    //チェック済みSSを追加
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster/${disasterId}/join`;
    const params = {
      site_id: checked,
      dst_id: selectedContents["dst_id"],
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("災害情報結合入力データ", params);
    isSuccess
      ? axios
        // .put(apiUrl, condition)
          .put(apiUrl, params, headers)
          .then((response) => {
            const status = response.data;
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            console.log("災害情報結合:", response.data);
            //結合後選択済みサイトをリセット
            setChecked([]);
            setCheckedName([]);
          })
          .catch((error) => {
            console.error("エラーが発生しました:", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  //災害情報分割
  const [popoverSplitOpen, setPopoverSplitOpen] = useState(false);
  const splitButtonClick = () => {
    setPopoverSplitOpen(true);
  };
  const splitPopupClose = (event) => {
    setPopoverSplitOpen(false);
  };
  const splitDisasters = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster/${disasterId}/split`;
    const params = {
      site_id: checked,
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("災害情報分割入力データ", params);
    isSuccess
      ? axios
          .post(apiUrl, params, headers)
          .then((response) => {
            const status = response.data;
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            console.log("災害情報分割:", response.data);
            //結合後選択済みサイトをリセット
            setChecked([]);
            setCheckedName([]);
          })
          .catch((error) => {
            console.error("エラーが発生しました:", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  //災害情報削除
  const [popoverDeleteOpen, setPopoverDeleteOpen] = useState(false);
  const deleteButtonClick = () => {
    setPopoverDeleteOpen(true);
  };
  const deletePopupClose = (event) => {
    setPopoverDeleteOpen(false);
  };

  const deleteDisasters = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    //チェック済みSSを追加
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster/${disasterId}`;
    const params = {
      params: { site_id: checked },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("災害情報分割入力データ", params);
    isSuccess
      ? axios
          .delete(apiUrl, params)
          .then((response) => {
            const status = response.data;
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            console.log("災害情報分割:", response.data);
            //結合後選択済みサイトをリセット
            setChecked([]);
            setCheckedName([]);
          })
          .catch((error) => {
            console.error("エラーが発生しました:", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
          padding: "16px 0 32px",
          "@media screen and (max-width:600px)": {
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <Button
          variant="contained"
          onClick={getDisasterSsData}
          sx={{ width: "208px" }}
        >
          検索
        </Button>
      </Box>
      <Divider />
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            gap: "16px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              paddingLeft: "40px",
              "@media screen and (max-width:900px)": {
                padding: "0",
              },
            }}
          >
            検索結果
          </Box>
          {/* <Button onClick={checkConsole}>コンソール確認</Button> */}
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ width: "100%" }}
            >
              <TableHead>
                <TableRow sx={{ overflowX: "auto" }}>
                  {columns.map((column) => (
                    <TableCell
                      key={`${column.label}_${column.id}`}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        position: column.position,
                        right: column.right,
                      }}
                      sx={{
                        padding: "8px",
                        "@media screen and (max-width:900px)": {
                          display:
                            column.isSmallScreen === true
                              ? "table-cell"
                              : "none",
                        },
                        backgroundColor: column.changeColor
                          ? "primary.light"
                          : "#FFF",
                        color: column.changeColor ? "#FFF" : "outline.dark",
                        fontWeight: "bold",
                        borderBottom: "2px solid #606060",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {disasterStatuses?.map((disasterStatus, rowNum) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`${disasterStatus.id}_${rowNum}`}
                      onClick={() => handleLocationCodeClick(disasterStatus)}
                      sx={{
                        pointerEvents: "none",
                        "@media screen and (max-width:900px)": {
                          cursor: "pointer",
                          pointerEvents: "auto",
                        },
                      }}
                    >
                      {columns.map((column) => {
                        return (
                          <TableCell
                            key={`${column.label}_${column.id}`}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              position: column.position,
                              right: column.right,
                              backgroundColor: column.backgroundColor,
                            }}
                            sx={{
                              padding: "8px",
                              "@media screen and (max-width:900px)": {
                                display:
                                  column.isSmallScreen === true
                                    ? "table-cell"
                                    : "none",
                              },
                            }}
                          >
                            {column.site ? (
                              <>{disasterStatus["site"][column.id]}</>
                            ) : column.m_branch ? (
                              <>
                                {disasterStatus["site"]["m_branch"][column.id]}
                              </>
                            ) : column.m_customer ? (
                              <>
                                {
                                  disasterStatus["site"]["m_customer"][column.id]
                                }
                              </>
                            ) : column.m_division ? (
                              <>
                                {
                                  disasterStatus["site"]["m_division"][column.id]
                                }
                              </>
                            ) : column.disaster_detail ? (
                              <>
                                {disasterStatus["disaster_detail"][column.id]}
                              </>
                            ) : column.existence ? (
                              <>
                                {existenceValidate(disasterStatus, column.id)}
                              </>
                            ) : (
                              <>{disasterStatus?.[column.id]}</>
                            )}
                            {column.checkBox ? (
                              <CheckboxValidate
                                siteId={disasterStatus.site.id}
                                siteName={disasterStatus.site.name}
                              />
                            ) : null}
                            {textFieldValidate(column.textField)}
                            <ButtonVaridate
                              button={column.button}
                              statusId={disasterStatus["id"]}
                              otherDisasterStatuses={disasterStatus['other_disaster_statuses']}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {manualCreateDisasterRole.update ? (
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                padding: "8px",
              }}
            >
              <JoinDisasterSelect
                changedValue={(value) => handleChangeDisaster(value)}
                ownId={disasterId}
              />
              <Button
                variant="contained"
                onClick={joinButtonClick}
                disabled={
                  checked.length === 0 || selectedContents.dst_id === null
                }
              >
                結合
              </Button>
              <Button
                variant="outlined"
                onClick={splitButtonClick}
                disabled={checked.length === 0}
              >
                分割
              </Button>
              <IconButton
                color="error"
                onClick={deleteButtonClick}
                disabled={checked.length === 0}
              >
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </Box>
          ) : null }
          <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
            <Box
              sx={{
                display: "flex",
                height: "56px",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px",
                borderBottom: "2px solid #CCC",
              }}
            >
              <Box
                sx={{
                  paddingLeft: "8px",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                対象SS詳細
              </Box>
              <IconButton
                aria-label="close"
                onClick={handlePopoverClose}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
              <TableBody>
                {selectedRow &&
                  columns.slice(1, 10).map((column) => {
                    // const value = selectedRow[column.id];
                    return (
                      <TableRow role="checkbox" tabIndex={-1}>
                        <TableCell
                          align={column.align}
                          sx={{
                            color: "outline.dark",
                            fontWeight: "bold",
                            boxSizing: "border-box",
                            width: "calc((100vw - 64px) / 2)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              WebkitWritingMode: "horizontal-tb",
                              height: "100%",
                            }}
                          >
                            {column.label}
                          </Box>
                        </TableCell>
                        <TableCell
                          align={column.align}
                          sx={{
                            writingMode: "horizontal-tb",
                            boxSizing: "border-box",
                            width: "calc((100% - 64px) / 2)",
                            overflowWrap: "break-word",
                          }}
                        >
                          {column.site ? (
                            <>{selectedRow["site"][column.id]}</>
                          ) : column.m_branch ? (
                            <>{selectedRow["site"]["m_branch"][column.id]}</>
                          ) : column.m_customer ? (
                            <>{selectedRow["site"]["m_customer"][column.id]}</>
                          ) : column.m_division ? (
                            <>{selectedRow["site"]["m_division"][column.id]}</>
                          ) : column.existence ? (
                            <>
                              {existenceValidate(selectedRow, column.id)}
                            </>
                          ) : (
                            <>{selectedRow?.[column.id]}</>
                          )}

                          {column.checkBox ? (
                            <CheckboxValidate
                              siteId={selectedRow.site.id}
                              siteName={selectedRow.site.name}
                            />
                          ) : null}
                          {textFieldValidate(column.textField)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <Box
              sx={{
                margin: "16px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  padding: "0 8px",
                  height: "36.5px",
                  border: "solid 1px #0752A3",
                  borderRadius: "4px",
                  fontWeight: "500",
                  fontSize: "0.875rem",
                  lineHeight: "1.75",
                  letterSpacing: "0.02857",
                  color: "#0752A3",
                }}
              >
                選択
                <CheckboxValidate
                  siteId={selectedRow?.site.id}
                  siteName={selectedRow?.site.name}
                />
              </Box>
              <ButtonVaridate button={true} statusId={selectedRow?.["id"]} />
            </Box>
          </Dialog>
          <PopoverCustom
            open={popoverJoinOpen}
            onClose={joinPopupClose}
            yesNo={joinDisasters}
            title={"結合確認"}
            contents={joinMessage}
          />
          <PopoverCustom
            open={popoverSplitOpen}
            onClose={splitPopupClose}
            yesNo={splitDisasters}
            title={"分割確認"}
            contents={splitMessage}
          />
          <PopoverCustom
            open={popoverDeleteOpen}
            onClose={deletePopupClose}
            yesNo={deleteDisasters}
            title={"削除確認"}
            contents={deleteMessage}
          />
          <SnackbarCustom
            data={snackBarstatus}
            openStatus={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
          />
          <TablePagination
            rowsPerPageOptions={[10, 30, 50, 100]}
            component="div"
            count={totalData | 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions":
                {
                  margin: "0 !important",
                  padding: "0 !important",
                },
            }}
          />
        </Paper>
      </Box>
    </>
  );
};
export default SearchResult;
