import { TextField, Autocomplete } from "@mui/material";
import {useState, useEffect, useContext} from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { fetchSession } from "../auth";
import {TokenContext} from "../TokenContext";
import { IsAgent } from "../role";

export default function CustomerSelect(props) {
  const { user } = useContext(TokenContext);
  const isAgent = IsAgent(user.m_organization.m_role_id)
  const { prefCode, branchId, changedValue } = props;
  //クエリパラメータ取得
  const searchParams = new URLSearchParams(useLocation().search);
  const disasterId = searchParams.get("disaster-id");
  const [customers, setCustomers] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const getCustomers = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/customers`;
    const params = {
      params: {
        disaster_id: disasterId,
        pref_code: prefCode,
        m_branch_id: branchId,
        order: null,
        sort: null,
        page: 1,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.m_customers;
        setCustomers(data);
        if (isAgent) {
          // default preset value
          const ownCustomers = user.customer_users.map((customers) => customers.m_customer);
          // targetのオブジェクトがoptionsに存在するかをチェックし、存在するもののみを抽出
          const existData = ownCustomers.filter(target =>
            data.some(option => option.id === target.id)
          );
          setSelectedOption(existData);
          if (existData.length > 0) {
            changedValue(existData.map((value) => value.id));
          }
          setDisabled(true);
        }
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };
  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disasterId, prefCode, branchId]);

  const handleChange = (event, newValues) => {
    setSelectedOption(newValues);
    changedValue(newValues.map((newValue) => newValue.id));
  };

  return (
    <>
      <Autocomplete
        multiple
        limitTags={2}
        fullWidth
        size="small"
        options={customers}
        getOptionLabel={(customer) => customer["name"]}
        isOptionEqualToValue={(option, value) => option["id"] === value["id"]}
        renderInput={(params) => (
          <TextField {...params} placeholder="複数選択可" />
        )}
        disabled={isDisabled} // 全体を無効化
        value={selectedOption} // プリセットの初期値をここで設定
        onChange={handleChange}
      />
    </>
  );
}
