// 特約店判定
export const IsAgent = (roleId) => {
  return roleId === 7;
}

export const MenuRole = (roleId) => {
  switch (roleId) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 6:
    case 99:
      return {
        "dashboard": true,
        "sslog": true,
        "ssstate": true,
        "ssinfo": true,
        "mail": true,
        "mail_send": true,
        "mail_template": true,
        "account": true,
        "authority": true,
        "setting": true,
        "tenant": false,
      };
    case 5:
    case 7:
    case 8:
      return {
        "dashboard": true,
        "sslog": true,
        "ssstate": true,
        "ssinfo": true,
        "mail": false,
        "mail_send": false,
        "mail_template": false,
        "account": false,
        "authority": false,
        "setting": false,
        "tenant": false,
      };
    default:
      return {
        "dashboard": false,
        "sslog": false,
        "ssstate": false,
        "ssinfo": false,
        "mail": false,
        "mail_send": false,
        "mail_template": false,
        "account": false,
        "authority": false,
        "setting": false,
        "tenant": false,
      };
  }
}

// ダッシュボード操作権限
export const DashboardRole = (roleId) => {
  switch (roleId) {
    case 1:
      return {"create": false, "read": true, "update": true, "delete": false};
    case 2:
      return {"create": false, "read": true, "update": true, "delete": false};
    case 3:
      return {"create": false, "read": true, "update": true, "delete": false};
    case 4:
      return {"create": false, "read": true, "update": true, "delete": false};
    case 5:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 6:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 7:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 8:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 99:
      return {"create": false, "read": true, "update": true, "delete": false};
    default:
      return {"create": false, "read": false, "update": false, "delete": false};
  }
};

// 被害報告Excel操作権限
export const DisasterExcelRole = (roleId) => {
  switch (roleId) {
    case 1:
      return {"create": false, "read": true, "update": true, "delete": false};
    case 2:
      return {"create": false, "read": true, "update": true, "delete": false};
    case 3:
      return {"create": false, "read": true, "update": true, "delete": false};
    case 4:
      return {"create": false, "read": true, "update": true, "delete": false};
    case 5:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 6:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 7:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 8:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 99:
      return {"create": false, "read": true, "update": true, "delete": false};
    default:
      return {"create": false, "read": false, "update": false, "delete": false};
  }
};

// 災害操作権限
export const DisasterRole = (roleId) => {
  switch (roleId) {
    case 1:
      return {"create": false, "read": true, "update": true, "delete": false};
    case 2:
      return {"create": false, "read": true, "update": true, "delete": false};
    case 3:
      return {"create": false, "read": true, "update": true, "delete": false};
    case 4:
      return {"create": false, "read": true, "update": true, "delete": false};
    case 5:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 6:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 7:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 8:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 99:
      return {"create": false, "read": true, "update": true, "delete": false};
    default:
      return {"create": false, "read": false, "update": false, "delete": false};
  }
};

// 災害の手動操作権限
export const ManualCreateDisasterRole = (roleId) => {
  switch (roleId) {
    case 1:
      return {"create": true, "read": true, "update": true, "delete": false};
    case 2:
      return {"create": true, "read": true, "update": true, "delete": false};
    case 3:
      return {"create": true, "read": true, "update": true, "delete": false};
    case 4:
      return {"create": true, "read": true, "update": true, "delete": false};
    case 5:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 6:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 7:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 8:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 99:
      return {"create": true, "read": true, "update": true, "delete": false};
    default:
      return {"create": false, "read": false, "update": false, "delete": false};
  }
};

// 集計表操作権限
export const TotalizationRole = (roleId) => {
  switch (roleId) {
    case 1:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 2:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 3:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 4:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 5:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 6:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 7:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 8:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 99:
      return {"create": false, "read": true, "update": false, "delete": false};
    default:
      return {"create": false, "read": false, "update": false, "delete": false};
  }
};

// SS基本台帳インポート操作権限
export const SSMasterImportRole = (roleId) => {
  switch (roleId) {
    case 1:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 2:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 3:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 4:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 5:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 6:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 7:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 8:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 99:
      return {"create": true, "read": true, "update": true, "delete": true};
    default:
      return {"create": false, "read": false, "update": false, "delete": false};
  }
};

// メール送信操作権限
export const MailSendRole = (roleId) => {
  switch (roleId) {
    case 1:
      return {"create": true, "read": true, "update": true, "delete": true};
    case 2:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 3:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 4:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 5:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 6:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 7:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 8:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 99:
      return {"create": true, "read": true, "update": true, "delete": true};
    default:
      return {"create": false, "read": false, "update": false, "delete": false};
  }
};

// メールテンプレート操作権限
export const MailTemplateRole = (roleId) => {
  switch (roleId) {
    case 1:
      return {"create": true, "read": true, "update": true, "delete": true};
    case 2:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 3:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 4:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 5:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 6:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 7:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 8:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 99:
      return {"create": true, "read": true, "update": true, "delete": true};
    default:
      return {"create": false, "read": false, "update": false, "delete": false};
  }
};

// ユーザー情報操作権限
export const UserRole = (roleId) => {
  switch (roleId) {
    case 1:
      return {"create": true, "read": true, "update": true, "delete": true};
    case 2:
      return {"create": false, "read": true, "update": true, "delete": false};
    case 3:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 4:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 5:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 6:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 7:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 8:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 99:
      return {"create": true, "read": true, "update": true, "delete": true};
    default:
      return {"create": false, "read": false, "update": false, "delete": false};
  }
};

// ユーザー情報インポート操作権限
export const UserImportRole = (roleId) => {
  switch (roleId) {
    case 1:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 2:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 3:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 4:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 5:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 6:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 7:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 8:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 99:
      return {"create": true, "read": true, "update": true, "delete": true};
    default:
      return {"create": false, "read": false, "update": false, "delete": false};
  }
};

// 災害判定条件操作権限
export const DisasterConditionRole = (roleId) => {
  switch (roleId) {
    case 1:
      return {"create": true, "read": true, "update": true, "delete": true};
    case 2:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 3:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 4:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 5:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 6:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 7:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 8:
      return {"create": false, "read": true, "update": false, "delete": false};
    case 99:
      return {"create": true, "read": true, "update": true, "delete": true};
    default:
      return {"create": false, "read": false, "update": false, "delete": false};
  }
};

// テナント操作権限
export const TenantRole = (roleId) => {
  switch (roleId) {
    case 1:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 2:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 3:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 4:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 5:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 6:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 7:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 8:
      return {"create": false, "read": false, "update": false, "delete": false};
    case 99:
      return {"create": true, "read": true, "update": true, "delete": true};
    default:
      return {"create": false, "read": false, "update": false, "delete": false};
  }
};