import { TextField, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { fetchSession } from "../auth";

export default function BranchSelect(props) {
  const { prefCode, customerId, changedValue } = props;
  const searchParams = new URLSearchParams(useLocation().search);
  const disasterId = searchParams.get("disaster-id");

  const [branches, setBranches] = useState([]);
  const getBranches = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/branches`;

    const params = {
      params: {
        disaster_id: disasterId,
        pref_code: prefCode,
        m_customer_id: customerId,
        order: null,
        sort: null,
        page: 1,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.m_branches;
        setBranches(data)
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };
  useEffect(() => {
    getBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disasterId, prefCode, customerId]);

  const handleChange = (event, newValues) => {
    const prefValuesAray = newValues.map((newValue) => newValue.id);
    changedValue(prefValuesAray);
  };

  return (
    <>
      <Autocomplete
        multiple
        limitTags={2}
        fullWidth
        size="small"
        options={branches}
        getOptionLabel={(branch) => branch["name"]}
        isOptionEqualToValue={(option, value) => option["id"] === value["id"]}
        renderInput={(params) => (
          <TextField {...params} placeholder="複数選択可" />
        )}
        onChange={handleChange}
      />
    </>
  );
}
