import { TextField, Autocomplete } from "@mui/material";

const reasonsClosing = [
  {
    key: 1,
    value: "停電",
  },
  {
    key: 2,
    value: "浸水・冠水",
  },
  {
    key: 3,
    value: "設備被害",
  },
  {
    key: 4,
    value: "人員被害",
  },
  {
    key: 5,
    value: "油切れ",
  },
  {
    key: 6,
    value: "計画休業",
  },
];

export default function CloseReasonSelect(props) {
  const { reasonsClosingStatus, changedValue } = props;

  //第一引数にイベント情報、第二引数に選択された新しい値を受け取ることができる。
  const handleChange = (event, newValues) => {
    changedValue(newValues ? newValues.key : null);
  };
  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={reasonsClosing}
        getOptionLabel={(sale) => sale["value"]}
        isOptionEqualToValue={(option, value) => option["key"] === value["key"]}
        value={
          reasonsClosing.find(
            (status) => status.key === reasonsClosingStatus
          ) || null
        }
        renderInput={(params) => (
          <TextField {...params} placeholder="休止要因選択" />
        )}
        onChange={handleChange}
      />
    </>
  );
}
