import React, {useContext, useState} from "react";
import { Auth } from "aws-amplify";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  CssBaseline,
  LinearProgress,
  Alert,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {TokenContext} from "./TokenContext";

const CustomSignIn = ({ onSignIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signInUser, setSignInUser] = useState(null);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentView, setCurrentView] = useState("signIn");
  const { isLoading, authState, user, setUser, setAuthState } = useContext(TokenContext);

  const handleSignIn = async (event) => {
    setLoading(true);
    setError("");
    try {
      event.preventDefault();
      const user = await Auth.signIn(email, password);
      setSignInUser(user);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        // 仮パスワードの場合は、ユーザーに新しいパスワードを入力させる画面へ遷移
        console.log(
          "NEW_PASSWORD_REQUIRED: 新しいパスワードを入力してください。"
        );
        setCurrentView("setNewPassword");
      } else if (user.authenticationFlowType === "CUSTOM_AUTH") {
        // 通常のサインイン
        setCurrentView("confirmSignIn");
      } else {
        onSignIn(user);
      }
    } catch (error) {
      console.error("Error signing in", error);
      setError("サインインに失敗しました。再試行してください。");
    } finally {
      setLoading(false);
    }
  };

  const handleCompleteNewPassword = async (event) => {
    setLoading(true);
    setError("");
    try {
      event.preventDefault();

      if (newPassword !== confirmPassword) {
        setError("パスワードが一致しません。");
        return;
      }

      // 新しいパスワードを設定
      const updatedUser = await Auth.completeNewPassword(
        signInUser,
        newPassword
      );
      console.log(`新しいパスワードが設定されました: ${updatedUser}`);

      setCurrentView("signIn"); // パスワードを設定後、サインイン画面へ戻る
    } catch (error) {
      console.error("Error completing new password", error);
      setError("新しいパスワードの設定に失敗しました。再試行してください。");
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    setError("");
    try {
      // メールアドレスが空の場合はエラーメッセージを表示
      if (!email) {
        setError("メールアドレスを入力してください");
        setLoading(false);
        return;
      }

      // await Auth.forgotPassword(email);
      // setForgotPassword(true);
      setCurrentView("resetPassword");
      // console.log(`認証コードが ${email} 宛に送信されました。`);
    } catch (error) {
      console.error("Error initiating forgot password", error);
      if (error.code === "LimitExceededException") {
        setError(
          "操作が制限されました。しばらく時間を置いて再試行してください。"
        );
      } else {
        setError("メールアドレスを入力してください");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmSignInForNewPassword = async (event) => {
    setLoading(true);
    setError("");
    try {
      event.preventDefault();

      if (!code) {
        setError("コードが入力されていません");
        return;
      }

      // 認証コードが正しいことを確認後、パスワード変更画面に遷移
      await Auth.confirmSignUp(email, code);
      console.log("認証コード確認成功。パスワード変更画面へ遷移。");
      setCurrentView("setNewPassword");
    } catch (error) {
      console.error("Error confirming sign in", error);
      if (error.code === "ExpiredCodeException") {
        setError("認証コードが異なります");
      } else {
        setError("確認コードの送信に失敗しました。再試行してください。");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmSignIn = async (event) => {
    setLoading(true);
    setError("");
    try {
      event.preventDefault();
      await Auth.sendCustomChallengeAnswer(signInUser, code);
      const user = await Auth.currentAuthenticatedUser();
      onSignIn(user);
    } catch (error) {
      console.error("Error confirming sign in", error);
      if (error.code === "ExpiredCodeException") {
        setError("認証コードが異なります");
      } else {
        setError("確認コードの送信に失敗しました。再試行してください。");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSendResetCode = async (event) => {
    setLoading(true);
    setError("");
    try {
      event.preventDefault();
      await Auth.forgotPassword(email);
      setCurrentView("setPassword");
      console.log(`認証コードが ${email} 宛に送信されました。`);
    } catch (error) {
      console.error("Error sending reset code", error);
      if (error.code === "LimitExceededException") {
        setError(
          "操作が制限されました。しばらく時間を置いて再試行してください。"
        );
      } else {
        setError("コードの送信に失敗しました。再試行してください。");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSetPassword = async (event) => {
    setLoading(true);
    setError("");
    try {
      event.preventDefault();

      if (!code) {
        setError("コードが入力されていません");
        return;
      }

      if (newPassword !== confirmPassword) {
        setError("パスワードが一致しません。");
        return;
      }

      await Auth.forgotPasswordSubmit(email, code, newPassword);
      setCurrentView("signIn");
      console.log(`パスワードが ${email} でリセットされました。`);
    } catch (error) {
      console.error("Error setting new password", error);
      if (error.code === "ExpiredCodeException") {
        setError("認証コードが異なります");
      } else {
        setError("パスワードの設定に失敗しました。再試行してください。");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    setLoading(true);
    setError("");
    try {
      await Auth.forgotPassword(email);
      console.log(`再送コードが ${email} 宛に送信されました。`);
    } catch (error) {
      console.error("Error resending code", error);
      if (error.code === "LimitExceededException") {
        setError(
          "操作が制限されました。しばらく時間を置いて再試行してください。"
        );
      } else {
        setError("コードの再送信に失敗しました。再試行してください。");
      }
    } finally {
      setLoading(false);
    }
  };

  const theme = createTheme();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleBackToSignIn = () => {
    setCurrentView("signIn");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div>
      {currentView === "signIn" && (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                TAMOS災害対応システム
              </Typography>
              <Box component="form" onSubmit={handleSignIn} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={handleEmailChange}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={handlePasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {error && <Alert severity="error">{error}</Alert>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  サインイン
                </Button>
                <Button
                  onClick={handleForgotPassword}
                  fullWidth
                  sx={{ mt: 1, mb: 2 }}
                >
                  パスワード再発行
                </Button>
                {loading && <LinearProgress />}
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      )}

      {currentView === "confirmSignInForNewPassword" && (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Confirm code
              </Typography>
              <Box
                component="form"
                onSubmit={handleConfirmSignInForNewPassword}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="confirm-code"
                  label="Confirm code"
                  name="confirm-code"
                  autoComplete="code"
                  autoFocus
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                {error && <Alert severity="error">{error}</Alert>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading}
                  sx={{ mt: 3, mb: 2 }}
                >
                  SEND
                </Button>
                {loading && <LinearProgress />}
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      )}

      {currentView === "setNewPassword" && (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                新しいパスワードを設定
              </Typography>
              <Box
                component="form"
                onSubmit={handleCompleteNewPassword}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="new-password"
                  label="新しいパスワード"
                  type={showNewPassword ? "text" : "password"}
                  id="new-password"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirm-password"
                  label="新しいパスワード（確認）"
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {error && <Alert severity="error">{error}</Alert>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  パスワードを設定
                </Button>
                {loading && <LinearProgress />}
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      )}

      {currentView === "confirmSignIn" && (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Confirm code
              </Typography>
              <Box
                component="form"
                onSubmit={handleConfirmSignIn}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="confirm-code"
                  label="Confirm code"
                  name="confirm-code"
                  autoComplete="code"
                  autoFocus
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                {error && <Alert severity="error">{error}</Alert>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading}
                  sx={{ mt: 3, mb: 2 }}
                >
                  SEND
                </Button>
                {loading && <LinearProgress />}
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      )}

      {currentView === "resetPassword" && (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                パスワードをリセット
              </Typography>
              <Box
                component="form"
                onSubmit={handleSendResetCode}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  disabled
                />
                {error && <Alert severity="error">{error}</Alert>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  コードを送信
                </Button>
                <Button
                  onClick={handleBackToSignIn}
                  fullWidth
                  sx={{ mt: 1, mb: 2 }}
                >
                  サインインに戻る
                </Button>
                {loading && <LinearProgress />}
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      )}

      {currentView === "setPassword" && (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                パスワードを設定
              </Typography>
              <Box component="form" onSubmit={handleSetPassword} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="confirm-code"
                  label="Confirm code"
                  name="confirm-code"
                  autoComplete="code"
                  autoFocus
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="new-password"
                  label="新しいパスワード"
                  type={showNewPassword ? "text" : "password"}
                  id="new-password"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirm-password"
                  label="新しいパスワード（確認）"
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {error && <Alert severity="error">{error}</Alert>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  送信
                </Button>
                <Button
                  onClick={handleResendCode}
                  fullWidth
                  sx={{ mt: 1, mb: 2 }}
                >
                  コードを再送信
                </Button>
                {loading && <LinearProgress />}
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      )}
    </div>
  );
};

export default CustomSignIn;
