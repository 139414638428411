import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

export default function MenuPopup(props) {
  const { disasterId } = props;
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        // onClick={(event) => { handleClick(); event.stopPropagation(); }}
        sx={{ pointerEvents: "auto" }}
        endIcon={<KeyboardArrowDownIcon />}
      >
        操作選択
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() =>
            handleNavigate(`/dashboard/disastermap?disaster-id=${disasterId}`)
          }
          value="click_map"
          sx={{ fontWeight: "bold" }}
        >
          マップ表示
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleNavigate(`/dashboard/sslist?disaster-id=${disasterId}`)
          }
          value="click_map"
          sx={{ fontWeight: "bold" }}
        >
          対象SS一覧
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleNavigate(
              `/dashboard/situationcheck?disaster-id=${disasterId}`
            )
          }
          value="click_map"
          sx={{ fontWeight: "bold" }}
        >
          被害状況確認
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleNavigate(`/dashboard/totalization?disaster-id=${disasterId}`)
          }
          value="click_map"
          sx={{ fontWeight: "bold" }}
        >
          集計表
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
