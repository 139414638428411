import { TextField, Autocomplete } from "@mui/material";

const WaterTestStatus = [
  {
    key: 1,
    value: "実施済み",
  },
  {
    key: 2,
    value: "業者依頼中",
  },
  {
    key: 0,
    value: "未実施",
  },
];

export default function WaterTestStatusSelect(props) {
  const { waterTestStatus, changedValue } = props;

  //第一引数にイベント情報、第二引数に選択された新しい値を受け取ることができる。
  const handleChange = (event, newValues) => {
    changedValue(newValues ? newValues.key : null);
  };

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={WaterTestStatus}
        getOptionLabel={(sale) => sale["value"]}
        isOptionEqualToValue={(option, value) => option["key"] === value["key"]}
        value={
          WaterTestStatus.find((status) => status.key === waterTestStatus) ||
          null
        }
        renderInput={(params) => <TextField {...params} />}
        onChange={handleChange}
      />
    </>
  );
}
