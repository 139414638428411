import { TextField, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";

const earthquakeScale = [
  {
    scale: "0",
    name: "0",
  },
  {
    scale: "1",
    name: "1",
  },
  {
    scale: "2",
    name: "2",
  },
  {
    scale: "3",
    name: "3",
  },
  {
    scale: "4",
    name: "4",
  },
  {
    scale: "5-",
    name: "5弱",
  },
  {
    scale: "5+",
    name: "5強",
  },
  {
    scale: "6-",
    name: "6弱",
  },
  {
    scale: "6+",
    name: "6強",
  },
  {
    scale: "7",
    name: "7",
  },
];

export default function EarthquakeScale(props) {
  const { changedValue, inputValue, disabled } = props;

  const [value, setValue] = useState(inputValue);

  useEffect(() => {
    if (inputValue) {
      const newValue = earthquakeScale.find(
        (scale) => scale.scale === inputValue
      );
      setValue(newValue);
    }
  }, [inputValue]);

  const handleChange = (event, newValues) => {
    setValue(newValues);
    changedValue(newValues?.scale);
  };

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={earthquakeScale}
        getOptionLabel={(pref) => pref["name"]}
        isOptionEqualToValue={(option, value) =>
          option["scale"] === value["scale"]
        }
        renderInput={(params) => <TextField {...params} label="震度選択" />}
        onChange={handleChange}
        value={value} // 初期値を設定
        disabled={disabled}
      />
    </>
  );
}
