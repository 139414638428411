import { TextField, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { fetchSession } from "../../../../auth";

const TemplateSelect = (props) => {
  const { changedValue, value } = props;
  const [mailTemplates, setMailTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const getTemplates = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/template_mails`;

    const params = {
      params: {
        title: null,
        content: null,
        order: null,
        sort: null,
        page: 1,
        limit: null,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };

    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.mail_templates;
        if (data) {
          setMailTemplates(data);

          // 初期のvalueに対応するテンプレートを設定
          const initialTemplate = data.find(
            (template) => template.id === value
          );
          setSelectedTemplate(initialTemplate || null);
        } else {
          console.error("ヒットするテンプレートなし");
        }
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  // コンポーネントマウント時にテンプレートリストを取得
  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // 初回のみ実行

  // value または mailTemplates が変更されたときに選択されたテンプレートを更新
  useEffect(() => {
    if (value && mailTemplates.length > 0) {
      const initialTemplate = mailTemplates.find(
        (template) => template.id === value
      );
      setSelectedTemplate(initialTemplate || null);
    }
  }, [value, mailTemplates]); // value または mailTemplates が変更されたら実行

  const handleChange = (event, newValues) => {
    setSelectedTemplate(newValues);
    changedValue({
      id: newValues?.id,
      name: newValues?.name,
      content: newValues?.content, // contentを追加
      title: newValues?.title, // titleを追加
    });
  };

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={mailTemplates}
        value={selectedTemplate}
        getOptionLabel={(template) => template["title"]}
        isOptionEqualToValue={(option, value) => option["id"] === value["id"]}
        renderInput={(params) => (
          <TextField {...params} label="テンプレート選択" />
        )}
        onChange={handleChange}
      />
    </>
  );
};

export default TemplateSelect;
