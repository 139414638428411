import React from "react";
import { Box, TextField, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import PrefectureSelect from "../../../../components/PrefectureSelect";
import CustomerSelect from "../../../../components/CustomerSelect";
import BranchSelect from "../../../../components/BranchSelect";
import SearchResult from "./SearchResult";

export default function SearchArea() {
  //クエリパラメータ取得
  const searchParams = new URLSearchParams(useLocation().search);
  const disasterId = searchParams.get("disaster-id");

  // 検索条件
  const [searchContents, setSearchContents] = useState({
    display_id: disasterId,
    display_code: null,
    name: null,
    pref: null,
    m_branch_id: null, // 例: 都道府県コードを指定 (複数指定可能)
    m_customer_id: null, // 例: 得意先IDを指定 (複数指定可能)
    latest_sales: null,
    is_input: null,
    is_damage: null,
  });

  //ロケーションコード設定
  const handleChangeLocathion = (e) => {
    setSearchContents((prev) => ({
      ...prev,
      display_code: e.target.value,
    }));
  };

  //都道府県情報設定
  const handleChangePref = (e) => {
    setSearchContents((prev) => ({
      ...prev,
      pref: e,
    }));
  };

  //支店情報設定
  const handleChangeBranch = (e) => {
    setSearchContents((prev) => ({
      ...prev,
      m_branch_id: e,
    }));
  };

  //得意先情報設定
  const handleChangeCustomer = (e) => {
    setSearchContents((prev) => ({
      ...prev,
      m_customer_id: e,
    }));
  };

  // 被害報告入力有無
  const handleChangeIsInput = (e) => {
    if (searchContents.is_input === e) {
      // チェックされているボックスが再度クリックされた場合、チェックを外す
      setSearchContents((prev) => ({
        ...prev,
        is_input: null,
      }));
    } else {
      // チェックされたボックスに対応する値を設定
      setSearchContents((prev) => ({
        ...prev,
        is_input: e,
      }));
    }
  };

  // 被害有無
  const handleChangeIsDamage = (e) => {
    if (searchContents.is_damage === e) {
      // チェックされているボックスが再度クリックされた場合、チェックを外す
      setSearchContents((prev) => ({
        ...prev,
        is_damage: null,
      }));
    } else {
      // チェックされたボックスに対応する値を設定
      setSearchContents((prev) => ({
        ...prev,
        is_damage: e,
      }));
    }
  };

  //直近売上設定
  const handleChangeSales = (e) => {
    if (searchContents.latest_sales === e) {
      // チェックされているボックスが再度クリックされた場合、チェックを外す
      setSearchContents((prev) => ({
        ...prev,
        latest_sales: null,
      }));
    } else {
      // チェックされたボックスに対応する値を設定
      setSearchContents((prev) => ({
        ...prev,
        latest_sales: e,
      }));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        justifyContent: "center",
        borderRadius: 2,
        borderColor: "primary.main",
        boxShadow: 3,
        color: "outline.dark",
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          gap: "16px",
          paddingTop: "32px",
          paddingRight: "40px",
          paddingLeft: "40px",
          "@media screen and (max-width:900px)": {
            padding: "16px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            minWidth: "min-content",
            paddingLeft: "40px",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            "@media screen and (max-width:900px)": {
              padding: "0",
            },
          }}
        >
          検索条件
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "16px",
            "@media screen and (max-width:600px)": {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
              maxWidth: "480px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
                "@media screen and (max-width:900px)": {
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                },
              }}
            >
              <Box sx={{ minWidth: "160px" }}>Sコード</Box>
              <Box sx={{ display: "flex", width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px",
                    height: "40px",
                    border: "1px solid #CCC",
                    borderRight: "none",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                >
                  S
                </Box>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  onChange={(e) => handleChangeLocathion(e)}
                  sx={{
                    height: "40px",
                    width: "100%",
                    ".MuiInputBase-root": {
                      height: "100%",
                    },
                    "#outlined-basic": {
                      boxSizing: "border-box",
                      height: "100%",
                    },
                    ".MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "0 4px 4px 0",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
                "@media screen and (max-width:900px)": {
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                },
              }}
            >
              <Box sx={{ minWidth: "160px" }}>都道府県</Box>
              <PrefectureSelect
                branchId={searchContents["m_branch_id"]}
                customerId={searchContents["m_customer_id"]}
                changedValue={(value) => handleChangePref(value)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
                "@media screen and (max-width:900px)": {
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                },
              }}
            >
              <Box sx={{ minWidth: "160px" }}>特約店</Box>
              <CustomerSelect
                prefCode={searchContents["pref"]}
                branchId={searchContents["m_branch_id"]}
                changedValue={(value) => handleChangeCustomer(value)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
                "@media screen and (max-width:900px)": {
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                },
              }}
            >
              <Box sx={{ minWidth: "160px" }}>支店</Box>
              <BranchSelect
                prefCode={searchContents["pref"]}
                customerId={searchContents["m_customer_id"]}
                changedValue={(value) => handleChangeBranch(value)}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
              maxWidth: "480px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
                "@media screen and (max-width:900px)": {
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                },
              }}
            >
              <Box sx={{ minWidth: "160px" }}>被害報告入力有無</Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    size="small"
                    checked={searchContents.is_input === true}
                    onChange={() => handleChangeIsInput(true)}
                  />
                  <PanoramaFishEyeIcon />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    size="small"
                    checked={searchContents.is_input === false}
                    onChange={() => handleChangeIsInput(false)}
                  />
                  <CloseIcon />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
                "@media screen and (max-width:900px)": {
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                },
              }}
            >
              <Box sx={{ minWidth: "160px" }}>被害有無</Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    size="small"
                    checked={searchContents.is_damage === true}
                    onChange={() => handleChangeIsDamage(true)}
                  />
                  <PanoramaFishEyeIcon />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    size="small"
                    checked={searchContents.is_damage === false}
                    onChange={() => handleChangeIsDamage(false)}
                  />
                  <CloseIcon />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
                "@media screen and (max-width:900px)": {
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                },
              }}
            >
              <Box sx={{ minWidth: "160px" }}>直近売上</Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    size="small"
                    checked={searchContents.latest_sales === true}
                    onChange={() => handleChangeSales(true)}
                  />
                  <PanoramaFishEyeIcon />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    size="small"
                    checked={searchContents.latest_sales === false}
                    onChange={() => handleChangeSales(false)}
                  />
                  <CloseIcon />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <SearchResult searchContent={searchContents} />
    </Box>
  );
}
