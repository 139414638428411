import React from "react";
import { Box, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SnackbarCustom from "../../../../components/SnackBarCustom";
import { fetchSession } from "../../../../auth";

export default function AccountEdit() {
  const navigateback = useNavigate();
  const handleGoBack = () => {
    navigateback(-1); // 前の画面に戻る
  };
  //リクエストパラメータ取得
  const searchParams = new URLSearchParams(useLocation().search);
  const userId = searchParams.get("user-id");

  //ユーザー情報取得※エラー修正待ち
  const [user, setUser] = useState([]);
  const getUser = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/user/${userId}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, headers)
      .then((response) => {
        const data = response.data.user;
        const status = response.data;
        setUser(data);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //パスワード
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChangePass = (e) => {
    const newValue = e.target.value;
    setUser((prevValue) => ({
      ...prevValue,
      password: newValue,
    }));
  };

  //ユーザー情報更新/登録
  const putPostUser = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    // エンドポイント
    const endpoint = userId
      ? // ? `http://localhost:8080/user/${userId}`
        `${process.env.REACT_APP_BASE_URL}/user/${userId}`
      : // : "http://localhost:8080/user";
        `${process.env.REACT_APP_BASE_URL}/user`;
    // リクエストメソッド（POSTまたはPUT）
    const method = userId ? "PUT" : "POST";
    //リクエストパラメータ
    const params = {
      password: user.password,
    };
    const axiosConfig = {
      method: method,
      url: endpoint,
      data: params,
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    isSuccess
      ? axios(axiosConfig)
          .then((response) => {
            const status = response.data;
            // スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.error("API呼び出しエラー", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          onClick={handleGoBack}
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            paddingLeft: "40px",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            color: "primary.main",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
              color: "primary.dark",
            },
            "&:active": {
              color: "primary.light",
            },
          }}
        >
          アカウント一覧
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          /パスワード更新
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              paddingLeft: "40px",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              "@media screen and (max-width:900px)": {
                padding: "0px",
              },
            }}
          >
            ユーザ情報
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              "@media screen and (max-width:600px)": {
                width: "100%",
                flexDirection: "column",
              },
            }}
          >
            <Box sx={{ width: "248px" }}>組織名（変更不可）</Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "372px",
                height: "40px",
                "@media screen and (max-width:900px)": {
                  width: "248px",
                },
              }}
            >
              {user?.m_organizations?.map((organization, index) => (
                <Chip
                  key={index}
                  label={organization.department_name}
                />)
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              "@media screen and (max-width:600px)": {
                width: "100%",
                flexDirection: "column",
              },
            }}
          >
            <Box sx={{ width: "248px" }}>ユーザー名（変更不可）</Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "14px",
                width: "372px",
                border: "1px solid rgba(0, 0, 0, 0.6)",
                borderRadius: "4px",
                height: "40px",
                "@media screen and (max-width:900px)": {
                  width: "248px",
                },
              }}
            >
              {user?.name || ""}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              "@media screen and (max-width:600px)": {
                width: "100%",
                flexDirection: "column",
              },
            }}
          >
            <Box sx={{ width: "248px" }}>パスワード</Box>
            <FormControl
              size="small"
              fullWidth
              variant="outlined"
              sx={{
                width: "372px",
                "@media screen and (max-width:900px)": {
                  width: "248px",
                },
              }}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                パスワード
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                onChange={(e) => handleChangePass(e)}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "16px",
              "@media screen and (max-width:600px)": {
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            <Button
              variant="contained"
              sx={{ width: "208px" }}
              onClick={putPostUser}
            >
              更新
            </Button>
          </Box>
        </Box>
      </Box>
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}
