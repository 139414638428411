import React from "react";
import Box from "@mui/material/Box";
import AuthorityTable from "./AuthorityTable";

export default function AccountAuthority() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          paddingLeft: "40px",
          height: "48px",
          alignItems: "center",
          fontSize: 20,
          fontWeight: "bold",
          whiteSpace: "nowrap",
        }}
      >
        権限設定
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          gap: "16px",
          padding: "32px 48px",
          borderRadius: 2,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        {/* <Button variant="outlined" sx={{ width: "fit-content" }}>エクスポート</Button> */}
        <AuthorityTable />
      </Box>
    </>
  );
}
