import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Dialog,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import axios from "axios";
import { formatDate } from "../../utils/deteUtils";
import { fetchSession } from "../../auth";

const columns = [
  {
    key: "disaster-start_at",
    id: "start_at",
    label: "災害対応日時（始）",
    minWidth: "120px",
    isSmallScreen: true,
    isDisaster: true,
    isDate: true,
  },
  {
    key: "disaster-kind",
    id: "kind",
    label: "災害種別",
    minWidth: "120px",
    kind: true,
    isSmallScreen: false,
    isDisaster: true,
  },
  {
    key: "disaster-name",
    id: "name",
    label: "災害名",
    minWidth: "120px",
    isSmallScreen: true,
    isDisaster: true,
  },
  {
    key: "customer-name",
    id: "name",
    label: "特約店名",
    minWidth: "120px",
    isSmallScreen: false,
    isCustomer: true,
  },
  {
    key: "site-name",
    id: "name",
    label: "SS名",
    minWidth: "120px",
    isSmallScreen: false,
    isSite: true,
  },
];

export default function DisasterStatus(props) {
  const { disasterStatus } = props;
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  //災害種別スタイル
  const disasterValidate = (disasterName) => {
    return (
      <Box
        sx={{
          borderRadius: "20px",
          padding: "8px",
          color: "white",
          fontWeight: "bold",
          width: "fit-content",
          backgroundColor:
            disasterName === "quake"
              ? "red"
              : disasterName === "w-r-s"
              ? "orange"
              : disasterName === "manual"
              ? "gray"
              : "black",
        }}
      >
        {disasterName === "quake"
          ? "地震"
          : disasterName === "w-r-s"
          ? "雨風雪"
          : disasterName === "manual"
          ? "手動"
          : disasterName === "alert"
          ? "警報"
          : "その他"}
      </Box>
    );
  };

  const handleLocationCodeClick = () => {
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.key}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{
                      color: "outline.dark",
                      fontWeight: "bold",
                      borderBottom: 2,
                      padding: "8px",
                      "@media screen and (max-width:900px)": {
                        display:
                          column.isSmallScreen === true ? "table-cell" : "none",
                      },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                onClick={() => handleLocationCodeClick()}
                sx={{
                  pointerEvents: "none",
                  "@media screen and (max-width:900px)": {
                    cursor: "pointer",
                    pointerEvents: "auto",
                  },
                }}
              >
                {columns.map((column) => {
                  return (
                    <TableCell
                      key={column.key}
                      sx={{
                        padding: "8px",
                        border: "none",
                        "@media screen and (max-width:900px)": {
                          display:
                            column.isSmallScreen === true
                              ? "table-cell"
                              : "none",
                        },
                      }}
                    >
                      {
                        column.isDisaster && column.kind
                        ? disasterValidate(disasterStatus?.disaster?.kind)
                        : column.isDisaster && column.isDate
                        ? formatDate(disasterStatus?.disaster?.[column.id])
                        : column.isDisaster
                        ? disasterStatus?.disaster?.[column.id]
                        : column.isCustomer
                        ? disasterStatus?.site?.m_customer?.[column.id]
                        : column.isSite
                        ? disasterStatus?.site?.[column.id]
                        :""
                      }
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
          <Box
            sx={{
              display: "flex",
              height: "56px",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              borderBottom: "2px solid #CCC",
            }}
          >
            <Box
              sx={{
                paddingLeft: "8px",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              災害情報詳細
            </Box>
            <IconButton
              aria-label="close"
              onClick={handlePopoverClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
            <TableBody>
              {columns.map((column) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={column.key}>
                    <TableCell
                      align={column.align}
                      sx={{
                        color: "outline.dark",
                        fontWeight: "bold",
                        boxSizing: "border-box",
                        width: "calc((100vw - 64px) / 2)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          WebkitWritingMode: "horizontal-tb",
                          height: "100%",
                        }}
                      >
                        {column.label}
                      </Box>
                    </TableCell>
                    <TableCell
                      align={column.align}
                      sx={{
                        writingMode: "horizontal-tb",
                        boxSizing: "border-box",
                        width: "calc((100vw - 64px) / 2)",
                        overflowWrap: "break-word",
                      }}
                    >
                      <>
                        {
                          column.isDisaster && column.kind
                            ? disasterValidate(disasterStatus?.disaster?.kind)
                            : column.isDisaster && column.id === "start_at"
                              ? formatDate(disasterStatus?.disaster?.[column.id])
                              : column.isDisaster
                                ? disasterStatus?.disaster?.[column.id]
                                : column.isCustomer
                                  ? disasterStatus?.site?.m_customer?.[column.id]
                                  : column.isSite
                                    ? disasterStatus?.site?.[column.id]
                                    :""
                        }
                      </>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Dialog>
      </Paper>
    </>
  );
}
