import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";

const NotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 264px)",
      }}
    >
      <Typography variant="h2" sx={{ marginBottom: "16px" }}>
        404 Not Found
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "16px" }}>
        The page you are looking for does not exist.
      </Typography>
      <Button component={Link} to="/" variant="contained" color="primary">
        Go to Home
      </Button>
    </Box>
  );
};

export default NotFound;
