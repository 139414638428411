import React from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Box, Button } from "@mui/material";
import ConnectedTable from "./ConnectedTable";
import DisconnectedTable from "./DisconnectedTable";
import Fab from "@mui/material/Fab";
import SnackbarCustom from "../../../../components/SnackBarCustom";
import { fetchSession } from "../../../../auth";

export default function Connecting() {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // 前の画面に戻る
  };
  //クエリパラメータ取得
  const searchParams = new URLSearchParams(useLocation().search);
  const roleId = searchParams.get("role-id");

  const [update, setUpdate] = useState();
  const handleChangeData = () => {
    // 現在の状態と新しい値を比較し、異なる場合のみ setUpdate を呼び出す
    setUpdate(update ===true ? false:true);
  };
  //関連付け済み組織関連解除操作
  const [connectedOrganizations, setConnectedOrganizations] = useState({
    m_organization_ids: null,
  });

  const splitOrganization = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/organization/unset`;
    const params = {
      m_organization_ids: connectedOrganizations?.m_organization_ids,
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .put(apiUrl, params, headers)
      .then((response) => {
        const status = response.data;
        console.log("関連解除完了", response);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
        handleChangeData();
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  //未関連組織関連付け操作
  const [disconnectedOrganizations, setDisconnectedOrganizations] = useState({
    m_organization_ids: null,
  });

  const joinOrganization = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/organization`;
    const params = {
      m_organization_ids: disconnectedOrganizations?.m_organization_ids,
      //int型に変換
      m_role_id: roleId | 0,
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .put(apiUrl, params, headers)
      .then((response) => {
        const status = response.data;
        console.log("関連付け完了", response);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
        handleChangeData();
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  const ClickRegistration = () => {
    if (connectedOrganizations?.m_organization_ids !== null) {
      splitOrganization();
      setUpdate("split");
    }
    if (disconnectedOrganizations?.m_organization_ids !== null) {
      joinOrganization();
      setUpdate("join");
    }
    setConnectedOrganizations({
      m_organization_ids: null,
    });
    setDisconnectedOrganizations({
      m_organization_ids: null,
    });

  };
  const consoleCheck = () => {
    console.log("connectedOrganizations", connectedOrganizations);
    console.log("disconnectedOrganizations", disconnectedOrganizations);
    console.log("roleId", roleId, typeof roleId);
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          onClick={handleGoBack}
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            paddingLeft: "40px",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            color: "primary.main",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
              color: "primary.dark",
            },
            "&:active": {
              color: "primary.light",
            },
          }}
        >
          権限設定
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          /関連付け
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "32px",
          "@media screen and (max-width:1200px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            width: "100%",
            justifyContent: "center",
            borderRadius: 2,
            borderTop: 16,
            borderColor: "primary.main",
            boxShadow: 3,
            color: "outline.dark",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              justifyContent: "center",
              padding: "16px 40px 32px 40px",
              gap: "40px",
              alignItems: "center",
              "@media screen and (max-width:600px)": {
                padding: "16px 16px 32px 16px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 20,
                fontWeight: "bold",
                color: "primary.main",
                whiteSpace: "nowrap",
              }}
            >
              関連付け済み組織
            </Box>
            <ConnectedTable
              CheckedItems={(value) => setConnectedOrganizations(value)}
              connectedOrganizations={connectedOrganizations}
              update = {update}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            width: "100%",
            justifyContent: "center",
            borderRadius: 2,
            borderTop: 16,
            borderColor: "primary.main",
            boxShadow: 3,
            color: "outline.dark",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              justifyContent: "center",
              padding: "16px 40px 32px 40px",
              gap: "40px",
              alignItems: "center",
              "@media screen and (max-width:600px)": {
                padding: "16px 16px 32px 16px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "144px",
                fontSize: 20,
                fontWeight: "bold",
                color: "primary.main",
                wordWrap: "break-word",
                maxWidth: "216px",
                textAlign: "center",
              }}
            >
              未関連組織
            </Box>
            <DisconnectedTable
              // CheckedItems={(value) => handleChangePutItems(value)}
              CheckedItems={(value) => setDisconnectedOrganizations(value)}
              disconnectedOrganizations={disconnectedOrganizations}
              update = {update}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          position: "sticky",
          bottom: "16px",
        }}
      >
        <Fab
          variant="extended"
          color="primary"
          onClick={ClickRegistration}
          sx={{
            width: "144px",
          }}
        >
          登録
        </Fab>
        {/*{process.env.NODE_ENV === "development" && (*/}
        {/*  <Button onClick={consoleCheck}>コンソール確認</Button>*/}
        {/*)}*/}
      </Box>
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}
