import { Box, TextField } from "@mui/material";
import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import ja from "date-fns/locale/ja";

const DamageStatus = (props) => {
  const { disasterStatus, onDamageStatusChange } = props;
  //被害日時管理
  const damageAt = disasterStatus?.damage_at ? new Date(disasterStatus?.damage_at) : new Date();
  const handleChangeCalender = (e) => {
    //日付の入出力値のフォーマットは親コンポーネントで管理
    //親コンポーネントのステート変更
    onDamageStatusChange({
      ...disasterStatus,
      damage_at: e,
    });
  };

  //被害状況チェックボックス管理
  const handleChangeCheckbox = (e, column) => {
    const newValue = e.target.checked;
    //親コンポーネントのステート変更
    onDamageStatusChange({
      ...disasterStatus,
      [column]: newValue,
    });
  };

  //被害詳細管理
  const handleChangeTextField = (e) => {
    const newValue = e.target.value;
    //親コンポーネントのステート変更
    onDamageStatusChange({
      ...disasterStatus,
      damage_details: newValue,
    });
  };

  return (
    <Box
      className="child1"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        width: "100%",
        justifyContent: "center",
        borderRadius: 2,
        borderTop: 16,
        borderColor: "primary.main",
        boxShadow: 3,
        color: "outline.dark",
        backgroundColor: "#fff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          padding: "16px 40px 32px 40px",
          gap: "16px",
          "@media screen and (max-width:900px)": {
            padding: "16px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 20,
            fontWeight: "bold",
            color: "primary.main",
            whiteSpace: "nowrap",
          }}
        >
          被害状況
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            "@media screen and (max-width:600px)": {
              width: "100%",
              flexDirection: "column",
            },
          }}
        >
          <Box sx={{ width: "248px" }}>被害確認日時</Box>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ja} //ja追加
            dateFormats={{ monthAndYear: "yyyy/MM/dd hh:mm" }} //カレンダー左上の日付表示
            localeText={{
              previousMonth: "前月を表示", // < のツールチップ
              nextMonth: "次月を表示", // > のツールチップ
              cancelButtonLabel: "キャンセル", // スマホ画面のCANCELボタン
              okButtonLabel: "選択", // スマホ画面のOKボタン
              datePickerToolbarTitle: "日付選択",
            }}
          >
            <DateTimePicker
              // inputFormat="yyyy-MM-dd" //選択済みの日付の表示
              inputFormat=""
              mask="____年__月__日"
              format="yyyy/MM/dd HH:mm"
              toolbarFormat="yyyy年MM月dd日"
              textFieldProps={(params) => (
                <TextField
                  size="small"
                  {...params}
                  inputProps={{
                    inputMode: "numeric",
                    ...params.inputProps,
                    placeholder: "****年**月**日", //プレースホルダー（フォーカスを合わせたときに薄く表示される入力例）
                  }}
                />
              )}
              value={damageAt}
              onChange={(e) => handleChangeCalender(e)}
              slotProps={{
                toolbar: {
                  toolbarFormat: "yyyy/MM/dd",
                },
                textField: { size: "small" },
              }}
            />
          </LocalizationProvider>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
          }}
        >
          <Box
            sx={{
              minWidth: "160px",
              padding: "9px 0",
              "@media screen and (max-width:900px)": {
                padding: "0",
              },
            }}
          >
            被害状況（複数選択可）
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  size="small"
                  checked={disasterStatus?.not_damage || false}
                  onChange={(e) => handleChangeCheckbox(e, "not_damage")}
                />
                被害なし
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  size="small"
                  checked={disasterStatus?.personal_damage || false}
                  onChange={(e) => handleChangeCheckbox(e, "personal_damage")}
                />
                人的被害あり
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  size="small"
                  checked={disasterStatus?.property_damage || false}
                  onChange={(e) => handleChangeCheckbox(e, "property_damage")}
                />
                第三者への物損あり
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  size="small"
                  checked={disasterStatus?.facility_damage || false}
                  onChange={(e) => handleChangeCheckbox(e, "facility_damage")}
                />
                SS設備被害あり
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            "@media screen and (max-width:600px)": {
              width: "100%",
              flexDirection: "column",
            },
          }}
        >
          <Box sx={{ width: "248px" }}>被害詳細</Box>
          <TextField
            id="outlined-basic"
            variant="outlined"
            multiline
            rows={2}
            value={disasterStatus?.damage_details || ''}
            onChange={(e) => handleChangeTextField(e)}
            sx={{
              height: "100%",
              width: "100%",
              ".MuiInputBase-root": {
                height: "100%",
              },
              "#outlined-basic": {
                boxSizing: "border-box",
                height: "100%",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default DamageStatus;
