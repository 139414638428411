import React from 'react';
import { Typography, Box } from '@mui/material';

const Unauthorized = () => {
  return (
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100vh - 264px)",
    }}
  >
    <Typography variant="h2" sx={{ marginBottom: "16px" }}>
    403 Unauthorized
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: "16px" }}>
    You are not authorized to access this page.
    </Typography>
  </Box>
  );
};

export default Unauthorized;
