import { Box } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ImportLog from "./ImportLog";
import PopoverCustom from "../../../../components/PopoverCustom";
import SnackbarCustom from "../../../../components/SnackBarCustom";
import { fetchSession } from "../../../../auth";

export default function AccountImport() {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // 前の画面に戻る
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event, file) => {
    event.stopPropagation();
    setSelectedFile(file);
  };

  //インポート処理
  const [importPopoverOpen, setImportPopoverOpen] = useState(false);
  const importButtonClick = () => {
    setImportPopoverOpen(true);
  };
  const importPopupClose = (event) => {
    setImportPopoverOpen(false);
  };

  //登録処理
  const postUserMaster = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/async_task`;
    const params = new FormData();
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    params.append("kind", "importUserMaster");
    params.append("opt_file", selectedFile);

    console.log("selectedFile", ...params.entries());
    console.log("入力データ", ...params.entries());
    isSuccess
      ? axios
          .post(apiUrl, params, headers)
          .then((response) => {
            const status = response.data;
            console.log("アカウントインポートレスポンス:", response);
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.error("API呼び出しエラー", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          "@media screen and (max-width:900px)": {
            flexDirection: "column",
            gap: "-16px",
          },
        }}
      >
        <Box
          onClick={handleGoBack}
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            paddingLeft: "40px",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            color: "primary.main",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
              color: "primary.dark",
            },
            "&:active": {
              color: "primary.light",
            },
          }}
        >
          アカウント一覧
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            "@media screen and (max-width:900px)": {
              paddingLeft: "40px",
            },
          }}
        >
          /アカウントインポート
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              paddingLeft: "40px",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              "@media screen and (max-width:900px)": {
                padding: "0",
              },
            }}
          >
            検索条件
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  maxWidth: "480px",
                }}
              >
                <Button variant="contained" component="label">
                  ファイル選択
                  <input
                    accept=".xls, .xlsx,"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) =>
                      handleFileChange(event, event.target.files[0])
                    }
                  />
                </Button>
                <Button variant="outlined" onClick={importButtonClick}>
                  <DownloadIcon />
                  インポート
                </Button>
              </Box>
              {selectedFile ? (
                <Tooltip title={selectedFile.name}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      height: "36px",
                      width: "100%",
                      maxWidth: "480px",
                      border: "1px #D9D9D9 solid",
                      borderRadius: "4px ",
                    }}
                  >
                    <AttachFileIcon />
                    <Box
                      variant="body1"
                      sx={{
                        height: "32px",
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {selectedFile.name}
                    </Box>
                  </Box>
                </Tooltip>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxSizing: "border-box",
                    height: "36px",
                    width: "100%",
                    maxWidth: "480px",
                    border: "1px #D9D9D9 solid",
                    borderRadius: "4px ",
                  }}
                >
                  <AttachFileIcon />
                  {"ファイルを選択してください "}
                </Box>
              )}
            </Box>
            {/* <Button variant="outlined">アカウント一覧</Button> */}
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              justifyContent: "center",
              gap: "16px",
              paddingTop: "32px",
              paddingRight: "40px",
              paddingLeft: "40px",
              paddingBottom: "32px",
              "@media screen and (max-width:900px)": {
                padding: "16px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "min-content",
                paddingLeft: "40px",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
                "@media screen and (max-width:900px)": {
                  padding: "0",
                },
              }}
            >
              インポート履歴
            </Box>
          </Box>
          <ImportLog />
        </Box>
      </Box>
      <PopoverCustom
        open={importPopoverOpen}
        onClose={importPopupClose}
        yesNo={postUserMaster}
        title={"インポート確認"}
        contents={"インポートしますか？"}
      />
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
}
