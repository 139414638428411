import { Auth } from 'aws-amplify';

export const fetchSession = async () => {
  try {
    const data = await Auth.currentSession();
    const idToken = data.getIdToken().getJwtToken();
    const accessToken = data.getAccessToken().getJwtToken();
    const refreshToken = data.getRefreshToken().getToken();

    const expiration = data.getAccessToken().getExpiration();
    const currentTime = Math.floor(new Date().getTime() / 1000);

    if (expiration - currentTime < 300) {
      const refreshedData = await Auth.currentSession();
      return {
        idToken: refreshedData.getIdToken().getJwtToken(),
        accessToken: refreshedData.getAccessToken().getJwtToken(),
        refreshToken: refreshedData.getRefreshToken().getToken(),
      };
    }

    return { idToken, accessToken, refreshToken };
  } catch (error) {
    console.error("トークン取得エラー:", error);
    throw error;
  }
};
