import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {
  TotalizationRole,
  SSMasterImportRole,
  MailSendRole,
  MailTemplateRole,
  UserRole,
  UserImportRole,
  DisasterConditionRole
} from "../role";

const ProtectedRoute = ({ children, user, requiredRole }) => {
  const searchParams = new URLSearchParams(useLocation().search);
  const pathName = useLocation().pathname;
  let checkRole = {"create": false, "read": false, "update": false, "delete": false};
  switch (requiredRole) {
    case "TotalizationRole":
      checkRole = TotalizationRole(user.m_organization.m_role_id);
      break;
    case "SSMasterImportRole":
      checkRole = SSMasterImportRole(user.m_organization.m_role_id);
      break;
    case "MailSendRole":
      checkRole = MailSendRole(user.m_organization.m_role_id);
      break;
    case "MailTemplateRole":
      checkRole = MailTemplateRole(user.m_organization.m_role_id);
      break;
    case "UserRole":
      checkRole = UserRole(user.m_organization.m_role_id);
      if (!checkRole.read) {
        // 自身のアカウントは許容する処理
        switch (pathName) {
          case "/account/list/edit":
            if (searchParams.get("user-id") == user.id) {
              // 自ユーザーの操作権限を与える
              checkRole = {"create": false, "read": true, "update": true, "delete": false};
            }
            break;
        }
      }
      break;
    case "UserImportRole":
      checkRole = UserImportRole(user.m_organization.m_role_id);
      break;
    case "DisasterConditionRole":
      checkRole = DisasterConditionRole(user.m_organization.m_role_id);
      break;
    default:
      break;
  }
  console.log(checkRole, requiredRole);
  if (!checkRole.read) {
    // ユーザーが権限を持っているかを確認
    return <Navigate to="/forbidden" replace />; // 権限がなければエラーページへリダイレクト
  }
  return children; // 権限があれば元のコンポーネントを表示
};

export default ProtectedRoute;
