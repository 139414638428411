import { Box } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ImportLog from "./ImportLog";
import axios from "axios";
import SnackbarCustom from "../../../components/SnackBarCustom";
import PopoverCustom from "../../../components/PopoverCustom";
import { fetchSession } from "../../../auth";

export default function SsImport() {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // 前の画面に戻る
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  // 登録項目管理

  const postDisaster = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/async_task`;

    const params = new FormData();
    params.append("kind", "importSSMaster");
    params.append("opt_file", selectedFile || null);

    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    isSuccess
      ? axios
          .post(apiUrl, params, headers)
          .then((response) => {
            console.log("SS情報登録", response);
            const status = response.data;
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.error("API呼び出しエラー", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };
  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  //ポップオーバー管理
  const [confirmPopoverOpen, setPopoverSplitOpen] = useState(false);
  const putImportButtonClick = () => {
    setPopoverSplitOpen(true);
  };
  const confirmPopupClose = (event) => {
    setPopoverSplitOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          "@media screen and (max-width:900px)": {
            flexDirection: "column",
            gap: "-16px",
          },
        }}
      >
        <Box
          onClick={handleGoBack}
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            paddingLeft: "40px",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            color: "primary.main",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
              color: "primary.dark",
            },
            "&:active": {
              color: "primary.light",
            },
            "@media screen and (max-width:900px)": {
              paddingLeft: 2,
            },
          }}
        >
          SS基本情報一覧
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            "@media screen and (max-width:900px)": {
              paddingLeft: 2,
            },
          }}
        >
          /SS基本台帳インポート
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              paddingLeft: "40px",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
            }}
          >
            検索条件
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  maxWidth: "480px",
                }}
              >
                <Button variant="contained" component="label">
                  ファイル選択
                  <input
                    accept=".xls, .xlsx,"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Button>
                <Button variant="outlined" onClick={putImportButtonClick}>
                  <DownloadIcon />
                  インポート
                </Button>
              </Box>
              {selectedFile ? (
                <Tooltip title={selectedFile.name}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      height: "36px",
                      width: "100%",
                      maxWidth: "480px",
                      border: "1px #D9D9D9 solid",
                      borderRadius: "4px ",
                    }}
                  >
                    <AttachFileIcon />
                    <Box
                      variant="body1"
                      sx={{
                        height: "32px",
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {selectedFile.name}
                    </Box>
                  </Box>
                </Tooltip>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxSizing: "border-box",
                    height: "36px",
                    width: "100%",
                    maxWidth: "480px",
                    border: "1px #D9D9D9 solid",
                    borderRadius: "4px ",
                  }}
                >
                  <AttachFileIcon />
                  {"ファイルを選択してください "}
                </Box>
              )}
            </Box>
            <Button variant="outlined">SS基本情報一覧</Button>
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              justifyContent: "center",
              gap: "16px",
              paddingTop: "32px",
              paddingRight: "40px",
              paddingLeft: "40px",
              paddingBottom: "32px",
              "@media screen and (max-width:900px)": {
                padding: "16px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "min-content",
                paddingLeft: "40px",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
              }}
            >
              インポート履歴
            </Box>
          </Box>
          <ImportLog />
        </Box>
        <PopoverCustom
          open={confirmPopoverOpen}
          onClose={confirmPopupClose}
          yesNo={(e) => postDisaster(e)}
          title={"登録確認"}
          contents={"登録してもよろしいですか？"}
        />
        <SnackbarCustom
          data={snackBarstatus}
          openStatus={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
        />
      </Box>
    </>
  );
}
