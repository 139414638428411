import React, {useState, useEffect, useRef, useContext} from "react";
import {
  Box,
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Close as CloseIcon,
  ModeEditOutline as ModeEditOutlineIcon,
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
} from "@mui/icons-material";
import SnackbarCustom from "../../../components/SnackBarCustom";
import PopoverCustom from "../../../components/PopoverCustom";
import RoleSelect from "./RoleSelect";
import { fetchSession } from "../../../auth";
import {UserRole} from "../../../role";
import {TokenContext} from "../../../TokenContext";

const columns = [
  {
    id: "open_id",
    label: "ログインID",
    minWidth: "fit-content",
    isSmallScreen: true,
  },
  {
    id: "m_organizations",
    label: "所属",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: false,
    organizations: true,
  },
  {
    id: "role",
    label: "権限",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: false,
    role: true,
  },
  {
    id: "name",
    label: "名前",
    minWidth: "fit-content",
    align: "left",
    isSmallScreen: true,
  },
  {
    id: "operation",
    label: "",
    align: "right",
    button: true,
    isSmallScreen: true,
  },
];

export default function AccountList() {
  const { user } = useContext(TokenContext);
  const userRole = UserRole(user.m_organization.m_role_id)

  const navigate = useNavigate();
  const handleClick = (event, path) => {
    event.stopPropagation();
    navigate(path);
  };

  const ButtonVaridate = (props) => {
    const { userId } = props;
    return (
      userRole.update ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            height: "36px",
            width: "100%",
          }}
        >
          <IconButton
            color="primary"
            onClick={(event) =>
              handleClick(event, `/account/list/edit?user-id=${userId}`)
            }
            sx={{ pointerEvents: "auto" }}
          >
            <ModeEditOutlineIcon />
          </IconButton>
        </Box>
      ) : (
        ""
      )
    );
  };

  const [selectedRow, setSelectedRow] = useState(null);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleLocationCodeClick = (row) => {
    console.log("row", row);
    setSelectedRow(row);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState();
  const [role, setRole] = useState([]);

  //ユーザー一覧取得
  const getUsers = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/users`;
    const params = {
      params: {
        m_role_id: [role["m_role_id"]],
        m_branch_id: null,
        m_organization_id: null,
        order: 'm_organizations.parent_m_organization_id, m_organizations.code, m_organizations.id',
        sort: null,
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.users;
        const total = response.data.total_count;
        const status = response.data;
        console.log("ユーザー一覧取得取得", response);
        setUsers(data);
        setTotalData(total);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  //初回起動時の読み込み回避
  const isFirstRender = useRef(false);

  useEffect(() => {
    // このeffectは初回レンダー時のみ呼ばれるeffect
    isFirstRender.current = true;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // 初回レンダー判定
      isFirstRender.current = false; // もう初回レンダーじゃないよ代入
    } else {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  //選択した災害名称管理
  // const [DisasterName, setDisasterName] = useState();
  const handleChangeRole = (e) => {
    // setDisplayCode(e.target.value)
    setRole((prev) => ({
      ...prev,
      m_role_id: e.id,
    }));
    // setDisasterName(e.name);
  };

  // 削除処理
  const [deleteUser, setDeleteUser] = useState([]);
  const [deletePopoverOpen, setDeletePopoverOpen] = useState(false);
  const deleteButtonClick = (e, userId) => {
    e.stopPropagation();
    setDeleteUser(userId);
    setDeletePopoverOpen(true);
  };
  const confirmPopupClose = (event) => {
    setDeletePopoverOpen(false);
  };

  //ユーザー削除
  const deleteUsers = async (userId) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/user/${deleteUser}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .delete(apiUrl, headers)
      .then((response) => {
        const status = response.data;
        console.log("ユーザー削除", response);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);

        getUsers();
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  //ページネーション
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "48px",
          alignItems: "center",
          paddingLeft: 5,
          fontSize: 20,
          fontWeight: "bold",
          whiteSpace: "nowrap",
        }}
      >
        アカウント一覧
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              paddingLeft: "40px",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              "@media screen and (max-width:900px)": {
                padding: "0",
              },
            }}
          >
            検索条件
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "16px",
              "@media screen and (max-width:600px)": {
                flexDirection: "column",
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
                maxWidth: "480px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box sx={{ minWidth: "160px" }}>権限区分</Box>
                <RoleSelect changedValue={(value) => handleChangeRole(value)} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "16px",
              "@media screen and (max-width:600px)": {
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                "@media screen and (max-width:600px)": {
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <Button
                variant="contained"
                sx={{ width: "208px" }}
                onClick={getUsers}
              >
                検索
              </Button>
              <Button
                variant="outlined"
                onClick={(e) => handleClick(e, "/account/list/import")}
                sx={{ width: "208px" }}
              >
                アカウントインポート
              </Button>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              justifyContent: "center",
              gap: "16px",
              paddingTop: "32px",
              paddingRight: "40px",
              paddingLeft: "40px",
              paddingBottom: "32px",
              "@media screen and (max-width:900px)": {
                padding: "16px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "min-content",
                paddingLeft: "40px",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
                "@media screen and (max-width:900px)": {
                  padding: "0",
                },
              }}
            >
              検索結果
            </Box>
          </Box>
          <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        sx={{
                          padding: "8px",
                          fontWeight: "bold",
                          borderBottom: 2,
                          borderBottomColor: "outline.dark",
                          "@media screen and (max-width:900px)": {
                            display:
                              column.isSmallScreen === true
                                ? "table-cell"
                                : "none",
                          },
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.map((user) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={user.id}
                        onClick={() => handleLocationCodeClick(user)}
                        sx={{
                          pointerEvents: "none",
                          "@media screen and (max-width:900px)": {
                            cursor: "pointer",
                            pointerEvents: "auto",
                          },
                        }}
                      >
                        {columns.map((column) => {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{
                                padding: "8px",
                                "@media screen and (max-width:900px)": {
                                  display:
                                    column.isSmallScreen === true
                                      ? "table-cell"
                                      : "none",
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "32px",
                                }}
                              >
                                {column.button ? (
                                  <ButtonVaridate userId={user.id} />
                                ) : column.role ? (
                                  <>{user.m_organizations[0]?.m_role?.name}</>
                                ) : column.organizations ? (
                                  <>{
                                    (user.m_organizations[0]?.parent_m_organization?.parent_m_organization ? user.m_organizations[0]?.parent_m_organization?.parent_m_organization.department_name + ' ' : '')
                                    +
                                    (user.m_organizations[0]?.parent_m_organization ? user.m_organizations[0]?.parent_m_organization.department_name + ' ' : '')
                                    +
                                    (user.m_organizations[0] ? user.m_organizations[0].department_name : '')
                                  }</>
                                ) : (
                                  <>{user[column.id]}</>
                                )}
                              </Box>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
              <Box
                sx={{
                  display: "flex",
                  height: "56px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "8px",
                  borderBottom: "2px solid #CCC",
                }}
              >
                <Box
                  sx={{
                    paddingLeft: "8px",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  アカウント詳細
                </Box>
                <IconButton
                  aria-label="close"
                  onClick={handlePopoverClose}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
                <TableBody>
                  {selectedRow &&
                    columns.slice(0, 4).map((column) => {
                      return (
                        <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                          <TableCell
                            align={column.align}
                            sx={{
                              color: "outline.dark",
                              fontWeight: "bold",
                              boxSizing: "border-box",
                              width: "calc((100vw - 64px) / 2)",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                WebkitWritingMode: "horizontal-tb",
                                height: "100%",
                              }}
                            >
                              {column.label}
                            </Box>
                          </TableCell>
                          <TableCell
                            align={column.align}
                            sx={{
                              writingMode: "horizontal-tb",
                              boxSizing: "border-box",
                              width: "calc((100vw - 64px) / 2)",
                              overflowWrap: "break-word",
                            }}
                          >
                            {column.button ? (
                              <ButtonVaridate userId={selectedRow.id} />
                            ) : column.role ? (
                              <>
                                {selectedRow.m_organizations[0]?.m_role?.name}
                              </>
                            ) : column.organizations ? (
                              <>
                                {
                                  selectedRow.m_organizations[0]?.parent_m_organization?.parent_m_organization?.department_name
                                  +' '+selectedRow.m_organizations[0]?.parent_m_organization?.department_name
                                  +' '+selectedRow.m_organizations[0]?.department_name
                                }
                              </>
                            ) : (
                              <>{selectedRow[column.id]}</>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <Box
                sx={{
                  margin: "16px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ButtonVaridate userId={selectedRow?.id} />
              </Box>
            </Dialog>
            <SnackbarCustom
              data={snackBarstatus}
              openStatus={snackbarOpen}
              onClose={() => setSnackbarOpen(false)}
            />
            <PopoverCustom
              open={deletePopoverOpen}
              onClose={confirmPopupClose}
              yesNo={deleteUsers}
              title={"削除確認"}
              contents={"削除しますか？"}
            />
            <TablePagination
              rowsPerPageOptions={[10, 30, 50, 100]}
              component="div"
              count={totalData | 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions":
                  {
                    margin: "0 !important",
                    padding: "0 !important",
                  },
              }}
            />
          </Paper>
        </Box>
      </Box>
    </>
  );
}
