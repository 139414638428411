import React, {useState, useEffect, useContext} from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Box,
  Dialog,
  IconButton,
} from "@mui/material";
import {
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
  Close as CloseIcon, QuestionMark,
} from "@mui/icons-material";
import JoinDisasterSelect from "./JoinDisasterSelect";
import SnackbarCustom from "../../../../../components/SnackBarCustom";
import PopoverCustom from "../../../../../components/PopoverCustom";
import { fetchSession } from "../../../../../auth";
import {formatDate} from "../../../../../utils/deteUtils";
import {ManualCreateDisasterRole} from "../../../../../role";
import {TokenContext} from "../../../../../TokenContext";

const columns = [
  {
    id: "CheckBox",
    label: "",
    minWidth: "64px",
    CheckBox: true,
    isSmallScreen: true,
    checkBox: true,
  },
  {
    id: "condition_kind",
    label: "種類",
    minWidth: "112px",
    isSmallScreen: true,
    site: true,
    kind: true,
  },
  {
    id: "description",
    label: "内容",
    minWidth: "96px",
    isSmallScreen: true,
    site: true,
  },
  {
    id: "ss_count",
    label: "被災SS数",
    minWidth: "144px",
    isSmallScreen: false,
    site: true,
    count: true,
  },
  {
    id: "last_detect_at",
    label: "検知日時",
    minWidth: "96px",
    isSmallScreen: false,
    site: true,
    date: true
  },
];

const DisasterInfomation = (props) => {
  const { user } = useContext(TokenContext);
  const manualCreateDisasterRole = ManualCreateDisasterRole(user.m_organization.m_role_id)
  //クエリパラメータ取得
  const searchParams = new URLSearchParams(useLocation().search);
  const disasterId = searchParams.get("disaster-id");

  const { searchContent } = props;

  //検索結果ページネーション
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // 災害対象SS一覧取得
  const [disasterDetails, setDisasterDetails] = useState([]);
  const [totalData, setTotalData] = useState();

  const getDisasterDetails = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster-details/${disasterId}`;
    const params = {
      params: {
        order: 'last_detect_at', // デフォルト値: 'code'
        sort: 'desc',
        page: page + 1,
        limit: rowsPerPage,
      },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    axios
      .get(apiUrl, params)
      .then((response) => {
        const data = response.data.disaster_details;
        const total = response.data.total_count;
        const status = response.data;
        // テナント情報をステートにセット
        setDisasterDetails(data);
        setTotalData(total);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    getDisasterDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page]);

  const [selectedRow, setSelectedRow] = useState(null);
  //*ポップアップ設定 */
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  //チェックボックスのチェック管理
  const [checkedDetails, setCheckedDetails] = useState([]);
  const [checkedName, setCheckedName] = useState([]);

  const CheckboxValidate = (props) => {
    const { siteId, sites } = props;
    const site = sites.map((site) => site.site_id);
    const isChecked = checkedDetails.some((item) => item[siteId]);
    const handleChange = (e) => {
      if (e.target.checked === true) {
        setCheckedDetails((prevChecked) => {
          if (!prevChecked.includes(siteId)) {
            return [...prevChecked, { [siteId]: site }];
          }
          return prevChecked;
        });
        setCheckedName((prevChecked) => {
          if (!prevChecked.includes(siteId)) {
            return [...prevChecked, siteId];
          }
          return prevChecked;
        });
      } else {
        setCheckedDetails((prevChecked) =>
          prevChecked.filter((checkedItem) => !checkedItem[siteId])
        );
        setCheckedName((prevChecked) =>
          prevChecked.filter((checkedId) => checkedId !== siteId)
        );
      }
    };

    return (
      <Checkbox
        size="small"
        checked={isChecked}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleChange(e)}
        sx={{ cursor: "pointer", pointerEvents: "auto" }}
      />
    );
  };

  const disasterValidate = (disasterName) => {
    return (
      <Box
        sx={{
          borderRadius: "20px",
          padding: "8px",
          color: "white",
          fontWeight: "bold",
          width: "fit-content",
          backgroundColor:
            disasterName === "quake"
              ? "red"
              : disasterName === "w-r-s"
              ? "orange"
              : disasterName === "manual"
              ? "gray"
              : "black",
        }}
      >
        {disasterName === "quake"
          ? "地震"
          : disasterName === "w-r-s"
          ? "雨風雪"
          : disasterName === "manual"
          ? "手動"
          : disasterName === "alert"
          ? "警報"
          : "その他"}
      </Box>
    );
  };

  const handleLocationCodeClick = (row) => {
    setSelectedRow(row);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  //検索結果ページネーション
  const handleChangePage = (event, newPage) => {
    console.log("newPage", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //選択した災害名称管理
  const [DisasterName, setDisasterName] = useState();
  const handleChangeDisaster = (e) => {
    setSelectedContents((prev) => ({
      ...prev,
      dst_id: e.id,
    }));
    setDisasterName(e.name);
  };

  //選択災害詳細情報に関連するSSの合計
  const total = checkedDetails.reduce((sum, item) => {
    //checkedDetailsのkeyをkeysに設定
    const keys = Object.keys(item);
    const values = item[keys[0]];
    return sum + values.length;
  }, 0);

  //選択災害詳細情報に関連するSSを抜き出す
  const allSites = checkedDetails.flatMap((item) => Object.values(item)[0]);

  //結合確認コメント
  const joinMessage = (
    <div>
      災害情報
      <br />
      {checkedName.map((name, index) => (
        <span key={index}>
          ・ID{name}
          <br />
        </span>
      ))}
      に関連するSS {total}件を
      <br />"{DisasterName}"に結合しますか？
    </div>
  );

  //分割確認コメント
  const splitMessage = (
    <div>
      災害情報
      <br />
      {checkedName.map((name, index) => (
        <span key={index}>
          ・ID{name}
          <br />
        </span>
      ))}
      に関連するSS {total}件を分割しますか？
    </div>
  );

  //削除確認コメント
  const deleteMessage = (
    <div>
      災害情報
      <br />
      {checkedName.map((name, index) => (
        <span key={index}>
          ・ID{name}
          <br />
        </span>
      ))}
      に関連するSS {total}件を削除しますか？
    </div>
  );

  //災害情報結合
  const [popoverJoinOpen, setPopoverJoinOpen] = useState(false);
  const joinButtonClick = () => {
    setPopoverJoinOpen(true);
  };
  const joinPopupClose = (event) => {
    setPopoverJoinOpen(false);
  };
  const [selectedContents, setSelectedContents] = useState({
    dst_id: null,
  });
  const joinDisasters = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster/${disasterId}/join`;
    const params = {
      site_id: allSites,
      dst_id: selectedContents["dst_id"],
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("災害情報結合入力データ", params);
    isSuccess
      ? axios
          // .put(apiUrl, condition)
          .put(apiUrl, params, headers)
          .then((response) => {
            const status = response.data;
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            console.log("災害情報結合:", response.data);
            //結合後選択済みサイトをリセット
            setCheckedDetails([]);
            setCheckedName([]);
          })
          .catch((error) => {
            console.error("エラーが発生しました:", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  //災害情報分割
  const [popoverSplitOpen, setPopoverSplitOpen] = useState(false);
  const splitButtonClick = () => {
    setPopoverSplitOpen(true);
  };
  const splitPopupClose = (event) => {
    setPopoverSplitOpen(false);
  };
  const splitDisasters = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster/${disasterId}/split`;
    const params = {
      site_id: allSites,
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    isSuccess
      ? axios
          .post(apiUrl, params, headers)
          .then((response) => {
            const status = response.data;
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            //結合後選択済みサイトをリセット
            setCheckedDetails([]);
            setCheckedName([]);
          })
          .catch((error) => {
            console.error("エラーが発生しました:", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  //災害情報削除
  const [popoverDeleteOpen, setPopoverDeleteOpen] = useState(false);
  const deleteButtonClick = () => {
    setPopoverDeleteOpen(true);
  };
  const deletePopupClose = (event) => {
    setPopoverDeleteOpen(false);
  };

  const deleteDisasters = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster/${disasterId}`;
    const params = {
      params: { site_id: allSites },
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    isSuccess
      ? axios
          .delete(apiUrl, params)
          .then((response) => {
            const status = response.data;
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            //結合後選択済みサイトをリセット
            setCheckedDetails([]);
            setCheckedName([]);
            getDisasterDetails();
          })
          .catch((error) => {
            console.error("エラーが発生しました:", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  // 対象SS確認ボタン
  const [ssConfirmDisasterStatus, setSsConfirmDisasterStatus] = useState([]);
  const ssConfirmMessage = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell key='code'>Sコード</TableCell>
            <TableCell key='name'>SS名称</TableCell>
            <TableCell key='operator'>運営店</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ssConfirmDisasterStatus.map((ds) => (
            <TableRow key={ds.id}>
              <TableCell key={ds.site.code}>{ds.site.code}</TableCell>
              <TableCell key={ds.site.name}>{ds.site.name}</TableCell>
              <TableCell key={ds.site.operator}>{ds.site.operator}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
  // 災害名登録ポップオーバー管理
  const [ssConfirmOpen, setSsConfirmOpen] = useState(false);
  const ssConfirmBtnClick = (e, disasterStatus) => {
    e.stopPropagation();
    console.log(disasterStatus)
    setSsConfirmDisasterStatus(disasterStatus);
    setSsConfirmOpen(true);
  };
  const ssConfirmClose = (event) => {
    setSsConfirmOpen(false);
  };
  const ConfirmButton = (props) => {
    const { disasterStatus } = props;
    return (
      <IconButton
        color="secondary"
        sx={{ pointerEvents: "auto" }}
        onClick={(e) => ssConfirmBtnClick(e, disasterStatus)}
      >
        <QuestionMark />
      </IconButton>
    );
  };

  return (
    <>
      <Box>
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ width: "100%" }}
            >
              <TableHead>
                <TableRow sx={{ overflowX: "auto" }}>
                  {columns.map((column) => (
                    <TableCell
                      key={`${column.label}_${column.id}`}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      sx={{
                        padding: "8px",
                        "@media screen and (max-width:900px)": {
                          display:
                            column.isSmallScreen === true
                              ? "table-cell"
                              : "none",
                        },
                        backgroundColor:
                          column.id === "code" ? "primary.light" : "#FFF",
                        color: column.id === "code" ? "#FFF" : "outline.dark",
                        fontWeight: "bold",
                        borderBottom: "2px solid #606060",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {disasterDetails?.map((disasterDetail, rowNum) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`${disasterDetail.id}_${rowNum}`}
                      onClick={() => handleLocationCodeClick(disasterDetail)}
                      sx={{
                        pointerEvents: "none",
                        "@media screen and (max-width:900px)": {
                          cursor: "pointer",
                          pointerEvents: "auto",
                        },
                      }}
                    >
                      {columns.map((column) => {
                        return (
                          <TableCell
                            key={`${column.label}_${column.id}`}
                            align={column.align}
                            sx={{
                              padding: "8px",
                              "@media screen and (max-width:900px)": {
                                display:
                                  column.isSmallScreen === true
                                    ? "table-cell"
                                    : "none",
                              },
                            }}
                          >
                            {column.checkBox ? (
                              <CheckboxValidate
                                siteId={disasterDetail?.id}
                                sites={disasterDetail?.disaster_statuses}
                              />
                            ) : null}
                            {column.count ? (
                                <>
                                  {disasterDetail.disaster_statuses.length}
                                  <ConfirmButton disasterStatus={disasterDetail.disaster_statuses} />
                                </>
                            ) : column.kind ? (
                              disasterValidate(disasterDetail?.[column.id])
                            ) : column.date ? (
                              formatDate(disasterDetail?.[column.id])
                            ) : (
                              <>{disasterDetail?.[column.id]}</>
                            )}


                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {manualCreateDisasterRole.update ? (
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                padding: "8px",
              }}
            >
              <JoinDisasterSelect
                changedValue={(value) => handleChangeDisaster(value)}
              />
              <Button
                variant="contained"
                onClick={joinButtonClick}
                disabled={
                  checkedDetails.length === 0 || selectedContents.dst_id === null
                }
              >
                結合
              </Button>
              <Button
                variant="outlined"
                onClick={splitButtonClick}
                disabled={checkedDetails.length === 0}
              >
                分割
              </Button>
              <IconButton
                color="error"
                onClick={deleteButtonClick}
                disabled={checkedDetails.length === 0}
              >
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </Box>
          ) : null }
          <Dialog open={isPopoverOpen} onClose={handlePopoverClose}>
            <Box
              sx={{
                display: "flex",
                height: "56px",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px",
                borderBottom: "2px solid #CCC",
              }}
            >
              <Box
                sx={{
                  paddingLeft: "8px",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                災害情報詳細
              </Box>
              <IconButton
                aria-label="close"
                onClick={handlePopoverClose}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Table sx={{ WebkitWritingMode: "horizontal-tb" }}>
              <TableBody>
                {selectedRow &&
                  columns.slice(1, 10).map((column) => {
                    // const value = selectedRow[column.id];
                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={column.id}>
                        <TableCell
                          align={column.align}
                          sx={{
                            color: "outline.dark",
                            fontWeight: "bold",
                            boxSizing: "border-box",
                            width: "calc((100vw - 64px) / 2)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              WebkitWritingMode: "horizontal-tb",
                              height: "100%",
                            }}
                          >
                            {column.label}
                          </Box>
                        </TableCell>
                        <TableCell
                          align={column.align}
                          sx={{
                            writingMode: "horizontal-tb",
                            boxSizing: "border-box",
                            width: "calc((100% - 64px) / 2)",
                            overflowWrap: "break-word",
                          }}
                        >
                          {column.checkBox ? (
                            <CheckboxValidate
                              siteId={selectedRow?.id}
                              sites={selectedRow?.disaster_statuses}
                            />
                          ) : null}
                          {column.count ? (
                            <>{selectedRow.disaster_statuses.length}</>
                          ) : column.kind ? (
                            disasterValidate(selectedRow?.[column.id])
                          ) : column.date ? (
                            formatDate(selectedRow?.[column.id])
                          ) : (
                            <>{selectedRow?.[column.id]}</>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <Box
              sx={{
                margin: "16px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  padding: "0 8px",
                  height: "36.5px",
                  border: "solid 1px #0752A3",
                  borderRadius: "4px",
                  fontWeight: "500",
                  fontSize: "0.875rem",
                  lineHeight: "1.75",
                  letterSpacing: "0.02857",
                  color: "#0752A3",
                }}
              >
                選択
                <CheckboxValidate
                  siteId={selectedRow?.id}
                  sites={selectedRow?.disaster_statuses}
                />
              </Box>
            </Box>
          </Dialog>
          <PopoverCustom
            open={popoverJoinOpen}
            onClose={joinPopupClose}
            yesNo={joinDisasters}
            title={"結合確認"}
            contents={joinMessage}
          />
          <PopoverCustom
            open={popoverSplitOpen}
            onClose={splitPopupClose}
            yesNo={splitDisasters}
            title={"分割確認"}
            contents={splitMessage}
          />
          <PopoverCustom
            open={popoverDeleteOpen}
            onClose={deletePopupClose}
            yesNo={deleteDisasters}
            title={"削除確認"}
            contents={deleteMessage}
          />
          <PopoverCustom
            open={ssConfirmOpen}
            onClose={ssConfirmClose}
            title={"被災SS一覧"}
            contents={ssConfirmMessage}
          />
          <SnackbarCustom
            data={snackBarstatus}
            openStatus={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
          />
          <TablePagination
            rowsPerPageOptions={[10, 30, 50, 100]}
            component="div"
            count={totalData | 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiToolbar-root,.MuiInputBase-root,.MuiTablePagination-actions":
                {
                  margin: "0 !important",
                  padding: "0 !important",
                },
            }}
          />
        </Paper>
      </Box>
    </>
  );
};
export default DisasterInfomation;
