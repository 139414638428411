import { Box } from "@mui/material";
import React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import SearchResult from "./SearchResult";
import PrefectureSelect from "../../components/PrefectureSelect";
import CustomerSelect from "../../components/CustomerSelect";
import BranchSelect from "../../components/BranchSelect";

export default function SsInfomation() {
  // 検索条件
  const [searchContents, setSearchContents] = useState({
    display_code: null,
    name: null,
    pref: null,
    division_code: null,
    m_branch_id: null, // 例: 都道府県コードを指定 (複数指定可能)
    m_customer_id: null, // 例: 得意先IDを指定 (複数指定可能)
    sir_at: null,
    owned_kind: null,
    sir_status: null,
  });

  //ロケーションコード設定
  const handleChangeLocathion = (e) => {
    setSearchContents((prev) => ({
      ...prev,
      display_code: e.target.value,
    }));
  };

  //SS名称設定
  const handleChangeName = (e) => {
    setSearchContents((prev) => ({
      ...prev,
      name: e.target.value,
    }));
  };

  //都道府県情報設定
  const handleChangePref = (e) => {
    setSearchContents((prev) => ({
      ...prev,
      pref: e,
    }));
  };

  //支店情報設定
  const handleChangeBranch = (e) => {
    setSearchContents((prev) => ({
      ...prev,
      m_branch_id: e,
    }));
  };

  //得意先情報設定
  const handleChangeCustomer = (e) => {
    setSearchContents((prev) => ({
      ...prev,
      m_customer_id: e,
    }));
  };

  //所有区分情報設定
  const CheckboxValidate = (props) => {
    const { ownedKind } = props;
    // checked に ownedKind が存在する場合、isChecked が true
    const isChecked = searchContents.owned_kind?.includes(ownedKind);

    const handleChange = (e) => {
      setSearchContents((prevSearchContents) => {
        const currentOwnedKind = prevSearchContents.owned_kind || [];

        if (e.target.checked === true) {
          // チェックが入った場合、roleId を m_role_id 配列に追加
          return {
            ...prevSearchContents,
            owned_kind: [...currentOwnedKind, ownedKind],
          };
        } else {
          // チェックが外された場合、roleId を m_role_id 配列から削除
          return {
            ...prevSearchContents,
            owned_kind: currentOwnedKind.filter(
              (checkedId) => checkedId !== ownedKind
            ),
          };
        }
      });
    };

    return (
      <Checkbox
        size="small"
        checked={isChecked}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleChange(e)}
        sx={{ cursor: "pointer", pointerEvents: "auto" }}
      />
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "48px",
          alignItems: "center",
          paddingLeft: 5,
          fontSize: 20,
          fontWeight: "bold",
          whiteSpace: "nowrap",
          "@media screen and (max-width:900px)": {
            paddingLeft: 2,
          },
        }}
      >
        SS基本情報一覧
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: "min-content",
              paddingLeft: "40px",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000",
              whiteSpace: "nowrap",
              "@media screen and (max-width:900px)": {
                padding: "0px",
              },
            }}
          >
            検索条件
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "48px",
              "@media screen and (max-width:600px)": {
                flexDirection: "column",
                gap: "16px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
                maxWidth: "480px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box sx={{ minWidth: "160px" }}>Sコード</Box>
                <Box sx={{ display: "flex", width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px",
                      height: "40px",
                      border: "1px solid #CCC",
                      borderRight: "none",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "4px",
                    }}
                  >
                    S
                  </Box>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(e) => handleChangeLocathion(e)}
                    sx={{
                      height: "40px",
                      width: "100%",
                      ".MuiInputBase-root": {
                        height: "100%",
                      },
                      "#outlined-basic": {
                        boxSizing: "border-box",
                        height: "100%",
                      },
                      ".MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "0 4px 4px 0",
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box sx={{ minWidth: "160px" }}>SS名称</Box>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  onChange={(e) => handleChangeName(e)}
                  sx={{
                    height: "40px",
                    width: "100%",
                    ".MuiInputBase-root": {
                      height: "100%",
                    },
                    "#outlined-basic": {
                      boxSizing: "border-box",
                      height: "100%",
                    },
                    ".MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "0 4px 4px 0",
                      },
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box sx={{ minWidth: "160px" }}>都道府県</Box>
                <PrefectureSelect
                  branchId={searchContents["m_branch_id"]}
                  customerId={searchContents["m_customer_id"]}
                  changedValue={(value) => handleChangePref(value)}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
                maxWidth: "480px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box sx={{ minWidth: "160px" }}>特約店</Box>
                <CustomerSelect
                  prefCode={searchContents["pref"]}
                  branchId={searchContents["m_branch_id"]}
                  changedValue={(value) => handleChangeCustomer(value)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box sx={{ minWidth: "160px" }}>支店</Box>
                <BranchSelect
                  prefCode={searchContents["pref"]}
                  customerId={searchContents["m_customer_id"]}
                  changedValue={(value) => handleChangeBranch(value)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box sx={{ minWidth: "160px" }}>所有区分</Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    "@media screen and (max-width:600px)": {
                      flexDirection: "column",
                      width: "100%",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckboxValidate
                      ownedKind={1}
                      column={"m_organization_id"}
                    />
                    自己
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckboxValidate
                      ownedKind={9}
                      column={"m_organization_id"}
                    />
                    社有
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <SearchResult searchContent={searchContents} />
      </Box>
    </>
  );
}
