import React from "react";
import { Box } from "@mui/material";
import SearchResult from "./SearchResult";

export default function SsLog() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "48px",
          alignItems: "center",
          paddingLeft: 5,
          fontSize: 20,
          fontWeight: "bold",
          whiteSpace: "nowrap",
          "@media screen and (max-width:900px)": {
            paddingLeft: 2,
          },
        }}
      >
        SS被害履歴
      </Box>
      <SearchResult />
    </>
  );
}
