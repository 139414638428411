import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

export default function OtherDisasterPopup(props) {
  const { otherDisasterStatuses, id } = props;
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    (!otherDisasterStatuses || otherDisasterStatuses.length === 0) ? null :
    <React.Fragment>
      <Button
        color="secondary"
        variant="contained"
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        // onClick={(event) => { handleClick(); event.stopPropagation(); }}
        sx={{ pointerEvents: "auto" }}
        endIcon={<KeyboardArrowDownIcon />}
      >
        他の対応中災害
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {otherDisasterStatuses.map((row, index) => (
          <MenuItem
            key={id + '_' + index}
            onClick={() =>
              handleNavigate(`/dashboard/situationcheck/report?disaster-id=${row['disaster']['id']}&status-id=${row['id']}`)
              // handleNavigate(`/dashboard/sslist?disaster-id=${row['id']}`)
            }
            value="click_map"
            sx={{ fontWeight: "bold" }}
          >
            {row['disaster']['name'] ? row['disaster']['name'] : '名称未設定'}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
