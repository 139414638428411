/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  // "aws_project_region": "ap-northeast-1",
  // // "aws_appsync_graphqlEndpoint": "https://uowdjfyorndadkkjm7s7fqmnya.appsync-api.ap-northeast-1.amazonaws.com/graphql",
  // // "aws_appsync_region": "ap-northeast-1",
  // // "aws_appsync_authenticationType": "API_KEY",
  // // "aws_appsync_apiKey": "da2-4vye4zqi45hepda2733srb3hba",
  // "aws_cognito_identity_pool_id": "ap-northeast-1:1201e89e-91e6-4992-bf7b-e3e0dbc5026d",
  // "aws_cognito_region": "ap-northeast-1",
  // "aws_user_pools_id": "ap-northeast-1_UznRRtUiF",
  // "aws_user_pools_web_client_id": "14hpfevbgeb8duarbq7crjd2bh",
  aws_project_region: process.env.REACT_APP_PROJECT_REGION,
  // "aws_cognito_identity_pool_id": process.env.REACT_APP_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
