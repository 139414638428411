import { TextField, Autocomplete } from "@mui/material";

const salesStatus = [
  {
    key: 1,
    value: "平常営業",
  },
  {
    key: 2,
    value: "時間短縮営業",
  },
  {
    key: 3,
    value: "給油制限営業",
  },
  {
    key: 4,
    value: "緊急車両限定営業",
  },
  {
    key: 5,
    value: "営業停止（停電）",
  },
  {
    key: 6,
    value: "営業停止（施設損壊）",
  },
  {
    key: 7,
    value: "営業停止（その他）",
  },
  {
    key: 0,
    value: "不明",
  },
];

export default function SalesSelect(props) {
  const { saleDetailStatus, changedValue } = props;

  //第一引数にイベント情報、第二引数に選択された新しい値を受け取ることができる。
  const handleChange = (event, newValues) => {
    console.log("newValues", newValues);
    // changedValue(newValues?.key);
    changedValue(newValues ? newValues.key : null);
  };

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={salesStatus}
        getOptionLabel={(sale) => sale["value"]}
        isOptionEqualToValue={(option, value) => option["key"] === value["key"]}
        value={
          salesStatus.find((status) => status.key === saleDetailStatus) || null
        }
        renderInput={(params) => (
          <TextField {...params} placeholder="営業状況選択" />
        )}
        onChange={handleChange}
      />
    </>
  );
}
