import { Box } from "@mui/material";
import React, { useContext } from "react";
import ManualRegistration from "./ManualRegistration";
import ManualDisasterList from "./ManualDisasterList";
import DisasterList from "./DisasterList";
import {TokenContext} from "../../TokenContext";
import {ManualCreateDisasterRole} from "../../role";

export default function Dashboard() {
  const { user } = useContext(TokenContext);
  const manualCreateDisasterRole = ManualCreateDisasterRole(user.m_organization.m_role_id)
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "48px",
          alignItems: "center",
          paddingLeft: 5,
          fontSize: 20,
          fontWeight: "bold",
          whiteSpace: "nowrap",
          "@media screen and (max-width:900px)": {
            paddingLeft: 2,
          },
        }}
      >
        ダッシュボード
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderTop: 16,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            paddingTop: "16px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 20,
              fontWeight: "bold",
              color: "primary.main",
              whiteSpace: "nowrap",
            }}
          >
            対応中災害一覧
          </Box>
        </Box>
        <DisasterList />
      </Box>

      {manualCreateDisasterRole.read ? (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderTop: 16,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            paddingTop: "16px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            gap: "32px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "min-content",
              fontSize: 20,
              fontWeight: "bold",
              color: "primary.main",
              whiteSpace: "nowrap",
            }}
          >
            災害手動登録
          </Box>
        </Box>
        <ManualRegistration />
        <ManualDisasterList />
      </Box> ) : (
        ""
      ) }
    </>
  );
}
