import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import { Box, TextField } from "@mui/material";
import SnackbarCustom from "../../../../components/SnackBarCustom";
import PopoverCustom from "../../../../components/PopoverCustom";
import { fetchSession } from "../../../../auth";

export default function TemplateRegistration() {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // 前の画面に戻る
  };

  //クエリパラメータ取得
  const searchParams = new URLSearchParams(useLocation().search);
  const templateId = searchParams.get("id");

  //メールテンプレート一覧取得※エラー修正待ち
  const [templateMail, setTemplateMail] = useState({
    title: "",
    content: "",
  });
  const getTemplateMail = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/template_mail/${templateId}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("メールテンプレート取得入力データ", templateId);
    axios
      .get(apiUrl, headers)
      .then((response) => {
        const data = response.data.template_mail;
        const status = response.data;
        console.log("メールテンプレート情報取得", response);
        setTemplateMail(data);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  //結合確認コメント
  var putPostMessage = templateId ? (
    <div>更新しますか？</div>
  ) : (
    <div>登録しますか？</div>
  );

  //登録更新処理
  const [confirmPopoverOpen, setPopoverSplitOpen] = useState(false);
  const putPostButtonClick = () => {
    setPopoverSplitOpen(true);
  };
  const confirmPopupClose = (event) => {
    setPopoverSplitOpen(false);
  };

  //メールテンプレート更新/登録
  const putPostTemplateMail = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const endpoint = templateId
      ? // ? `http://localhost:8080/template_mail/${templateId}`
        `${process.env.REACT_APP_BASE_URL}/template_mail/${templateId}`
      : `${process.env.REACT_APP_BASE_URL}/template_mail`;
    // リクエストメソッド（POSTまたはPUT）
    const method = templateId ? "PUT" : "POST";
    //リクエストパラメータ
    const params = {
      title: templateMail?.["title"],
      content: templateMail?.["content"],
    };
    const axiosConfig = {
      method: method,
      url: endpoint,
      data: params,
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
      // 他に必要なヘッダーや認証情報があれば追加
    };
    console.log("メールテンプレート情報更新登録入力内容", params);
    isSuccess
      ? axios(axiosConfig)
          .then((response) => {
            const data = response.data.template_mail;
            const status = response.data;
            console.log("メールテンプレート情報更新登録", response);
            setTemplateMail(data);
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.error("API呼び出しエラー", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  //画面表示 更新or登録
  const pageContent = [{ title: "登録" }, { title: "更新" }];
  const [pageValidate, setPageValidate] = useState(0);
  const settingTemplate = () => {
    setPageValidate(1);
    getTemplateMail();
  };

  useEffect(() => {
    if (templateId === null) {
      setPageValidate(0);
    } else {
      settingTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTitle = (e) => {
    const newValue = e.target.value;
    setTemplateMail((prevValue) => ({
      ...prevValue,
      title: newValue,
    }));
  };

  const handleChangeContent = (e) => {
    const newValue = e.target.value;
    setTemplateMail((prevValue) => ({
      ...prevValue,
      content: newValue,
    }));
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          "@media screen and (max-width:900px)": {
            flexDirection: "column",
            gap: "-16px",
          },
        }}
      >
        <Box
          onClick={handleGoBack}
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            paddingLeft: "40px",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            color: "primary.main",
            cursor: "pointer",
            textDecoration: "underline",
            "&:hover": {
              color: "primary.dark",
            },
            "&:active": {
              color: "primary.light",
            },
            "@media screen and (max-width:900px)": {
              paddingLeft: 2,
            },
          }}
        >
          メールテンプレート一覧
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            fontSize: 20,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            "@media screen and (max-width:900px)": {
              paddingLeft: 2,
            },
          }}
        >
          /メールテンプレート{pageContent[pageValidate]["title"]}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          justifyContent: "center",
          borderRadius: 2,
          borderColor: "primary.main",
          boxShadow: 3,
          color: "outline.dark",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "32px",
            paddingRight: "40px",
            paddingLeft: "40px",
            paddingBottom: "32px",
            "@media screen and (max-width:900px)": {
              padding: "16px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "16px",
              "@media screen and (max-width:600px)": {
                flexDirection: "column",
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
                maxWidth: "480px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box sx={{ minWidth: "160px" }}>件名</Box>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  value={templateMail?.["title"] || ""}
                  onChange={(e) => handleChangeTitle(e)}
                  sx={{
                    width: "100%",
                    ".MuiInputBase-root": {
                      height: "100%",
                    },
                    "#outlined-basic": {
                      boxSizing: "border-box",
                      height: "100%",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "min-content",
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#000",
                  whiteSpace: "nowrap",
                  "@media screen and (max-width:900px)": {
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  },
                }}
              >
                <Box sx={{ minWidth: "160px" }}>本文 </Box>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={templateMail?.["content"] || ""}
                  onChange={(e) => handleChangeContent(e)}
                  multiline
                  rows={3}
                  sx={{
                    height: "100%",
                    width: "100%",
                    ".MuiInputBase-root": {
                      height: "100%",
                    },
                    "#outlined-basic": {
                      boxSizing: "border-box",
                      height: "100%",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "16px",
              "@media screen and (max-width:600px)": {
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            <Button
              variant="contained"
              sx={{ width: "208px" }}
              // onClick={putPostTemplateMail}
              onClick={putPostButtonClick}
            >
              {pageContent[pageValidate]["title"]}
            </Button>
          </Box>
        </Box>
      </Box>
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
      <PopoverCustom
        open={confirmPopoverOpen}
        onClose={confirmPopupClose}
        yesNo={putPostTemplateMail}
        title={templateId ? "更新確認" : "登録確認"}
        contents={putPostMessage}
      />
    </>
  );
}
