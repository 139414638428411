import React, {useState, useEffect, useContext} from "react";
import { Box, Checkbox, Divider, Button } from "@mui/material";
import axios from "axios";
import SnackbarCustom from "../../components/SnackBarCustom";
import PopoverCustom from "../../components/PopoverCustom";
import { fetchSession } from "../../auth";
import {TokenContext} from "../../TokenContext";
import {DisasterConditionRole} from "../../role";

const columnsDisaster = [
  {
    warning: "大雨特別警報",
    checkBox: "heavy_rain_warning",
  },
  {
    warning: "大雪特別警報",
    checkBox: "heavy_snow_warning",
  },
  {
    warning: "暴風特別警報",
    checkBox: "storm_warning",
  },
  {
    warning: "暴風雪特別警報",
    checkBox: "blizzard_warning",
  },
  {
    warning: "波浪特別警報",
    checkBox: "wave_warning",
  },
  {
    warning: "高潮特別警報",
    checkBox: "storm_surge_warning",
  },
  {
    warning: "土砂災害特別警報",
    checkBox: "landslide_warning",
  },
];
export default function DisasterJudge() {
  const { user } = useContext(TokenContext);
  const disasterConditionRole = DisasterConditionRole(user.m_organization.m_role_id);
  const [condition, setCondition] = useState({
    heavy_rain_warning: false,
    heavy_snow_warning: false,
    storm_warning: false,
    blizzard_warning: false,
    wave_warning: false,
    storm_surge_warning: false,
    landslide_warning: false,
  });

  const getDisasterCondition = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/condition`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    // axiosを使用してGETリクエストを送信
    axios
      .get(apiUrl, headers)
      .then((response) => {
        const data = response.data.m_condition;
        console.log("災害判定条件(警報)取得", data);
        // テナント情報をステートにセット
        setCondition(data);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  useEffect(() => {
    getDisasterCondition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCheckBox = (e, column) => {
    const newValue = e.target.checked;
    setCondition((prevCheck) => ({
      ...prevCheck,
      [column]: newValue,
    }));
  };

  //登録確認ポップオーバー確認
  const [confirmPopoverOpen, setPopoverSplitOpen] = useState(false);
  const registerButtonClick = () => {
    setPopoverSplitOpen(true);
  };
  const confirmPopupClose = (event) => {
    setPopoverSplitOpen(false);
  };

  //災害判定条件登録
  const putConditionData = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/condition?id=1`;

    const params = {
      id: 1,
      is_quake: condition["is_quake"],
      quake: condition["quake"],
      is_wind: condition["is_wind"],
      wind: condition["wind"],
      is_rain: condition["is_rain"],
      is_rain_hour3: condition["is_rain_hour3"],
      is_rain_hour6: condition["is_rain_hour6"],
      is_rain_hour12: condition["is_rain_hour12"],
      is_rain_hour24: condition["is_rain_hour24"],
      is_rain_hour48: condition["is_rain_hour48"],
      is_rain_hour72: condition["is_rain_hour72"],
      is_snow_hour3: condition["is_snow_hour3"],
      is_snow_hour6: condition["is_snow_hour6"],
      is_snow_hour12: condition["is_snow_hour12"],
      is_snow_hour24: condition["is_snow_hour24"],
      is_snow_hour48: condition["is_snow_hour48"],
      is_snow_hour72: condition["is_snow_hour72"],
      rain: condition["rain"],
      rain_hour6: condition["rain_hour6"],
      rain_hour12: condition["rain_hour12"],
      rain_hour24: condition["rain_hour24"],
      rain_hour48: condition["rain_hour48"],
      rain_hour72: condition["rain_hour72"],
      snow_hour3: condition["snow_hour3"],
      snow_hour6: condition["snow_hour6"],
      snow_hour12: condition["snow_hour12"],
      snow_hour24: condition["snow_hour24"],
      snow_hour48: condition["snow_hour48"],
      snow_hour72: condition["snow_hour72"],
      heavy_rain_warning: condition["heavy_rain_warning"],
      heavy_snow_warning: condition["heavy_snow_warning"],
      storm_warning: condition["storm_warning"],
      blizzard_warning: condition["blizzard_warning"],
      wave_warning: condition["wave_warning"],
      storm_surge_warning: condition["storm_surge_warning"],
      landslide_warning: condition["landslide_warning"],
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("入力データ", params);
    isSuccess
      ? axios
          .put(apiUrl, params, headers)
          .then((response) => {
            console.log("レスポンスデータ:", response.data);
            const status = response.data;
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.error("エラーが発生しました:", error);
          })
      : console.log("何も行いません");
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const consoleCheck = () => {
    console.log("conditionWarning", condition);
    console.log("condition", condition["heavy_rain_warning"]);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "100%",
          maxWidth: "480px",
        }}
      >
        {columnsDisaster.map((column) => (
          <React.Fragment key={`disaster${column["warning"]}`}>
            <Box
              sx={{
                display: "flex",
                // alignItems: "center",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
              }}
            >
              <Box
                sx={{
                  minWidth: "160px",
                  padding: "0px 0",
                  "@media screen and (max-width:900px)": {
                    padding: "0",
                  },
                }}
              >
                {column["warning"]}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={condition[column["checkBox"]]}
                    onChange={(e) =>
                      handleChangeCheckBox(e, column["checkBox"])
                    }
                    disabled={!disasterConditionRole.update}
                  />
                </Box>
              </Box>
            </Box>
            <Divider />
          </React.Fragment>
        ))}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "16px",
            paddingTop: "8px",
            "@media screen and (max-width:600px)": {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <Button
            variant="contained"
            onClick={() => registerButtonClick()}
            sx={{ width: "208px" }}
            disabled={!disasterConditionRole.update}
          >
            登録
          </Button>
        </Box>
      </Box>
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
      <PopoverCustom
        open={confirmPopoverOpen}
        onClose={confirmPopupClose}
        yesNo={putConditionData}
        title={"登録確認"}
        contents={"災害判定条件を登録してもよろしいですか？"}
      />
    </>
  );
}
