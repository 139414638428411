import { TextField, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";

const damage = [
  {
    id: 0,
    name: "無",
  },
  {
    id: 1,
    name: "有",
  },
  {
    id: 2,
    name: "未設定",
  },
  {
    id: 9,
    name: "設定しない",
  },
];

export default function DamageSelect(props) {
  const { changedValue, inputValue } = props;
  const initialValue = damage.find(
    (element) => element.id === parseInt(inputValue)
  );
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (inputValue) {
      const newValue = damage.find((damage) => damage.id === inputValue);
      setValue(newValue);
    }
  }, [inputValue]);

  const handleChange = (event, newValues) => {
    setValue(newValues);
    changedValue(newValues?.id);
  };

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={damage}
        getOptionLabel={(option) => (option.name ? option.name : "")}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} />}
        onChange={handleChange}
        value={value} // 初期値を設定
        sx={{ cursor: "pointer", pointerEvents: "auto" }}
      />
    </>
  );
}
