import { TextField, Autocomplete } from "@mui/material";
import { useState, useEffect } from "react";

const sales = [
  {
    sales: 0,
    name: "無",
  },
  {
    sales: 1,
    name: "有",
  },
  {
    sales: 9,
    name: "設定しない",
  },
];

export default function SalesSelect(props) {
  const { changedValue, inputValue } = props;

  const initialValue = sales.find(
    (element) => element.sales === parseInt(inputValue)
  );
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (inputValue) {
      const newValue = sales.find((sales) => sales.sales === inputValue);
      setValue(newValue);
    }
  }, [inputValue]);

  const handleChange = (event, newValues) => {
    setValue(newValues);
    changedValue(newValues?.sales);
  };

  return (
    <>
      <Autocomplete
        fullWidth
        size="small"
        options={sales}
        getOptionLabel={(option) => (option.name ? option.name : "")}
        isOptionEqualToValue={(option, value) =>
          option["sales"] === value["sales"]
        }
        renderInput={(params) => <TextField {...params} />}
        onChange={handleChange}
        value={value} // 初期値を設定
        sx={{ cursor: "pointer", pointerEvents: "auto" }}
      />
    </>
  );
}
