import React, {useState, useEffect, useContext} from "react";
import { Box, TextField, Button, Checkbox, Divider } from "@mui/material";
import axios from "axios";
import SnackbarCustom from "../../components/SnackBarCustom";
import PopoverCustom from "../../components/PopoverCustom";
import EarthquakeScale from "./EarthquakeScaleSelect";
import { fetchSession } from "../../auth";
import {DisasterConditionRole} from "../../role";
import {TokenContext} from "../../TokenContext";

const columnsRain = [
  {
    checkBox: "is_rain",
    textField: "rain",
    hour: 1,
  },
  {
    checkBox: "is_rain_hour3",
    textField: "rain_hour3",
    hour: 3,
  },
  {
    checkBox: "is_rain_hour6",
    textField: "rain_hour6",
    hour: 6,
  },
  {
    checkBox: "is_rain_hour12",
    textField: "rain_hour12",
    hour: 12,
  },
  {
    checkBox: "is_rain_hour24",
    textField: "rain_hour24",
    hour: 24,
  },
  {
    checkBox: "is_rain_hour48",
    textField: "rain_hour48",
    hour: 48,
  },
  {
    checkBox: "is_rain_hour72",
    textField: "rain_hour72",
    hour: 72,
  },
];

const columnsSnow = [
  {
    checkBox: "is_snow_hour3",
    textField: "snow_hour3",
    hour: 3,
  },
  {
    checkBox: "is_snow_hour6",
    textField: "snow_hour6",
    hour: 6,
  },
  {
    checkBox: "is_snow_hour12",
    textField: "snow_hour12",
    hour: 12,
  },
  {
    checkBox: "is_snow_hour24",
    textField: "snow_hour24",
    hour: 24,
  },
  {
    checkBox: "is_snow_hour48",
    textField: "snow_hour48",
    hour: 48,
  },
  {
    checkBox: "is_snow_hour72",
    textField: "snow_hour72",
    hour: 72,
  },
];

export default function ConditionSetting() {
  const { user } = useContext(TokenContext);
  const disasterConditionRole = DisasterConditionRole(user.m_organization.m_role_id);
  const [condition, setCondition] = useState({
    is_quake: false,
    quake: null,
    is_wind: false,
    wind: 0,
    is_rain: false,
    is_rain_hour3: false,
    is_rain_hour6: false,
    is_rain_hour12: false,
    is_rain_hour24: false,
    is_rain_hour48: false,
    is_rain_hour72: false,
    is_snow_hour3: false,
    is_snow_hour6: false,
    is_snow_hour12: false,
    is_snow_hour24: false,
    is_snow_hour48: false,
    is_snow_hour72: false,
    rain: 0,
    rain_hour3: 0,
    rain_hour6: 0,
    rain_hour12: 0,
    rain_hour24: 0,
    rain_hour48: 0,
    rain_hour72: 0,
    snow_hour3: 0,
    snow_hour6: 0,
    snow_hour12: 0,
    snow_hour24: 0,
    snow_hour48: 0,
    snow_hour72: 0,
  });

  const getDisasterCondition = async () => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/condition`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    // axiosを使用してGETリクエストを送信
    axios
      .get(apiUrl, headers)
      .then((response) => {
        const data = response.data.m_condition;
        const status = response.data;
        console.log("災害判定条件取得", data);
        setCondition(data);
        //スナックバーステート
        setSnackBarstatus(status);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("API呼び出しエラー", error);
      });
  };

  //災害判定条件取得
  useEffect(() => {
    getDisasterCondition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCheckBox = (e, column) => {
    const newValue = e.target.checked;
    setCondition((prevCheck) => ({
      ...prevCheck,
      [column]: newValue,
    }));
  };

  const handleChangeTextField = (e, column) => {
    const newValue = parseInt(e.target.value);
    setCondition((prevText) => ({
      ...prevText,
      [column]: newValue !== undefined && !isNaN(newValue) ? newValue : 0,
    }));
  };

  const handleChangeQuake = (value) => {
    setCondition((prevText) => ({
      ...prevText,
      quake: value,
    }));
  };

  //登録確認ポップオーバー確認
  const [confirmPopoverOpen, setPopoverSplitOpen] = useState(false);
  const registerButtonClick = () => {
    setPopoverSplitOpen(true);
  };
  const confirmPopupClose = (event) => {
    setPopoverSplitOpen(false);
  };

  //災害判定条件登録
  const putConditionData = async (isSuccess) => {
    const tokenInfo = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/condition`;

    const params = {
      id: 1,
      is_quake: condition["is_quake"],
      quake: condition["quake"],
      is_wind: condition["is_wind"],
      wind: condition["wind"],
      is_rain: condition["is_rain"],
      is_rain_hour3: condition["is_rain_hour3"],
      is_rain_hour6: condition["is_rain_hour6"],
      is_rain_hour12: condition["is_rain_hour12"],
      is_rain_hour24: condition["is_rain_hour24"],
      is_rain_hour48: condition["is_rain_hour48"],
      is_rain_hour72: condition["is_rain_hour72"],
      is_snow_hour3: condition["is_snow_hour3"],
      is_snow_hour6: condition["is_snow_hour6"],
      is_snow_hour12: condition["is_snow_hour12"],
      is_snow_hour24: condition["is_snow_hour24"],
      is_snow_hour48: condition["is_snow_hour48"],
      is_snow_hour72: condition["is_snow_hour72"],
      rain: condition["rain"],
      rain_hour3: condition["rain_hour3"],
      rain_hour6: condition["rain_hour6"],
      rain_hour12: condition["rain_hour12"],
      rain_hour24: condition["rain_hour24"],
      rain_hour48: condition["rain_hour48"],
      rain_hour72: condition["rain_hour72"],
      snow_hour3: condition["snow_hour3"],
      snow_hour6: condition["snow_hour6"],
      snow_hour12: condition["snow_hour12"],
      snow_hour24: condition["snow_hour24"],
      snow_hour48: condition["snow_hour48"],
      snow_hour72: condition["snow_hour72"],
      heavy_rain_warning: condition["heavy_rain_warning"],
      heavy_snow_warning: condition["heavy_snow_warning"],
      storm_warning: condition["storm_warning"],
      blizzard_warning: condition["blizzard_warning"],
      wave_warning: condition["wave_warning"],
      storm_surge_warning: condition["storm_surge_warning"],
      landslide_warning: condition["landslide_warning"],
      start_at: "2023/01/01",
      end_at: "2023/01/01",
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenInfo.accessToken}`,
      },
    };
    console.log("入力データ", params);
    isSuccess
      ? axios
          // .put(apiUrl, condition)
          .put(apiUrl, params, headers)
          .then((response) => {
            console.log("レスポンスデータ:", response.data);
            const status = response.data;
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.error("エラーが発生しました:", error);
          })
      : console.log("何も行いません");
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            "@media screen and (max-width:900px)": {
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
            },
          }}
        >
          <Box sx={{ minWidth: "80px" }}>震度</Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #CCC",
                borderRight: "none",
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px",
                backgroundColor: "outline.light",
              }}
            >
              <Checkbox
                size="small"
                checked={condition["is_quake"]}
                onChange={(e) => handleChangeCheckBox(e, "is_quake")}
                disabled={!disasterConditionRole.update}
              />
            </Box>
            <EarthquakeScale
              changedValue={(value) => handleChangeQuake(value)}
              inputValue={condition.quake}
              disabled={!disasterConditionRole.update}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #CCC",
                borderLeft: "none",
                backgroundColor: "outline.light",
                width: "80px",
                justifyContent: "center",
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              以上
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "min-content",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            whiteSpace: "nowrap",
            "@media screen and (max-width:900px)": {
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
            },
          }}
        >
          <Box sx={{ minWidth: "80px" }}>風速</Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #CCC",
                borderRight: "none",
                borderTopLeftRadius: "4px",
                borderBottomLeftRadius: "4px",
                backgroundColor: "outline.light",
              }}
            >
              <Checkbox
                size="small"
                checked={condition["is_wind"]}
                onChange={(e) => handleChangeCheckBox(e, "is_wind")}
                disabled={!disasterConditionRole.update}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #CCC",
                borderRight: "none",
                backgroundColor: "outline.light",
                width: "112px",
              }}
            >
              最大風速
            </Box>
            <TextField
              id="outlined-basic"
              variant="outlined"
              inputProps={{ min: 0, style: { textAlign: "end" } }}
              // value={condition["wind"]}
              disabled={!disasterConditionRole.update}
              value={
                condition["wind"] !== undefined && !isNaN(condition["wind"])
                  ? condition["wind"]
                  : ""
              }
              onChange={(e) => handleChangeTextField(e, "wind")}
              sx={{
                height: "40px",
                width: "calc(100% - 230px)",
                ".MuiInputBase-root": {
                  height: "100%",
                },
                "#outlined-basic": {
                  boxSizing: "border-box",
                  height: "100%",
                },
                ".MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: 0,
                  },
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #CCC",
                borderLeft: "none",
                backgroundColor: "outline.light",
                width: "80px",
                justifyContent: "center",
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              m/s以上
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "flex",
          width: "100%",
          // gap: "8px",
          "@media screen and (max-width:900px)": {
            flexDirection: "column",
            width: "100%",
            alignItems: "flex-start",
          },
        }}
      >
        <Box
          sx={{
            minWidth: "80px",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            paddingTop: "8px",
          }}
        >
          降水量
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            width: "100%",
          }}
        >
          {columnsRain.map((column) => (
            <Box
              key={`rain${column.hour}`}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
                "@media screen and (max-width:900px)": {
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                },
              }}
            >
              <Box sx={{ display: "flex", width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #CCC",
                    borderRight: "none",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    backgroundColor: "outline.light",
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={condition[column["checkBox"]]}
                    onChange={(e) =>
                      handleChangeCheckBox(e, column["checkBox"])
                    }
                    disabled={!disasterConditionRole.update}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #CCC",
                    borderRight: "none",
                    backgroundColor: "outline.light",
                    width: "112px",
                  }}
                >
                  {column.hour}時間降水量
                </Box>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  inputProps={{ min: 0, style: { textAlign: "end" } }}
                  disabled={!disasterConditionRole.update}
                  value={condition[column["textField"]]}
                  onChange={(e) =>
                    handleChangeTextField(e, column["textField"])
                  }
                  sx={{
                    height: "40px",
                    width: "calc(100% - 230px)",
                    ".MuiInputBase-root": {
                      height: "100%",
                    },
                    "#outlined-basic": {
                      boxSizing: "border-box",
                      height: "100%",
                    },
                    ".MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: 0,
                      },
                    },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #CCC",
                    borderLeft: "none",
                    backgroundColor: "outline.light",
                    width: "80px",
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  mm以上
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Divider sx={{ width: "100%" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "flex",
          width: "100%",
          // gap: "8px",
          "@media screen and (max-width:900px)": {
            flexDirection: "column",
            width: "100%",
            alignItems: "flex-start",
          },
        }}
      >
        <Box
          sx={{
            minWidth: "80px",
            fontSize: 16,
            fontWeight: "bold",
            color: "#000",
            paddingTop: "8px",
          }}
        >
          降雪量
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            width: "100%",
          }}
        >
          {columnsSnow.map((column) => (
            <Box
              key={`snow${column.hour}`}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 16,
                fontWeight: "bold",
                color: "#000",
                whiteSpace: "nowrap",
                "@media screen and (max-width:900px)": {
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                },
              }}
            >
              <Box sx={{ display: "flex", width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #CCC",
                    borderRight: "none",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    backgroundColor: "outline.light",
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={condition[column["checkBox"]]}
                    onChange={(e) =>
                      handleChangeCheckBox(e, column["checkBox"])
                    }
                    disabled={!disasterConditionRole.update}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #CCC",
                    borderRight: "none",
                    backgroundColor: "outline.light",
                    width: "112px",
                  }}
                >
                  {column.hour}時間降雪量
                </Box>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  inputProps={{ min: 0, style: { textAlign: "end" } }}
                  value={condition[column["textField"]]}
                  disabled={!disasterConditionRole.update}
                  onChange={(e) =>
                    handleChangeTextField(e, column["textField"])
                  }
                  sx={{
                    height: "40px",
                    width: "calc(100% - 230px)",
                    ".MuiInputBase-root": {
                      height: "100%",
                    },
                    "#outlined-basic": {
                      boxSizing: "border-box",
                      height: "100%",
                    },
                    ".MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: 0,
                      },
                    },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #CCC",
                    borderLeft: "none",
                    backgroundColor: "outline.light",
                    width: "80px",
                    borderTopRightRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  mm以上
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
          "@media screen and (max-width:600px)": {
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <Button
          variant="contained"
          sx={{ width: "208px" }}
          onClick={registerButtonClick}
          disabled={!disasterConditionRole.update}
        >
          登録
        </Button>
      </Box>
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
      <PopoverCustom
        open={confirmPopoverOpen}
        onClose={confirmPopupClose}
        yesNo={putConditionData}
        title={"登録確認"}
        contents={"災害判定条件を登録してもよろしいですか？"}
      />
    </>
  );
}
