import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";
import SsSelect from "./SsSelect";
import PrefectureSelect from "../../../../../components/PrefectureSelect";
import DivisionSelect from "./DivisionSelect";
import SnackbarCustom from "../../../../../components/SnackBarCustom";
import PopoverCustom from "../../../../../components/PopoverCustom";
import {fetchSession} from "../../../../../auth";

const ManualRegistration = ({onProcessComplete}) => {
  //クエリパラメータ取得
  const searchParams = new URLSearchParams(useLocation().search);
  const disasterId = searchParams.get("disaster-id");

  // 登録項目管理
  const [postContents, setPostContents] = useState({
    disaster_id: disasterId | 0,
    pref_code: null,
    m_division_code: null,
    site_id: null,
  });

  //都道府県情報設定
  const handleChangePref = (e) => {
    setPostContents((prev) => ({
      ...prev,
      pref_code: e,
    }));
  };

  //地域情報設定
  const handleChangeDivision = (e) => {
    setPostContents((prev) => ({
      ...prev,
      m_division_code: e,
    }));
  };

  //SS情報設定
  const handleChangeSites = (e) => {
    setPostContents((prev) => ({
      ...prev,
      site_id: [e],
    }));
  };

  const postDisaster = async (isSuccess) => {
    const tokenData = await fetchSession();
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/disaster-manual-trigger`;
    const headers = {
      headers: {
        Authorization: `Bearer ${tokenData.accessToken}`,
      },
    };
    console.log("SS情報登録データ", postContents);
    isSuccess
      ? axios
          .post(apiUrl, postContents, headers)
          .then((response) => {
            console.log("SS情報登録", response);
            const status = response.data;
            //スナックバーステート
            setSnackBarstatus(status);
            setSnackbarOpen(true);
            onProcessComplete(status);
          })
          .catch((error) => {
            console.error("API呼び出しエラー", error);
          })
      : console.log("何も行いません"); // valueがfalseの場合、何も行いません
  };

  //スナックバー管理
  const [snackBarstatus, setSnackBarstatus] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  //ポップオーバー管理
  const [confirmPopoverOpen, setPopoverSplitOpen] = useState(false);
  const putPostButtonClick = () => {
    setPopoverSplitOpen(true);
  };
  const confirmPopupClose = (event) => {
    setPopoverSplitOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        justifyContent: "center",
        gap: "16px",
        paddingRight: "40px",
        paddingLeft: "40px",
        "@media screen and (max-width:900px)": {
          padding: "16px",
        },
      }}
    >
      {/*{process.env.NODE_ENV === "development" && (*/}
      {/*  <Button onClick={consoleCheck}>コンソール確認</Button>*/}
      {/*)}*/}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: "16px",
          "@media screen and (max-width:900px)": {
            flexDirection: "column",
          },
        }}
      >
        <PrefectureSelect changedValue={(value) => handleChangePref(value)} />
        <DivisionSelect
          prefCode={postContents["pref_code"]}
          changedValue={(value) => handleChangeDivision(value)}
        />
        <SsSelect
          prefCode={postContents["pref_code"]}
          divisionCode={postContents["m_division_code"]}
          changedValue={(value) => handleChangeSites(value)}
        />
      </Box>

      <Button
        variant="contained"
        onClick={putPostButtonClick}
        sx={{ width: "48px" }}
      >
        登録
      </Button>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          minWidth: "min-content",
          paddingLeft: "40px",
          fontSize: 16,
          fontWeight: "bold",
          color: "#000",
          whiteSpace: "nowrap",
          "@media screen and (max-width:900px)": {
            padding: "0",
          },
        }}
      >
        災害エリア追加登録準備中
      </Box>
      <SnackbarCustom
        data={snackBarstatus}
        openStatus={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
      <PopoverCustom
        open={confirmPopoverOpen}
        onClose={confirmPopupClose}
        yesNo={(e) => postDisaster(e)}
        title={"登録確認"}
        contents={"登録してもよろしいですか？"}
      />
    </Box>
  );
};
export default ManualRegistration;
