import React from "react";
import { Box } from "@mui/material";
import Environment from "./Environment";
import ConditionSetting from "./ConditionSetting";
import DisasterJudge from "./DisasterJudge";

export default function Setting() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "48px",
          alignItems: "center",
          paddingLeft: "40px",
          fontSize: 20,
          fontWeight: "bold",
          whiteSpace: "nowrap",
          "@media screen and (max-width:900px)": {
            paddingLeft: 2,
          },
        }}
      >
        設定
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "32px",
          "@media screen and (max-width:1200px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            width: "100%",
            justifyContent: "center",
            borderRadius: 2,
            borderTop: 16,
            borderColor: "primary.main",
            boxShadow: 3,
            color: "outline.dark",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              justifyContent: "center",
              padding: "16px 40px 32px 40px",
              gap: "40px",
              alignItems: "center",
              "@media screen and (max-width:600px)": {
                padding: "16px 16px 32px 16px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "min-content",
                fontSize: 20,
                fontWeight: "bold",
                color: "primary.main",
                whiteSpace: "nowrap",
              }}
            >
              災害判定条件設定
            </Box>
            <ConditionSetting />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "40px",
            "@media screen and (max-width:1200px)": {
              flexDirection: "row",
            },
            "@media screen and (max-width:900px)": {
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              width: "100%",
              justifyContent: "center",
              borderRadius: 2,
              borderTop: 16,
              borderColor: "primary.main",
              boxShadow: 3,
              color: "outline.dark",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "fit-content",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px 40px 32px 40px",
                gap: "40px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "144px",
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "primary.main",
                  wordWrap: "break-word",
                  maxWidth: "216px",
                  textAlign: "center",
                }}
              >
                環境設定
              </Box>
              <Environment />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "fit-content",
              width: "100%",
              justifyContent: "center",
              borderRadius: 2,
              borderTop: 16,
              borderColor: "primary.main",
              boxShadow: 3,
              color: "outline.dark",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "fit-content",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px 40px 32px 40px",
                gap: "40px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "144px",
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "primary.main",
                  wordWrap: "break-word",
                  maxWidth: "216px",
                  textAlign: "center",
                }}
              >
                災害判定条件（警報）
              </Box>
              <DisasterJudge />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
